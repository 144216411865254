import { sprintf } from 'sprintf-js'

export class IndustryViewModel {
  constructor(name, type_industry1, active = false) {
    this.name = name;
    this.type_industry1 = type_industry1;
    this.active = active;
    this.is_active = function() {
      active = this.active ? ' active' : '';
      return this.industry_class_name() + active;
    };
    ko.track(this, ['active', 'is_active']);
  }

  industry_class_name() {
    const industry_number = typeof this.type_industry1 === 'number'
      ? this.getShortTypeIndustry1()
      : this.type_industry1;
    return `text-shop-category_${industry_number}`;
  }

  getShortTypeIndustry1() {
    const short_number = parseInt(this.type_industry1, 10) / 10000;
    return sprintf('%02d', short_number);
  }
};
