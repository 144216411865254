import { SearchByPref } from '../../_view_models/search_by_pref';
import { SelectModel } from '../../../select_model';
import { SearchModelForCompare } from './search_model_for_compare';
import '../../../../polyfill/closest_polyfill';
import { SearchByScTypeForSc } from '../search/search_by_sc_types_horizontal';

class SearchScsForCompare extends SearchModelForCompare {
  constructor(
    regions,
    sc_types,
    selected_sc_type_ids,
    max_count = null,
    search_conditions = {}
  ) {
    super();
    this.keywords = search_conditions['keywords'] || '';
    this.keywords_not = search_conditions['keywords_not'] || '';
    this.part_of_search_scs_by_sc_types = new SearchByScTypeForSc(
      sc_types,
      selected_sc_type_ids
    );
    this.max_count = max_count;
    this.part_of_search_scs_by_prefs = new SearchByPref(
      regions,
      search_conditions['address_pref_in_any'] || []
    );

    this.ovserved_scs = false;

    this.initialize();
    this.setDelayEvaluate(this, 'keywords');
    this.setDelayEvaluate(this, 'keywords_not');

    ko.postbox.subscribe('initializeModal', () => this.clearSearchConditions());
    ko.postbox.subscribe('initializeSearchButton', () =>
      this.initializeSearchButton()
    );

    ko.postbox.subscribe(
      'showModalCompareModelAndSendCurrentComponent',
      (params) => {
        this.parent_view_model = params.parent_view_model;
        ko.postbox.publish('showModalCompareModel');
      }
    );

    ko.postbox.subscribe('addScsFromSearchSc', (params) => {
      if (
        _.chain(this.parent_view_model.list)
          .pluck('model_id')
          .includes(params.model_id)
          .value()
      ) {
        // 選択したSCがすでにある場合、何もしない
      } else {
        const model = new SelectModel(
          params.model_id,
          params.model_name,
          params.model_type
        );
        this.parent_view_model.list.push(model);
      }
    });

    ko.postbox.subscribe('removeScsFromSearchSc', (params) => {
      const model = _.find(this.parent_view_model.list, (model) => {
        return model.model_id === params.model_id;
      });
      // 比較対象に選択されたSCだった場合、削除する
      if (model) {
        this.parent_view_model.list.remove(model);
      }
    });

    $(document).on(
      'ajax:beforeSend',
      'search-scs-for-compare',
      this.form_element,
      () => {
        if (!this.ovserved_scs) {
          const observer = new MutationObserver(() =>
            this.setSelectedModelColor()
          );
          const target = document.querySelector(
            'search-scs-for-compare #modal-search-result'
          );
          observer.observe(target, { childList: true, subtree: true });
          this.ovserved_scs = true;
        }
      }
    );
  }

  clearSearchConditions() {
    this.keywords = '';
    this.keywords_not = '';
    this.part_of_search_scs_by_sc_types.unselectAll();
    this.part_of_search_scs_by_prefs.unselectAll();
    this.initializeSearchButton();
    $('search-scs-for-compare #modal-search-result').empty();
  }
}

ko.components.register('search-scs-for-compare', {
  viewModel(params) {
    return new SearchScsForCompare(
      params.regions,
      params.sc_types,
      params.selected_sc_type_ids,
      params.max_candidate_count,
      params.search_conditions
    );
  },
  template: { element: 'ko-normal-template' },
});
