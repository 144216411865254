(function() {
  let REGION_CLASS_NAMES = undefined;
  let REGIONS = undefined;
  const Cls = (window.OccupancyPref = class OccupancyPref {
    static initClass() {
      REGION_CLASS_NAMES = {
        '北海道': 'hokkaido',
        '東北': 'tohoku',
        '関東': 'kantou',
        '中部': 'chubu',
        '近畿': 'kinki',
        '中国': 'chugoku',
        '四国': 'shikoku',
        '九州・沖縄': 'kyusyu'
      };
  
      REGIONS = {
        '北海道': ['北海道'],
        '東北': ['青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県'],
        '関東': ['茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県'],
        '中部': ['新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県'],
        '近畿': ['三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県'],
        '中国': ['鳥取県', '島根県', '岡山県', '広島県', '山口県'],
        '四国': ['徳島県', '香川県', '愛媛県', '高知県'],
        '九州・沖縄': ['福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県']
      };
    }

    constructor(name, count) {
      this.name = name;
      this.count = count;
    }

    getCssByName() {
      const region = this.getRegionByName();
      if (region != null) { return `region_${REGION_CLASS_NAMES[region]}`; }
    }

    getRegionByName() {
      for (let region in REGIONS) {
        const prefs = REGIONS[region];
        if (Array.from(prefs).includes(this.name)) { return region; }
      }
    }
  });
  Cls.initClass();
  return Cls;
})();

window.OccupancyPrefViewModel = class OccupancyPrefViewModel {
  constructor() {
    this.prefs = [];
    this.getRowCount = function() {
      return new Array(parseInt(this.prefs.length / 3, 10) + 1);
    };
    ko.track(this, ['prefs', 'getRowCount']);
    ko.getObservable(this, 'prefs').extend({ rateLimit: 300 });
  }

  getPref(index) {
    return this.prefs[index];
  }
};
