class MyGroupFormViewModel {
  constructor(name, status, description){
    if (name == null) { name = ''; }
    this.name = name;
    if (status == null) { status = ''; }
    this.status = status;
    if (description == null) { description = ''; }
    this.description = description;
    this.checkParams = function() {
      return !_.isBlank(this.name) && !_.isBlank(this.status);
    };
    this.isLimited = function() { return this.status === 'range_limited'; };
    ko.track(this);
  }
}

ko.components.register('my_group-form', {
  viewModel(params) {
    return new MyGroupFormViewModel(params.name, params.status, params.description);
  },
  template: { element: 'ko-normal-template' }
});