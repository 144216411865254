(() => {
  ko.bindingHandlers.bs_modal = {
    init: (element, valueAccessor, allBindings) => {
      let suspend = false;
      const propWriters = allBindings()['_ko_property_writers'];

      $(element).on('shown.bs.modal', () => {
        let value = valueAccessor();
        if (!value) {
          suspend = true;
          if (ko.isObservable(value)) {
            value = true;
          } else {
            propWriters.bs_modal(true);
          }
        }
      });
      $(element).on('hidden.bs.modal', () => {
        let value = valueAccessor();
        if (value) {
          suspend = true;
          if (ko.isObservable(value)) {
            value = false;
          } else {
            propWriters.bs_modal(false);
          }
        }
      });
      ko.computed({
        read: () => {
          const state = ko.unwrap(valueAccessor());
          if (suspend) {
            suspend = false;
            return;
          }
          if (state) {
            $(element).modal('show');
          } else {
            $(element).modal('hide');
          }
        },
        disposeWhenNodeIsRemoved: element
      });
    }
  };
  ko.expressionRewriting._twoWayBindings['bs_modal'] = true;
})();
