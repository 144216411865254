import { SearchByScType } from './part_of_search_by_sc_type';
import { SearchByPm } from './part_of_search_by_pm';
import { BaseSearchForm } from '../../../_view_models/base_search_form';
import { SearchByIndustry } from '../../../_view_models/search_by_industry';
import { SearchShopBrandPrefsViewModel } from '../search_shop_brand_pref';

class SearchFormShopBrands extends BaseSearchForm {
  constructor(
    large_industries,
    medium_industries,
    small_industries,
    selected_large_industry,
    selected_medium_industry,
    selected_small_industries,
    selected_food_court,
    selected_pms,
    sc_types,
    selected_sc_types,
    prefs,
    or_conditions,
    exists_direct = false,
    search_conditions = {},
    only_closed = false,
    small_industry_nicknames,
    recent_open_shop = false,
    recent_close_shop = false,
    open_within_months,
    close_within_months
  ) {
    super(search_conditions);
    this.only_closed = only_closed;
    this.exists_direct = exists_direct;
    this.recent_open_shop = recent_open_shop;
    this.recent_close_shop = recent_close_shop;
    this.search_by_sc_type = new SearchByScType(sc_types, selected_sc_types);

    this.search_by_pms = new SearchByPm(selected_pms);

    this.search_by_industry = new SearchByIndustry(
      large_industries,
      medium_industries,
      small_industries,
      selected_large_industry,
      selected_medium_industry,
      selected_small_industries,
      'q[type_industry1_eq]',
      'q[type_industry2_eq]',
      'q[type_industry3_in][]',
      selected_food_court,
      'q[food_court_true]',
      small_industry_nicknames
    );
    this.search_shop_brand_prefs = new SearchShopBrandPrefsViewModel(
      prefs,
      or_conditions,
      this.only_closed
    );
    this.scs_count_gteq = search_conditions['scs_count_gteq'] || '';
    this.scs_count_lteq = search_conditions['scs_count_lteq'] || '';
    this.open_within_months = open_within_months?.toString();
    this.close_within_months = close_within_months?.toString();
    this.initialize();
    this.setDelayEvaluate(this, 'scs_count_gteq');
    this.setDelayEvaluate(this, 'scs_count_lteq');
    ko.getObservable(this, 'only_closed').subscribe((new_value) => {
      ko.postbox.publish('changedOnlyClosedValue', new_value);
    });
    ko.postbox.subscribe('sendKeywordToSearchKeyword', (keyword) => {
      if (this.keywords.length > 0) {
        this.keywords += ` ${keyword}`;
      } else {
        this.keywords += keyword;
      }
    });
  }

  // @override
  canRequest() {
    return _.any(
      [
        this.only_closed,
        this.keywords,
        this.keywords_not,
        this.scs_count_gteq,
        this.scs_count_lteq,
        this.exists_direct,
        this.recent_open_shop && this.open_within_months,
        this.recent_close_shop && this.close_within_months,
        this.search_by_pms.rows,
        this.search_by_sc_type.conditions,
        this.search_by_industry.selected_large_industry,
        this.search_by_industry.selected_medium_industry,
        this.search_by_industry.selected_small_industries,
        this.search_by_industry.selected_food_court,
        // 出店都道府県のor条件の内容を全て1つの配列に入れてから検証させる
        _.flatten(
          this.search_shop_brand_prefs.or_conditions.map(
            (search_shop_brand_pref) => search_shop_brand_pref.and_conditions
          )
        ),
      ],
      function (value) {
        if (_.isNumber(value)) {
          value = value.toString(10);
        }
        if (_.isBoolean(value)) {
          return value;
        } else {
          return (value || '').length > 0;
        }
      }
    );
  }

  // @override
  getSearchCountUrl(params) {
    return Routes.count_shop_brands_path({
      key: params.key,
      format: params.format,
    });
  }

  // @override
  clearSearchConditions() {
    this.keywords = '';
    this.keywords_not = '';
    this.scs_count_gteq = '';
    this.scs_count_lteq = '';
    this.search_by_pms.clear();
    this.search_by_sc_type.clear();
    this.search_by_industry.clear();
    this.search_shop_brand_prefs.clear();
    this.exists_direct = false;
    this.recent_open_shop = false;
    this.recent_close_shop = false;
    this.only_closed = false;
    this.open_within_months = null;
    this.close_within_months = null;
  }
}

ko.components.register('search-form-shop_brands', {
  viewModel(params) {
    return new SearchFormShopBrands(
      params.large_industries,
      params.medium_industries,
      params.small_industries,
      params.selected_large_industry,
      params.selected_medium_industry,
      params.selected_small_industries,
      params.selected_food_court,
      params.selected_pms,
      params.sc_types,
      params.selected_sc_types,
      params.prefs,
      params.or_conditions,
      params.exists_direct,
      params.search_conditions,
      params.only_closed,
      params.small_industry_nicknames,
      params.recent_open_shop,
      params.recent_close_shop,
      params.open_within_months,
      params.close_within_months
    );
  },
  template: { element: 'ko-normal-template' },
});
