import { SearchByIndustry } from '../../_view_models/search_by_industry'

ko.components.register('search_by_industries-vertical', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new SearchByIndustry(
        params.large_industries,
        params.medium_industries,
        params.small_industries,
        params.selected_large_industry,
        params.selected_medium_industry,
        params.selected_small_industries,
        params.large_field_name,
        params.medium_field_name,
        params.small_field_name,
        params.selected_food_court,
        params.food_court_field_name,
        params.small_industry_nicknames
      );
    }
  },
  template: { element: 'part-of-search_by_industries-vertical' },
  synchronous: true
});