(function() {
    ko.bindingHandlers.jquery_slide = {
        init: function(element, valueAccessor) {
            var setting;
            setting = ko.unwrap(valueAccessor());
            if (setting.visible) {
                $(element).show();
            } else {
                $(element).hide();
            }
            return ko.computed({
                read: function() {
                    setting = ko.unwrap(valueAccessor());
                    if (setting.direction != null) {
                        if (setting.visible) {
                            return $(element).show('slide', {
                                direction: setting.direction
                            }, 500);
                        } else {
                            return $(element).hide('slide', {
                                direction: setting.direction
                            }, 500);
                        }
                    } else if (setting.visible != null) {
                        if (setting.visible) {
                            return $(element).slideDown();
                        } else {
                            return $(element).slideUp();
                        }
                    } else {
                        if (setting) {
                            return $(element).slideDown();
                        } else {
                            return $(element).slideUp();
                        }
                    }
                },
                disposeWhenNodeIsRemoved: element
            });
        }
    };

    ko.expressionRewriting._twoWayBindings['jquery_slide'] = true;

}).call(this);