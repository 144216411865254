import { BaseSearchForm } from '../../../_view_models/base_search_form';
import { SearchByIndustry} from '../../../_view_models/search_by_industry';
import { SearchShopCompanyPrefsViewModel } from '../search_shop_company_pref'

class SearchFormShopCompanies extends BaseSearchForm {
  constructor(large_industries,
                medium_industries,
                small_industries,
                selected_large_industry,
                selected_medium_industry,
                selected_small_industries,
                selected_food_court,
                prefs,
                or_conditions,
                search_conditions={},
                small_industry_nicknames) {
    super(search_conditions);
    this.search_by_industry = new SearchByIndustry(
      large_industries,
      medium_industries,
      small_industries,
      selected_large_industry,
      selected_medium_industry,
      selected_small_industries,
      'q[shop_brands_type_industry1_eq]',
      'q[shop_brands_type_industry2_eq]',
      'q[shop_brands_type_industry3_in][]',
      selected_food_court,
      'q[food_court_true]',
      small_industry_nicknames
    );
    this.search_shop_company_prefs = new SearchShopCompanyPrefsViewModel(
      prefs,
      or_conditions
    );
    this.initialize();
  }

  // @override
  canRequest() {
    return _.any(
      [
        this.keywords,
        this.keywords_not,
        this.search_by_industry.selected_large_industry,
        this.search_by_industry.selected_medium_industry,
        this.search_by_industry.selected_small_industries,
        this.search_by_industry.selected_food_court,
        this.search_shop_company_prefs.or_conditions
      ], function(value) {
        if (_.isNumber(value)) { value = value.toString(10); }
        if (_.isBoolean(value)) {
          return value;
        } else {
          return (value || '').length > 0;
        }
    });
  }

  // @override
  getSearchCountUrl(params) {
    return Routes.count_shop_companies_path({key: params.key, format: params.format});
  }

  // @override
  clearSearchConditions() {
    this.keywords = '';
    this.keywords_not = '';
    this.search_by_industry.clearSelectedLarge();
    return this.search_shop_company_prefs.clear();
  }
}

ko.components.register('search-form-shop_companies', {
  viewModel(params) {
    return new SearchFormShopCompanies(
      params.large_industries,
      params.medium_industries,
      params.small_industries,
      params.selected_large_industry,
      params.selected_medium_industry,
      params.selected_small_industries,
      params.selected_food_court,
      params.prefs,
      params.or_conditions,
      params.search_conditions,
      params.small_industry_nicknames,
    );
  },
  template: { element: 'ko-normal-template' }
});