ko.bindingHandlers.recaptcha = {
    init: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
        var propWriters = allBindings()['_ko_property_writers'];
        var value = valueAccessor();
        document.addEventListener('createCaptcha', function(event, theme) {
            var site_key = document.querySelector('.g-recaptcha').dataset.sitekey
            var callback = allBindings.get('recaptchaCallback') || function () {
                if (!value) {
                    if (ko.isObservable(value)) {
                        value = true
                    } else {
                        propWriters.recaptcha(true)
                    }
                }
            };
            window.widgetId = grecaptcha.render('recaptcha', {
                sitekey: site_key,
                theme: theme,
                callback: callback,
                'expired-callback': function() {
                    grecaptcha.reset(widgetId);
                    if (ko.isObservable(value)) {
                        value = false
                    } else {
                        propWriters.recaptcha(false)
                    }
                }
            })
        });
    }
};
ko.expressionRewriting._twoWayBindings['recaptcha'] = true;