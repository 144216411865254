$(function() {
  return $(document).popover({
    html: true,
    placement: 'top',
    selector: '#similar_scs .tab-pane ul li a',
    content() {
      const sc_id = $(document.body).data('model_id');
      const compare_sc_id = $(this).data('sc_id');
      return `\
<a href='${Routes.compare_path({sc: sc_id, compare_sc: compare_sc_id})}' class='btn btn-sm btn-default'>このSCと比べる</a>
<a href='${Routes.sc_path(compare_sc_id)}' class='btn btn-sm btn-default'>このSCの詳細へ</a>\
`;
    }
    ,
    delay: { show: 100, hide: 100 },
    trigger: 'focus'
  });
});
