/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// fat arrow演算子でメソッドを定義すると、
// 子クラスのthisが引数と同じになる現象が発生したため、
// もしこのクラスを継承する場合は、
// メソッドをオーバーライドしてアンダーバー付きメソッドを呼んでください
import { BaseSearchByIndustry } from './base_search_by_industry'

export class SearchByIndustry extends BaseSearchByIndustry{
  static RESTAURANT_AND_GROCERY = [70000, 120000];

  constructor(large_industries, medium_industries, small_industries,
    selected_large_industry, selected_medium_industry, selected_small_industries,
    large_field_name, medium_field_name, small_field_name,
    selected_food_court, food_court_field_name, small_industry_nicknames) {
    super(large_industries, medium_industries, small_industries,
      selected_large_industry, selected_medium_industry, selected_small_industries,
      small_industry_nicknames, '');
    this.large_field_name = large_field_name;
    this.medium_field_name = medium_field_name;
    this.small_field_name = small_field_name;
    this.selected_food_court = selected_food_court;
    this.food_court_field_name = food_court_field_name;
    ko.track(this,
      [
        'selected_large_industry',
        'selected_medium_industry',
        'selected_small_industries',
        'selected_food_court',
        'small_industry_keywords',
      ]
    );
    ko.getObservable(this, 'small_industry_keywords').extend({ rateLimit: 2000 });
  }
};
