class CheckboxOnOffButtonsByTypeIndustry1ViewModel {
  constructor(type_industry1, occupancy_type=null) {
    this.type_industry1 = type_industry1;
    this.occupancy_type = occupancy_type;
  }

  checkOnAll() {
    if (this.occupancy_type != null) {
      $('.industry_' + this.type_industry1 + '.' + this.occupancy_type).prop('checked', true);
    } else {
      $('.industry_' + this.type_industry1 + ':not(.open_occupancy, .close_occupancy)').prop('checked', true);
    }
  }

  checkOffAll() {
    if (this.occupancy_type != null) {
      $('.industry_' + this.type_industry1 + '.' + this.occupancy_type).prop('checked', false);
    } else {
      $('.industry_' + this.type_industry1 + ':not(.open_occupancy, .close_occupancy)').prop('checked', false);
    }
  }
}

ko.components.register('checkbox_on_off_buttons_by_type_industry1', {
  viewModel(params) {
    return new CheckboxOnOffButtonsByTypeIndustry1ViewModel(
      params.type_industry1,
      params.occupancy_type
    );
  },
  template: `\
<button class="btn btn-default btn-xs" data-bind="click: checkOnAll">選択</button>
&nbsp;
<button class="btn btn-warning btn-xs" data-bind="click: checkOffAll">解除</button>\
`
});