$.notifyDefaults({
  delay: 0,
  allow_dismiss: true,
  animate: {
    enter: 'animated fadeInDown',
    exit: 'animated fadeOutUp'
  },
  newest_on_top: true
});

window.Notify = class Notify {
  static danger(message) {
    $.notify(message, {type: 'danger'});
  }

  static success(message) {
    $.notify(message, {type: 'success'});
  }

  static info(message) {
    $.notify(message, {type: 'info'});
  }

  static warning(message) {
    $.notify(message, {type: 'warning'});
  }

  static error() {
    this.danger('エラーが発生しました。後ほどお試しいただくか、お問い合わせよりご連絡ください。');
  }
};