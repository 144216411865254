import { C3IndustryColors } from "./c3_industry_colors";
import * as d3 from 'd3';
import { C3ButtonDropdown } from './c3_button_dropdown'

export class C3CompareShopBrandKindsChart extends C3ButtonDropdown {
  constructor(element_id, json_data, percentage=false) {
    super(element_id);
    this.json_data = json_data;
    this.percentage = percentage;
    Object.assign(C3CompareShopBrandKindsChart.prototype, C3IndustryColors);
  }

  show() {
    const self = this;
    this.generate({
      bar: {
        zerobased: true
      },
      title: {
        text: this.percentage ? 'ショップ・ブランドの構成比' : 'ショップ数(件)',
        position: 'top-center'
      },
      size: {
        height: 400
      },
      data: {
        json: this._makeColumns(),
        type: 'bar',
        keys: {
          value: this._industryNames()
        },
        labels: {
          format(v, id, i, j) {
            if (self.percentage) {
              const sum = _.inject(self._makeColumns()[i], (memo, num) => memo + num);
              return d3.format('.1%')(v/sum);
            } else {
              return d3.format('01,d')(v);
            }
          }
        },
        groups: [this._industryNames()],
        colors: this._colors(),
        classes: this._additionalClass(),
        order: this._industryNames().reverse(),
        stack: {
          normalize: this.percentage
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: this._scNames(),
          tick: {
            multiline: true
          }
        },
        y: {
          label: {
            text: this.percentage ? '構成比(%)' : 'ショップ数',
            position: 'outer-middle'
          },
          tick: {
            format: d3.format('01,d')
          },
          min: 0,
          padding: { bottom: 0 }
        }
      },
      tooltip: {
        format: {
          value(value, ratio) {
            if (self.percentage) { return d3.format('.1%')(ratio); } else { return d3.format('01,d')(value); }
          }
        },
        order: this._industryNames()
      },
      onrendered: () => {
        const centers = d3.selectAll(`${this.element_id} .c3-chart-bars .c3-bar`).nodes().map(el => el.getBBox().height/2);
        return d3.selectAll(`${this.element_id} .c3-chart-texts text.c3-text`).nodes().forEach((el, i) => d3.select(el)
          .attr('transform', `translate(0, ${centers[i]})`));
      }
    });
  }

  _makeColumns() {
    return this._shopCounts().map(data => _.object(this._industryNames(), data));
  }

  _scNames() {
    return _.pluck(this.json_data.latest_sales_histories, 'sc_name');
  }

  _shopCounts() {
    return _.zip(...Array.from(this.json_data.shop_count_each_industry.map(d => d.data) || []));
  }

  _industryNames() {
    return _.pluck(this.json_data.shop_count_each_industry, 'name');
  }

  _colors() {
    return _.object(this._industryNames(), this.colors);
  }

  _additionalClass() {
    return _.object(this._industryNames(), this._industryNames().map(_ => 'stacked'));
  }
};