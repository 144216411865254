import { KnockoutModalBase } from '../../../shared/knockout/view_models/knockout_modal_base';
import { IndustryViewModel } from '../_view_models/industry_view_model'

class ModalIndustriesListViewModel extends KnockoutModalBase {
  constructor(large_industries) {
    super();
    this.changeActive = this.changeActive.bind(this);
    this.industries = large_industries.map(industry => new IndustryViewModel(industry.name, industry.type_industry1, false));
    this.industries.unshift(new IndustryViewModel('すべて', 0, true));
    ko.track(this);
    ko.postbox.subscribe('showModalIndustriesList', () => this.show());
  }

  changeActive(item) {
    this.industries.forEach(industry => industry.active = false);
    item.active = true;
    $('#brands_result').data('type_industry1', item.type_industry1);
    this.showFilterIndustry();
  }

  showFilterIndustry() {
    const selected_industry = this.industries.filter(industry => industry.active)[0];
    if (selected_industry.type_industry1 === 0) {
      $("[class^=type_industry_]").show();
    } else {
      $("[class^=type_industry_]").hide();
      $(`.type_industry_${selected_industry.type_industry1}`).show();
    }
  }
}

ko.components.register('modal-industries-list', {
  viewModel(params) {
    return new ModalIndustriesListViewModel(params.large_industries);
  },
  template: { element: 'modal-lg-template' }
});
