$(function(){
    $("a#to_page_top").click(function(){
        const Hash = $(this.hash);
        const HashOffset = $(Hash).offset().top;
        $("html,body").animate({
            scrollTop: HashOffset - 60
        }, 300);
        return false;
    });
});
