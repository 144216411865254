import { AdvertisePhotoViewModel } from '../view_models/advertise_photo';

class AdvertiseForm {
  constructor(industry, achievement, timing, attached_files) {
    this.industry_type = (industry || '').toString();
    this.disable_food_court = () => {
      return this.industry_type !== '70000';
    };

    this.achievement = achievement || [];
    this.disable_shop_count_sc = () => {
      return this.achievement.indexOf('sc') < 0;
    };
    this.disable_shop_count_road = () => {
      return this.achievement.indexOf('road') < 0;
    };
    this.disable_shop_count_department = () => {
      return this.achievement.indexOf('department') < 0;
    };
    this.disable_shop_count_other = () => {
      return this.achievement.indexOf('other') < 0;
    };
    this.disable_ec_url = () => {
      return this.achievement.indexOf('ec_site') < 0;
    };

    this.timing = timing;
    this.disable_open_date = () => {
      return this.timing !== 'specified';
    };

    this.photos = attached_files.map(
      (attached_file) =>
        new AdvertisePhotoViewModel(attached_file.id, attached_file.url)
    );
    ko.track(this);

    ko.postbox.subscribe('matchPhotosHeight', () => this.matchPhotosHeight());
    this.matchPhotosHeight();
  }

  matchPhotosHeight() {
    $(document.body).imagesLoaded(() => {
      $('.thumbnail').matchHeight();
    });
  }
}

ko.components.register('advertise-form', {
  viewModel(params) {
    return new AdvertiseForm(
      params.industry,
      params.achievements,
      params.timing,
      params.attached_files
    );
  },
  template: { element: 'ko-normal-template' },
  synchronous: true,
});
