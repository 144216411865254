import { KnockoutModalBase } from "../../../../shared/knockout/view_models/knockout_modal_base";

class ModalSearchShopBrandPrefConditionViewModel extends KnockoutModalBase {
  constructor(regions) {
    super();
    this.clickPref = this.clickPref.bind(this);
    this.clickSelectPrefArea = this.clickSelectPrefArea.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.regions = regions;
    this.only_closed = false;
    this.initialize();
    ko.track(this);

    ko.defineProperty(this, 'label_open_or_close', () => {
      if (this.only_closed) {
        return "退店済み";
      } else {
        return "出店している";
      }
    });
    ko.defineProperty(this, 'label_not_open_or_not_open_not_close', () => {
      if (this.only_closed) {
        return "出退店したことがない";
      } else {
        return "出店していない";
      }
    });

    if (this.selected_pref_id != null) { this.loadCities(); }

    const self = this;
    $(document).on('hidden.bs.modal', '#modal-select-pref-cities', () => {
      if (self.search_shop_brand_pref_condition != null) {
        if (self.selected_pref_ids.length > 0) {
          ko.postbox.publish(
            'addSearchShopBrandPrefConditions', {
            remove_item: self.search_shop_brand_pref_condition,
            opening: self.selected_opening === 'opening',
            prefs: self.selected_pref_ids,
            add_and_next: self.add_and_next,
            condition_index: self.condition_index,
            only_closed: self.only_closed
          }
          );
        } else {
          ko.postbox.publish(
            'addSearchShopBrandPrefCondition', {
            item: self.search_shop_brand_pref_condition,
            add_and_next: self.add_and_next,
            condition_index: self.condition_index,
          }
          );
        }
      }
    });

    ko.postbox.subscribe('showModalSearchShopBrandPrefCondition', (params) => {
      self.add_and_next = false;
      self.search_shop_brand_pref_condition = params.search_shop_brand_pref_condition;
      self.condition_index = params.condition_index;
      self.only_closed = params.search_shop_brand_pref_condition.only_closed;
      self.selected_opening = self.search_shop_brand_pref_condition.opening ?
                                "opening"
                              :
                                "not_opening";
      self.selected_area = self.search_shop_brand_pref_condition.cities.length > 0 ?
                             "not_all"
                           :
                             "all";
      if (self.search_shop_brand_pref_condition.pref === null) {
        self.selected_pref_id = null;
        self.selected_pref_ids = [];
        self.selected_pref_area = "all";
        self.selected_region_ids = [];
      } else {
        self.selected_pref_id = self.search_shop_brand_pref_condition.pref.id;
        self.selected_pref_ids = [];
        self.selected_pref_area = "not_all";
        self.selected_region_ids = [];
      }
      if (self.selected_pref_id != null) {
        self.loadCities();
      } else {
        self.cities = [];
      }
      self.show();
    });

    ko.postbox.subscribe('changedOnlyClosedValue', only_closed => self.only_closed = only_closed);
  }

  initialize() {
    this.add_and_next = false;
    this.search_shop_brand_pref_condition = null;
    this.selected_pref_id = null;
    this.selected_opening = "opening";
    this.selected_area = "all";
    this.cities = [];
    this.condition_index = null;
    this.selected_pref_area = "all";
    this.selected_pref_ids = [];
    this.selected_region_ids = [];
  }

  clickPref(pref) {
    this.search_shop_brand_pref_condition.pref = pref;
    this.search_shop_brand_pref_condition.cities.removeAll();
    this.loadCities();
    return true;
  }

  clickOpening() {
    this.search_shop_brand_pref_condition.opening =  this.selected_opening === "opening";
    return true;
  }

  clickSelectArea() {
    if (this.selected_area === 'all') { this.search_shop_brand_pref_condition.cities = []; }
    return true;
  }

  loadCities() {
    const self = this;
    $.getJSON(Routes.shop_brands_show_cities_path({pref_id: this.selected_pref_id}), data => self.cities = data.map(datum => datum.value));
  }

  addAndNext() {
    this.add_and_next = true;
    this.hide();
  }

  // 都道府県をまとめて選択するか、個別に選択するかを選んだ際に、
  // 関連データをクリアしておく
  clickSelectPrefArea() {
    if (this.selected_pref_area === 'all') { this.selected_pref_id = null; }
    if (this.selected_pref_area === 'not_all') { this.selected_pref_ids = []; }
    this.cities = [];
    return true;
  }

  // 地方を選択した際に、チェックを入れたらその都道府県全てを選択する
  // チェックを外したらその全ての都道府県の選択を解除する
  // @param region 地方
  selectRegion(region) {
    const pref_ids = _.pluck(region.prefs, 'id');
    if (_.includes(this.selected_region_ids, region.id)) {
      this.selected_pref_ids = _.union(this.selected_pref_ids, pref_ids);
    } else {
      this.selected_pref_ids = _.difference(this.selected_pref_ids, pref_ids);
    }
    return true;
  }
}

ko.components.register('modal-search-shop_brand-pref-condition', {
  viewModel(params) {
    return new ModalSearchShopBrandPrefConditionViewModel(params.regions);
  },
  template: `\
<div id="modal-select-pref-cities" class="modal fade" role="dialog" data-bind="bs_modal: modal_state, visible: search_shop_brand_pref_condition != null">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden='true'>&times;</span></button>
          <h3 class="modal-title">出店都道府県の選択</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div data-bind="css: selected_pref_area == 'all' ? 'col-xs-12' : 'col-xs-6'">
              <h4>1. 条件を選択してください</h4>
              <div class="form-group">
                <label class="radio-inline">
                  <input type="radio" value="opening" data-bind="checked: selected_opening, click: clickOpening">
                  <span data-bind="text: label_open_or_close"></span>
                </label>
                <label class="radio-inline">
                  <input type="radio" value="not_opening" data-bind="checked: selected_opening, click: clickOpening">
                  <span data-bind="text: label_not_open_or_not_open_not_close"></span>
                </label>
              </div>
              <h4>2. 都道府県を選択してください</h4>
              <div class="form-group select-pref-condition">
                <label class="radio-inline">
                  <input type="radio" value="all" data-bind="checked: selected_pref_area, click: clickSelectPrefArea">まとめて選択
                </label>
                <label class="radio-inline">
                  <input type="radio" value="not_all" data-bind="checked: selected_pref_area, click: clickSelectPrefArea">個別に選択
                </label>
                <p class="kome">まとめて選択の場合は、市区町村の指定はできません。</p>
              </div>
              <!-- ko if: selected_pref_area == 'all' -->
                <table class="table table-striped table-condensed margin-bottom-none" data-bind="if: regions.length > 0">
                  <tbody data-bind="foreach: { data: regions, as: 'region' }">
                    <tr>
                      <th class="col-xs-1-5">
                        <label class="checkbox-inline">
                          <input type="checkbox" data-bind="value: region.id, checked: $parent.selected_region_ids, click: $parent.selectRegion">
                          <span data-bind="text: region.name"></span>
                        </label>
                      </td>
                      <td class="col-xs-4-5">
                        <div class="row" data-bind="foreach: { data: region.prefs, as: 'pref' }">
                          <div class="col-xs-3 col-padding-5">
                            <label class="checkbox-inline">
                              <input type="checkbox" name="pref" data-bind="value: pref.id, checked: $parents[1].selected_pref_ids">
                              <span data-bind="text: pref.name"></span>
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              <!-- /ko -->
              <!-- ko if: selected_pref_area == 'not_all' -->
                <table class="table table-striped table-condensed margin-bottom-none" data-bind="if: regions.length > 0">
                  <tbody data-bind="foreach: { data: regions, as: 'region' }">
                    <tr>
                      <th class="col-xs-1-5" data-bind="text: region.name"></td>
                      <td class="col-xs-4-5">
                        <div class="row" data-bind="foreach: { data: region.prefs, as: 'pref' }">
                          <div class="col-xs-3 col-padding-5">
                            <label class="radio-inline">
                              <input type="radio" name="pref" data-bind="value: pref.id, checked: $parents[1].selected_pref_id, click: $parents[1].clickPref">
                              <span data-bind="text: pref.name"></span>
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              <!-- /ko -->
            </div>
            <!-- ko if: selected_pref_area == 'not_all' -->
              <div class="col-xs-6">
                <h4>3. 市区町村を選択してください</h4>
                <div class="form-group select-city-condition">
                  <label class="radio-inline">
                    <input type="radio" value="all" data-bind="checked: selected_area, click: clickSelectArea">全域
                  </label>
                  <label class="radio-inline">
                    <input type="radio" value="not_all" data-bind="checked: selected_area, click: clickSelectArea">個別に選択
                  </label>
                </div>
                <!-- ko if: selected_area === 'not_all' -->
                <h4>4. 条件を選択してください</h4>
                <label class="radio-inline">
                  <input type="radio" value="and" data-bind="checked: search_shop_brand_pref_condition.city_condition">全てに(AND)
                </label>
                <label class="radio-inline">
                  <input type="radio" value="or" data-bind="checked: search_shop_brand_pref_condition.city_condition">いずれかに(OR)
                </label>
                <hr>
                <div class="row" data-bind="foreach: { data: cities, as: 'city' }">
                  <div class="col-xs-4">
                    <label class="checkbox-inline"><input type="checkbox" data-bind="checked: $parent.search_shop_brand_pref_condition.cities, value: city"><span data-bind="text: city"></span></label>
                  </div>
                </div>
                <!-- /ko -->
              </div>
            <!-- /ko -->
          </div>
        </div>
        <div class="modal-footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xs-offset-8 col-xs-2">
                <button type="button" class="btn btn-primary btn-block" data-bind="click: addAndNext">続けて追加</button>
              </div>
              <div class="col-xs-2">
                <button type="button" class="btn btn-default btn-block" data-dismiss="modal">選択完了</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>\
`
});
