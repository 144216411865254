import { SearchByPref } from '../../_view_models/search_by_pref'
import { SelectModel } from "../../../select_model";
import { SearchModelForCompare } from "./search_model_for_compare";

class SearchPmsForCompare extends SearchModelForCompare {
  constructor(regions,
              max_count=null,
              search_conditions={}) {
    super();
    this.keywords = search_conditions['keywords'] || '';
    this.keywords_not = search_conditions['keywords_not'] || '';
    this.max_count = max_count;
    this.part_of_search_pms_by_prefs = new SearchByPref(regions, search_conditions['address_pref_in_any'] || []);

    this.ovserved_pms = false;

    this.initialize();
    this.setDelayEvaluate(this, 'keywords');
    this.setDelayEvaluate(this, 'keywords_not');

    ko.postbox.subscribe('initializeModal', () => this.clearSearchConditions());
    ko.postbox.subscribe('initializeSearchButton', () => this.initializeSearchButton());

    ko.postbox.subscribe('showModalCompareModelAndSendCurrentComponent', (params) => {
      this.parent_view_model = params.parent_view_model;
      ko.postbox.publish('showModalCompareModel');
    });

    ko.postbox.subscribe('addPmsFromSearchPm', (params) => {
      if (_.chain(this.parent_view_model.list).pluck('model_id').includes(params.model_id).value()) {
        // 選択したPMがすでにある場合、何もしない
      } else {
        const model = new SelectModel(
          params.model_id,
          params.model_name,
          params.model_type
        );
        this.parent_view_model.list.push(model);
      }
    });

    ko.postbox.subscribe('removePmsFromSearchPm', (params) => {
      const model = _.find(this.parent_view_model.list, (model) => { return model.model_id === params.model_id });
      // 比較対象に選択されたPMだった場合、削除する
      if (model) {
        this.parent_view_model.list.remove(model);
      }
    });

    $(document).on('ajax:beforeSend', 'search-pms-for-compare', this.form_element, () => {
      if (!this.ovserved_pms) {
        const observer = new MutationObserver(() => this.setSelectedModelColor());
        const target = document.querySelector('search-pms-for-compare #modal-search-result');
        observer.observe(target, { childList: true, subtree: true });
        this.ovserved_pms = true;
      }
    });
  }

  clearSearchConditions() {
    this.keywords = '';
    this.keywords_not = '';
    this.part_of_search_pms_by_prefs.unselectAll();
    this.initializeSearchButton();
    $("search-pms-for-compare #modal-search-result").empty();
  }
}

ko.components.register('search-pms-for-compare', {
  viewModel(params) {
    return new SearchPmsForCompare(
      params.regions,
      params.max_candidate_count,
      params.search_conditions
    );
  },
  template: { element: 'ko-normal-template' }
});
