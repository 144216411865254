/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_packs_with_chunks_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'events-polyfill';
import $ from 'jquery';
import _ from 'underscore';
import '../../stylesheets/front/application.scss';
import * as Routes from '../../src/js-routes.js.erb';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min';
import { AppViewModel } from '../../src/app_view_model';
import s from 'underscore.string';
import store from 'store/dist/store.everything';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../images', true);
const imagePath = (name) => images(name, true);

window.Routes = Routes;

require('bootstrap-sass');
require('jquery.easing/jquery.easing');
require('lightbox2');
require('knockout-es5');
require('knockout-postbox');
require('holderjs');
require('jquery-ujs/src/rails');
require('jquery-inview/jquery.inview');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/effect');
require('jquery-ui/ui/effects/effect-slide');
require('bootstrap-notify');
require('spin.js/spin');
require('ladda');
require('moment/locale/ja');
require('ko-jquery-visualstate.js');
require('knockout-ladda');
require('knockout-bootstrap-modal.js');
require('knockout-cursor-wait');
require('knockout-recaptcha');
require('knockout-jquery-slide');

// IEがAJAXの結果をキャッシュすることがあるので、しないように指定
// http://qiita.com/sasaplus1/items/166d2097d1fa30aeba9c
$.ajaxSetup({
  cache: false,
});

_.mixin(s.exports());

$(function () {
  window.app_view_model = new AppViewModel();
  $('body').tooltip({
    html: true,
    selector: '[data-toggle="tooltip"]',
  });

  $('[data-toggle="popover"]').popover();
});

$.when($.ready).then(function () {
  ko.applyBindings(app_view_model);
  $('.ko-hidden').fadeIn().removeClass('ko-hidden');
});

$(document).on('click', '.link-logout', () => store.remove('searchScsOnMap'));

function allRequire(context) {
  context.keys().forEach(context);
}

allRequire(require.context('../../src/front/', true));
require('../../src/manage/address_view_model');
require('../../src/shared/knockout/view_models/advertise_photo');
require('../../src/shared/knockout/components/advertise_form');
