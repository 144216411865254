import { KnockoutModalBase } from "../../../shared/knockout/view_models/knockout_modal_base";

class SimpleModal extends KnockoutModalBase {
  constructor() {
    super();
    ko.track(this);

    ko.postbox.subscribe('showModalCompareModel', () => {
      if (this.modal_state === true) {
        // すでにモーダルが表示されていたら何もしない
      } else {
        this.show();
      }
    });

    ko.postbox.subscribe('hideModalCompareModel', () => {
      if (this.modal_state === false) {
        // すでにモーダルが非表示だったら何もしない
      } else {
        this.hide();
      }
    });
  }
}

ko.components.register('modal-select-scs-for-compare', {
  viewModel() {
    return new SimpleModal();
  }, template: { element: 'modal-lg-template' }
});

ko.components.register('modal-select-scs-pms-for-compare', {
  viewModel() {
    return new SimpleModal();
  }, template: { element: 'modal-lg-template' }
});
