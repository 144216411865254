$(() => // マイグループのアイテムの削除ボタンを押した時の挙動
$(document).on('ajax:complete', 'a.btn-my_group-delete', function(event, xhr, status) {
  switch (status) {
    case 'success':
      if ($(".advertise_unit").is("*")) {
        const unit = $(this).closest('.advertise_unit');
        unit.slideUp(333);
        setTimeout(function() {
          unit.remove();
          $('.group_count').text(_.numberFormat($(".advertise_unit").length));
        }
        , 333);
      } else {
        const row = $(this).closest('tr');
        $('.column', row).slideUp(333);
        setTimeout(function() {
          const tbody = $(row).closest('tbody');
          row.remove();
          $('.group_count').text(_.numberFormat(tbody.children().length));
        }
        , 333);
      }
      break;
    default:
      alert("エラーが発生しました");
      if (console) {
        console.log(`status: ${status}`);
        console.log(xhr);
      }
  }
}));