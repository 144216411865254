class MemoFormViewModel {
  constructor(memo=null) {
    this.initializeForm();
    if (memo != null) { this.setMemo(memo); }
    this.checkParams = function() {
      if ((this.title == null) || (this.status == null)) {
        return false;
      } else {
        return !_.isBlank(this.title) && !_.isBlank(this.status);
      }
    };

    this.isLimited = function() {
      return this.status === 'range_limited';
    };

    ko.track(this);
  }

  setMemo(memo) {
    this.id = memo.id;
    this.title = memo.title;
    this.status = memo.status;
    this.content = memo.content;
    this.members = memo.members;
  }

  initializeForm() {
    this.id = null;
    this.title = null;
    this.status = null;
    this.content = null;
    this.members = [];
  }
}

ko.components.register('memo-form', {
  viewModel(params) {
    return new MemoFormViewModel(params);
  },
  template: { element: 'ko-normal-template'}
});