// ko.bindingHandlers.recaptchaを使っています。
class InquiryFormViewModel {
  constructor(
    company_name,
    name,
    email,
    email_confirmation,
    tel,
    category,
    subject,
    message,
    policy_confirmation,
    recaptcha_verified) {
    this.company_name = company_name;
    this.name = name;
    this.email = email;
    this.email_confirmation = email_confirmation;
    this.tel = tel;
    this.category = category;
    this.subject = subject;
    this.message = message;
    if (policy_confirmation == null) { policy_confirmation = false; }
    this.policy_confirmation = policy_confirmation;
    if (recaptcha_verified == null) { recaptcha_verified = false; }
    this.recaptcha_verified = recaptcha_verified;
    ko.track(this);
  }

  componentLoaded() {
    let timer_id = setInterval( function() {
      if (typeof grecaptcha !== 'undefined' && grecaptcha !== null) {
        clearInterval(timer_id);
        document.dispatchEvent(new Event('createCaptcha'));
      }
    }
    , 100);
  }

  allowSubmit() {
    return (this.company_name.length > 0) &&
        (this.name.length > 0) &&
        (this.email.length > 0) &&
        (this.email_confirmation.length > 0) &&
        (this.category.length > 0) &&
        (this.tel.length > 0) &&
        (this.subject.length > 0) &&
        (this.message.length > 0) &&
        this.policy_confirmation &&
        this.recaptcha_verified;
  }
}

ko.components.register('inquiry-form', {
  viewModel(params) {
    return new InquiryFormViewModel(
      params.company_name,
      params.name,
      params.email,
      params.email_confirmation,
      params.tel,
      params.category,
      params.subject,
      params.message,
      params.policy_confirmation,
      params.recaptcha_verified
    );
  },
  template: { element: 'ko-normal-template' }
});