import * as ko from 'knockout-es5';
import { ObservableArray } from 'knockout';
import { ScType } from 'src/domains/sc_type';
import { Condition } from 'src/domains/condition';
import { ScTypeCondition } from 'src/domains/sc_type_condition';

export class SearchByScType {
  private readonly sc_types: ScType[];
  public conditions:
    | SearchScTypeCondition[]
    | ObservableArray<SearchScTypeCondition>;
  static REJECT_SC_TYPES: ReadonlyArray<string> = [
    '百貨店',
    '商店街・市場',
    'ファッションECモール',
  ];

  /**
   * コンストラクタ
   * @param {ScType[]} sc_types
   * @param {ScTypeCondition[]} selected_sc_types
   */
  constructor(sc_types: ScType[], selected_sc_types: ScTypeCondition[] = []) {
    this.remove = this.remove.bind(this);
    this.refresh = this.refresh.bind(this);
    this.sc_types = sc_types;
    this.conditions = selected_sc_types.map(
      (selected_sc_type) =>
        new SearchScTypeCondition(
          selected_sc_type.sc_type,
          selected_sc_type.condition
        )
    );
    ko.track(this);
  }

  /**
   * SCタイプの条件を追加する
   */
  add() {
    this.conditions.push(new SearchScTypeCondition(this.sc_types[0]));
  }

  /**
   * SCタイプの条件を削除する
   * @param {SearchScTypeCondition} condition
   */
  remove(condition: SearchScTypeCondition) {
    (this.conditions as ObservableArray).remove(condition);
  }

  refresh() {
    ko.getObservable(this, 'conditions').notifySubscribers();
  }

  clear() {
    this.conditions = [];
  }

  /**
   * 百貨店、商店街・市場、ファッションECモールを含まない条件を追加する。
   * 既にある場合は「含まない」に変更する。
   */
  rejectDepartmentAndShoppingStreetAndEC() {
    this.rejectScType().forEach((sc_type) => {
      const existed_condition = (
        this.conditions as SearchScTypeCondition[]
      ).find(
        (condition) =>
          condition.isExclude() && condition.sc_type.id === sc_type.id
      );
      if (!existed_condition) {
        this.conditions.push(
          new SearchScTypeCondition(sc_type, Condition.Exclude)
        );
      }
    });
  }

  /**
   * 除外するSCタイプのみを返す
   * @private
   */
  private rejectScType(): ScType[] {
    return this.sc_types.filter((sc_type) =>
      SearchByScType.REJECT_SC_TYPES.includes(sc_type.name)
    );
  }
}

class SearchScTypeCondition implements ScTypeCondition {
  public sc_type: ScType;
  public condition: Condition;

  /**
   * コンストラクタ
   * @param {ScType} sc_type_id
   * @param {Condition} condition
   */
  constructor(sc_type: ScType, condition: Condition = Condition.Include) {
    this.sc_type = sc_type;
    this.condition = condition;
    ko.track(this);
  }

  /**
   * 条件が「含む」かどうか確認する
   * @return {boolean}
   */
  isInclude() {
    return this.condition === Condition.Include;
  }

  /**
   * 条件が「含まない」かどうか確認する
   * @return {boolean}
   */
  isExclude() {
    return !this.isInclude();
  }
}

ko.components.register('part-of-search-by-sc_types', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new SearchByScType(params.sc_types, params.selected_sc_types);
    }
  },
  template: { element: 'sc-type-selectable' },
  synchronous: true,
});
