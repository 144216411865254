import { getModelId } from "./utilities";
import { D3OccupancyMap } from './charts/d3_occupancy_map';
import { C3OccupancyPrefsChart } from './charts/c3_occupancy_prefs_chart';
import { C3ScTypeChart } from './charts/c3_sc_type_chart';
import { C3OccupancyChart } from './charts/c3_occupancy_chart';

$(function() {
  if ($('#shop-brand-occupancy-chart').is('*')) {
    getShopBrandOccupancyChart();
    showShopBrandOccupancyData();
  }

  // 出退店詳細ページの検索フォーム
  if ($('form#shop_brand_occupancy_detail').is('*')) {
    $('form#shop_brand_occupancy_detail').submit(function() {
      const form_data = $('form#shop_brand_occupancy_detail').serialize();
      getShopBrandOccupancyChart(form_data);
      showShopBrandOccupancyData(form_data);
      return false;
    });
  }

  // ショップが出店しているSCのタイプのグラフ
  if ($('#shop-brand-sc-type-column-chart').is('*')) {
    getShopBrandScTypeChart();
  }

  // ショップ・ブランドの都道府県別出店数の地図
  if ($('#shop-brand-occupancy-prefs').is('*')) {
    const occupancyPrefViewModel = new OccupancyPrefViewModel();
    app_view_model.addViewModels({occupancyPrefViewModel});
    $.getJSON(Routes.occupancy_prefs_shop_brand_path(getModelId(), {format: 'json'}), function(data) {
      D3OccupancyMap.show(
        '#shop-brand-occupancy-prefs',
        data.all_pref_occupancy_data,
        pref_name => location.href = Routes.open_sc_list_shop_brand_path(getModelId(), {pref: pref_name}));
      new C3OccupancyPrefsChart(
        '#shop-brand-occupancy-prefs-column-chart',
        data.all_pref_occupancy_data,
        pref_name => location.href = Routes.open_sc_list_shop_brand_path(getModelId(), {pref: pref_name})).show();
      data.all_pref_occupancy_data.forEach(pref => occupancyPrefViewModel.prefs.push(new OccupancyPref(pref.key, pref.value)));
    });
  }
});

function getShopBrandScTypeChart(form_data) {
  $.getJSON(Routes.sc_types_shop_brand_path(getModelId(), {format: 'json'}), form_data, data => new C3ScTypeChart("#shop-brand-sc-type-column-chart", data, category => location.href = Routes.open_sc_list_shop_brand_path(getModelId(), {sc_type: category})).show());
}

function getShopBrandOccupancyChart(form_data) {
  $.getJSON(Routes.occupancy_shop_brand_path(getModelId(), {format: 'json'}), form_data, data => new C3OccupancyChart('#shop-brand-occupancy-chart', data, function(d, element) {
    const state = d.value >= 0 ? 'open' : 'close';
    const category = this.categories()[d.index];
    const element_id = 'shop_brand_occupancy_' + category.replace('/', '_') + `_${state}`;
    ko.postbox.publish('scrollToElementWithDetailHeader',  {element_id});
  }).show());
}

function showShopBrandOccupancyData(form_data) {
  $('#shop-brand-occupancy-data').load(Routes.occupancy_shop_brand_path(getModelId(), {format: 'html'}), form_data);
}
