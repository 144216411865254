import { getModelId } from './utilities';
import { _BaseZenrinMapViewModel } from './_base_zenrin_map_view_model';
import { AdjacentScsMap } from './adjacent_scs_map'
import { LoadingModal } from '../shared/knockout/components/loading_modal';

export class AdjacentCloseShopBrandsViewModel extends _BaseZenrinMapViewModel {
  constructor(distance=5000, input_type='select', start_date, end_date) {
    distance = parseInt(distance, 10)
    // 10km以上を指定されたり、文字列を渡されると動かなくなるので、その場合は10kmで初期化する
    if (distance > 10000 || distance === NaN) {
      distance = 10000
    } else if (distance <= 0) { // 0以下の場合は500mで初期化する
      distance = 500
    }
    super(distance, input_type);
    this.sc = null;
    this.adjacent_scs = [];
    this.adjacent_close_shop_brands = [];
    const industries = {"gms": "ＧＭＳ", "fashion": "ファッション", "fashion_goods": "ファッション雑貨", "sundry": "生活雑貨",
      "interior": "インテリア・寝具・家電", "sport": "スポーツ・ホビー", "food_and_drink": "飲食", "service": "サービス",
      "amusement": "アミューズメント", "other": "その他", "store": "百貨店", "food": "食品"}
    Object.keys(industries).forEach(key => {
      Object.defineProperty(this, `visible_list_${key}`,{
        value: false,
        configurable: true,
      })
    })
    this.start_date = (!start_date) ? this.getOneYearAgo() : start_date;
    this.end_date = (!end_date) ? this.getOneMonthAgo() : end_date;
    const format = /^\d{4}\/(0[1-9]|1[0-2])$/;
    this.enable_submit = () => !format.test(this.start_date) || !format.test(this.end_date)
    this.adjacent_detail_close_shop_brand_sc_url = function() {
      if (this.sc != null) {
        return Routes.adjacent_detail_close_shop_brand_sc_path(this.sc.id, {distance: this.getDistance(), input_type: this.input_type, start_date: this.start_date, end_date: this.end_date});
      }
    };

    ko.track(this);
    ko.getObservable(this, 'input_distance').extend({integer: true});
    ko.defineProperty(this, 'open_adjacent_scs', function() {
      if (this.sc == null) { return []; }
      return this.adjacent_scs.filter(adjacent_sc => // リニューアル済SCは表示しない
        adjacent_sc.has_children === false);
    });

    Object.keys(industries).forEach(key => {
      ko.defineProperty(this, `close_shop_brands_${key}`, function () {
        return this.adjacent_close_shop_brands.filter(shop_brand => shop_brand.large_industry_name === industries[key]);
      })
    })
  }

  toggleList(industry) {
    switch (industry) {
      case 'gms':
        if (this.close_shop_brands_gms.length > 0) {
          this.visible_list_gms = !this.visible_list_gms;
        }
        break;
      case 'fashion':
        if (this.close_shop_brands_fashion.length > 0) {
          this.visible_list_fashion = !this.visible_list_fashion;
        }
        break;
      case 'fashion_goods':
        if (this.close_shop_brands_fashion_goods.length > 0) {
          this.visible_list_fashion_goods = !this.visible_list_fashion_goods;
        }
        break;
      case 'sundry':
        if (this.close_shop_brands_sundry.length > 0) {
          this.visible_list_sundry = !this.visible_list_sundry;
        }
        break;
      case 'interior':
        if (this.close_shop_brands_interior.length > 0) {
          this.visible_list_interior = !this.visible_list_interior;
        }
        break;
      case 'sport':
        if (this.close_shop_brands_sport.length > 0) {
          this.visible_list_sport = !this.visible_list_sport;
        }
        break;
      case 'food_and_drink':
        if (this.close_shop_brands_food_and_drink.length > 0) {
          this.visible_list_food_and_drink = !this.visible_list_food_and_drink;
        }
        break;
      case 'service':
        if (this.close_shop_brands_service.length > 0) {
          this.visible_list_service = !this.visible_list_service;
        }
        break;
      case 'amusement':
        if (this.close_shop_brands_amusement.length > 0) {
          this.visible_list_amusement = !this.visible_list_amusement;
        }
        break;
      case 'other':
        if (this.close_shop_brands_other.length > 0) {
          this.visible_list_other = !this.visible_list_other;
        }
        break;
      case 'store':
        if (this.close_shop_brands_store.length > 0) {
          this.visible_list_store = !this.visible_list_store;
        }
        break;
      case 'food':
        if (this.close_shop_brands_food.length > 0) {
          this.visible_list_food = !this.visible_list_food;
        }
        break;
    }
  }

  searchAdjacentScs({scroll = true}) {
    if (window.adjacentScsMap != null) {
      window.adjacentScsMap.drawCircleOval();
    }
    const distance = this.getDistance();
    const start_date = this.start_date;
    const end_date = this.end_date;
    if (distance <= 0) {
      return Notify.danger("1m以上の半径を入力してください");
    } else if (distance > 10000) {
      return Notify.danger("10km以下の半径を入力してください");
    } else {
      this.searching = true;
      LoadingModal.showSearching();
      $.getJSON(Routes.adjacent_close_shop_brand_sc_path(getModelId(), {format: 'json', distance, start_date, end_date}), (json) => {
        this.sc = json.sc;
        this.adjacent_scs = json.adjacent_scs;
        this.adjacent_close_shop_brands = json.adjacent_close_shop_brands;
        if (window.adjacentScsMap == null) {
          window.adjacentScsMap = new AdjacentScsMap(this,
            document.getElementById('adjacent-scs-map'),
            true,
            false,
            window.useMakepla
          );
        }
        window.adjacentScsMap.drawAll();
        this.searching = false;
        LoadingModal.hide();
        if (scroll) {
          $("html,body").animate(
            {scrollTop: $('#close_shop_list').offset().top - 142},
            {queue: false}
          );
        }
      });
    }
  }

  convertToMonth(str_date) {
    const [year, month, day] = str_date.split('-');
    return `${year}/${month}`
  }

  getOneMonthAgo() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    if (month >= 10) {
      return `${year}/${month}`
    } else if (month === 0) {
      return `${year - 1}/12`
    } else {
      return `${year}/0${month}`
    }
  }

  getOneYearAgo() {
    const date = new Date();
    const year = date.getFullYear() - 1;
    const month = date.getMonth() + 1;
    if (month >= 10) {
      return `${year}/${month}`
    } else {
      return `${year}/0${month}`
    }
  }

  getLatitude = () => this.sc.latitude

  getLongitude = () => this.sc.longitude
}
if (process.env.NODE_ENV != 'test') {
  $(function() {
    if ($('#adjacent-scs-map').is('*') && $("#adjacent-close-shop-brands").is("*")) {
      $('#start_date, #end_date').datepicker({
        format: 'yyyy/mm',
        language: 'ja',
        autoclose: true,
        minViewMode: 'months'
      });
      window.adjacentScsMap = null;
      const distance = $('#adjacent-close-shop-brands').data('distance');
      const input_type = $('#adjacent-close-shop-brands').data('input_type');
      const start_date = $('#adjacent-close-shop-brands').data('start_date');
      const end_date = $('#adjacent-close-shop-brands').data('end_date');
      const adjacentCloseShopBrandsViewModel = new AdjacentCloseShopBrandsViewModel(distance, input_type, start_date, end_date);
      app_view_model.addViewModels({adjacentCloseShopBrandsViewModel});
      adjacentCloseShopBrandsViewModel.searchAdjacentScs({scroll: false});
    }
  });
}
