import { AdvertisePhotoViewModel } from '../shared/knockout/view_models/advertise_photo';

$(function () {
  // バナー広告設定画面
  if ($('[data-bind="with: advertiseBannerViewModel"]').is('*')) {
    $('.accordion-toggle').on('click', function (e) {
      const id = $(this).attr('href');
      if (id === '#collapseOne') {
        $('#advertise_banner_banner_source').val('original');
      } else {
        $('#advertise_banner_banner_source').val('advertise');
      }

      if ($(id).hasClass('in')) {
        e.preventDefault();
        e.stopPropagation();
      }
    });

    const params = $('[data-bind="with: advertiseBannerViewModel"]').data(
      'params'
    );
    const vm =
      params != null
        ? new AdvertiseBannerViewModel({
            attached_file_id: params.attached_file_id,
            url: params.image_url,
            started_date: params.started_date,
            closed_date: params.closed_date,
          })
        : new AdvertiseBannerViewModel();

    app_view_model.addViewModels({ advertiseBannerViewModel: vm });
  }
});

class AdvertiseBannerViewModel extends AdvertisePhotoViewModel {
  constructor({ attached_file_id, url, started_date, closed_date }) {
    super(attached_file_id, url);
    this.started_date = started_date;
    this.closed_date = closed_date;
    this.banner_type = null;
    this.term = null;
    this.total_price = function () {
      const unit_price = this.banner_type === 'top' ? 70000 : 30000;
      return `¥ ${_.numberFormat(unit_price * this.term)} （税抜）`;
    };
    ko.track(this);
  }

  // 掲載終了日を自動計算する
  calculateClosedDate() {
    if (this.started_date == '') {
      return;
    }
    this.closed_date = moment(this.started_date, 'YYYY-MM-DD')
      .add(this.term, 'months')
      .format('YYYY-MM-DD');
    if ($('.closed_date-alt').is('*')) {
      $('.closed_date-alt').val(this.closed_date);
    }
  }
}
