class PartOfSearchApproximationByPopulation {
  constructor(selected_area_size) {
    if (selected_area_size == null) { selected_area_size = 'large'; }
    this.selected_area_size = selected_area_size;
    this.form_visible = false;
    ko.track(this);
  }

  toggleForm() { return this.form_visible = !this.form_visible; }

  clear() { return this.selected_area_size = null; }
}

ko.components.register('part-of-search_approximation_by_populations', {
  viewModel(params) {
    return new PartOfSearchApproximationByPopulation(params.selected_area_size);
  },
  template: {element: 'part-of-search_approximation_by_populations'}
});