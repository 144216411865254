import { IndustryViewModel } from "./industry_view_model";

export class IndustryWithSuggestedKeywords extends IndustryViewModel{
  constructor(name, type_industry1, active = false, suggested_keywords = []) {
    super(name, type_industry1, active);
    Object.defineProperty(this, 'suggested_keywords', { value: suggested_keywords })
  }

  sendKeywordToSearchKeyword(suggested_keyword) {
    ko.postbox.publish('sendKeywordToSearchKeyword', suggested_keyword.keyword)
  }
}