import { KnockoutModalBase } from "../../../../shared/knockout/view_models/knockout_modal_base";

class ModalSearchShopCompanyPrefConditionViewModel extends KnockoutModalBase {
  constructor(regions) {
    super();
    this.clickPref = this.clickPref.bind(this);
    this.regions = regions;
    this.initialize();
    ko.track(this);
    if (this.selected_pref_id != null) { this.loadCities(); }

    const self = this;
    $(document).on('hidden.bs.modal', '#modal-select-pref-cities', function() {
      if (self.search_shop_company_pref_condition != null) {
        ko.postbox.publish(
          'addSearchShopCompanyPrefCondition', {
          item: self.search_shop_company_pref_condition,
          add_and_next: self.add_and_next
        }
        );
      }
    });

    ko.postbox.subscribe('showModalSearchShopCompanyPrefCondition', function(params) {
      self.add_and_next = false;
      self.search_shop_company_pref_condition = params.search_shop_company_pref_condition;
      self.selected_area = self.search_shop_company_pref_condition.cities.length > 0 ?
                             "not_all"
                           :
                             "all";
      self.selected_pref_id = self.search_shop_company_pref_condition.pref === null ?
                                null
                              :
                                self.search_shop_company_pref_condition.pref.id;
      if (self.selected_pref_id != null) {
        self.loadCities();
      } else {
        self.cities = [];
      }
      self.show();
    });
  }

  initialize() {
    this.add_and_next = false;
    this.search_shop_company_pref_condition = null;
    this.selected_pref_id = null;
    this.selected_area = "all";
    this.cities = [];
  }

  clickPref(pref) {
    this.search_shop_company_pref_condition.pref = pref;
    this.search_shop_company_pref_condition.cities.removeAll();
    this.loadCities();
    return true;
  }

  clickSelectArea() {
    if (this.selected_area === 'all') { this.search_shop_company_pref_condition.cities = []; }
    return true;
  }

  loadCities() {
    const self = this;
    $.getJSON(Routes.shop_companies_show_cities_path({pref_id: this.selected_pref_id}), data => self.cities = data.map(datum => datum.value));
  }

  addAndNext() {
    this.add_and_next = true;
    this.hide();
  }
}


ko.components.register('modal-search-shop_company-pref-condition', {
  viewModel(params) {
    return new ModalSearchShopCompanyPrefConditionViewModel(params.regions);
  },
  template: `\
<div id="modal-select-pref-cities" class="modal fade" role="dialog" data-bind="bs_modal: modal_state, visible: search_shop_company_pref_condition != null">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden='true'>&times;</span></button>
          <h3 class="modal-title">本社所在地の選択</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-6">
              <h4>1. 都道府県を選択してください</h4>
              <table class="table table-striped table-condensed margin-bottom-none" data-bind="if: regions.length > 0">
                <tbody data-bind="foreach: { data: regions, as: 'region' }">
                  <tr>
                    <th class="col-xs-1-5" data-bind="text: region.name"></td>
                    <td class="col-xs-4-5">
                      <div class="row" data-bind="foreach: { data: region.prefs, as: 'pref' }">
                        <div class="col-xs-3 col-padding-5">
                          <label class="radio-inline">
                            <input type="radio" name="pref" data-bind="value: pref.id, checked: $parents[1].selected_pref_id, click: $parents[1].clickPref">
                            <span data-bind="text: pref.name"></span>
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-xs-6">
              <h4>2. 市区町村を選択してください</h4>
              <div class="form-group">
                <label class="radio-inline">
                  <input type="radio" value="all" data-bind="checked: selected_area, click: clickSelectArea">全域
                </label>
                <label class="radio-inline">
                  <input type="radio" value="not_all" data-bind="checked: selected_area, click: clickSelectArea">個別に選択
                </label>
              </div>
              <!-- ko if: selected_area === 'not_all' -->
              <div class="row" data-bind="foreach: { data: cities, as: 'city' }">
                <div class="col-xs-4">
                  <label class="checkbox-inline"><input type="checkbox" data-bind="checked: $parent.search_shop_company_pref_condition.cities, value: city"><span data-bind="text: city"></span></label>
                </div>
              </div>
              <!-- /ko -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xs-offset-8 col-xs-2">
                <button type="button" class="btn btn-primary btn-block" data-bind="click: addAndNext">続けて追加</button>
              </div>
              <div class="col-xs-2">
                <button type="button" class="btn btn-default btn-block" data-dismiss="modal">選択完了</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>\
`
});