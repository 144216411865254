import * as d3 from 'd3';
import c3 from 'c3';
import { Selection } from 'd3';

export class C3ButtonDropdown {
  private readonly element_id: string;
  private svg: Selection<SVGGElement, unknown, HTMLElement, any>;
  private context_menu: Selection<HTMLDivElement, unknown, HTMLElement, any>;
  private menu_box: Selection<HTMLDivElement, unknown, HTMLElement, any>;
  private dropdown_button: Selection<SVGGElement, unknown, HTMLElement, any>;
  private readonly default_options: object;

  /**
   * コンストラクタ
   * @param {string} element_id
   */
  constructor(element_id: string) {
    this.onInit = this.onInit.bind(this);
    this.element_id = element_id;
    this.svg = null;
    this.default_options = {
      bindto: this.element_id,
      padding: {
        top: 16,
      },
      oninit: this.onInit,
    };
  }

  /**
   * 初期化のコールバック関数
   */
  private onInit(): void {
    this.setSVG();
    this.drawDropdownButton();
  }

  /**
   * ドロップダウンボタンを描画する
   */
  private drawDropdownButton(): void {
    this.setContextMenu();
    this.setMenuBox();
    this.setDropdownButton();
  }

  /**
   * c3.jsを使ってグラフを描画する
   * @param {object} c3_options
   */
  generate(c3_options: object): any {
    return c3.generate(Object.assign(this.default_options, c3_options));
  }

  /**
   * SVG要素を設定する
   * @private
   */
  private setSVG(): void {
    this.svg = d3.select(this.element_id).select('svg');
  }

  /**
   * コンテキストメニューを設定する
   * @private
   */
  private setContextMenu(): void {
    this.context_menu = d3
      .select(this.element_id)
      .append('div')
      .style('position', 'absolute')
      .style('z-index', 1000)
      .style('padding', '24px')
      .style('visibility', 'hidden')
      .style('right', '-24px')
      .style('top', '0');
  }

  /**
   * コンテキストメニューが表示されたら表示するメニューボックスを設定する。
   * マウスが離れたらコンテキストメニューを非表示にする。
   * @private
   */
  private setMenuBox(): void {
    this.menu_box = this.context_menu
      .append('div')
      .style('box-shadow', 'rgb(136, 136, 136) 3px 3px 10px')
      .style('border', '1px solid rgb(160, 160, 160)')
      .style('background', 'white')
      .style('padding', '5px 0');

    this.menu_box
      .append('div')
      .style('font-size', 11)
      .style('color', 'rgb(48,48,48)')
      .style('background', 'none')
      .style('padding', '0 10px')
      .html('チャートを印刷')
      .on('mouseover', () => {
        d3.select(d3.event.currentTarget)
          .style('background', 'rgb(69, 114, 165)')
          .style('color', 'white');
      })
      .on('mouseout', () => {
        d3.select(d3.event.currentTarget)
          .style('background', 'none')
          .style('color', 'rgb(48,48,48)');
        this.hideContextMenu();
        this.dropdown_button.select('rect').style('stroke', 'none');
      })
      .on('click', this.openPrintWindow.bind(this));
  }

  /**
   * ドロップダウンボタンを設定する。クリックしたらコンテキストメニューを表示に変更する
   * @private
   */
  private setDropdownButton(): void {
    this.dropdown_button = this.svg
      .append('g')
      .attr(
        'transform',
        `translate(${parseInt(this.svg.attr('width'), 10) - 25}, 0)`
      )
      .attr('stroke-linecap', 'round')
      .attr('class', 'd3-button')
      .on('mouseover', () => {
        d3.select(d3.event.currentTarget)
          .select('rect')
          .style('stroke', '#68A');
      })
      .on('mouseout', () => {
        if (this.isHiddenContextMenu()) {
          d3.select(d3.event.currentTarget)
            .select('rect')
            .style('stroke', 'none');
        }
      })
      .on('click', this.showContextMenu.bind(this));

    this.dropdown_button
      .append('rect')
      .attr('width', 25)
      .attr('height', 22)
      .style('fill', 'white')
      .style('stroke', 'none')
      .style('stroke-width', 1)
      .style('rx', 2)
      .style('ry', 2);

    this.dropdown_button
      .append('path')
      .style('fill', '#E0E0E0')
      .style('stroke', '#666')
      .style('stroke-width', 3)
      .attr('d', 'M 6 6.5 L 20 6.5 M 6 11.5 L 20 11.5 M 6 16.5 L 20 16.5');
  }

  /**
   * 印刷用のサブウィンドウを表示する
   * @private
   */
  private openPrintWindow(): void {
    const printWindow = window.open(
      '',
      'PrintChart',
      `width=${this.svg.attr('width')}, height=${this.svg.attr('height')}`
    );
    printWindow.document.writeln(
      '<head><link rel="stylesheet" href="/css/c3.css" media="all" /></head>'
    );
    // ESIより指摘あり。グラフ比較で表示しているc3.jsで生成したsvgをコピーしているので安全です。
    printWindow.document.writeln(
      `<body class='c3'>${new XMLSerializer().serializeToString(
        this.svg.node()
      )}</body>`
    );
    printWindow.document.close();
    const button = printWindow.document.querySelector('.d3-button');
    (button as HTMLDivElement).style.visibility = 'hidden';
    setTimeout(() => printWindow.print(), 1000);
    setTimeout(() => printWindow.close(), 1000);
  }

  private showContextMenu(): void {
    this.context_menu.style('visibility', 'visible');
  }

  private hideContextMenu(): void {
    this.context_menu.style('visibility', 'hidden');
  }

  private isHiddenContextMenu(): boolean {
    return this.context_menu.style('visibility') === 'hidden';
  }
}
