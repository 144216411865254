import { LoadingModal } from '../../../../../shared/knockout/components/loading_modal';
import { IndustryViewModel } from '../../../_view_models/industry_view_model';

class TogetherShopsViewModel {
  constructor(shop_brand_id, industries) {
    this.changeActive = this.changeActive.bind(this);
    this.shop_brand_id = shop_brand_id;
    this.industries = industries;
    const self = this;

    this.large_industries  = () => _.filter(self.industries, industry => industry.level === 'large');
    this.medium_industries = () => _.filter(self.industries, industry => industry.level === 'medium');
    this.small_industries  = () => _.filter(self.industries, industry => industry.level === 'small');
    this.filter_industry_view_models = self.large_industries().map(industry => new IndustryViewModel(industry.name, industry.type_industry1, false));

    this.filter_industry_view_models.unshift(new IndustryViewModel('すべて', 0, true));
    this.selected_industry = _.first(this.filter_industry_view_models);
    this.shop_brands = [];
    this.show_detail_url = function() {
      if (self.selected_industry.type_industry1 === 0) {
        return Routes.together_shops_all_shop_brand_path(self.shop_brand_id);
      } else {
        return Routes.together_shops_all_shop_brand_path(self.shop_brand_id,
          {q: { type_industry1_eq: self.selected_industry.type_industry1}} );
      }
    };
    ko.track(this);
    this.showTogetherShops();
  }

  changeActive(item) {
    this.filter_industry_view_models.forEach(industry => industry.active = false);
    item.active = true;
    this.selected_industry = item;
    this.showTogetherShops();
    return false;
  }

  getLargeIndustryName(type_industry1) {
    const industry = _.findWhere(this.large_industries(), {type_industry1});
    if (industry != null) {
      return industry.name;
    } else {
      return '';
    }
  }

  getMediumIndustryName(type_industry2) {
    const industry = _.findWhere(this.medium_industries(), {type_industry2});
    if (industry != null) {
      return industry.name;
    } else {
      return '';
    }
  }

  getSmallIndustryName(type_industry3) {
    const industry = _.findWhere(this.small_industries(), {type_industry3});
    if (industry != null) {
      return industry.name;
    } else {
      return '';
    }
  }

  showTogetherShops() {
    if (this.shop_brands.length > 0) { LoadingModal.showWaiting(); }
    const url = this.selected_industry.type_industry1 === 0 ?
            Routes.together_shops_shop_brand_path(this.shop_brand_id, {format: 'json'})
          :
            Routes.together_shops_shop_brand_path(this.shop_brand_id, {
              type_industry1: this.selected_industry.type_industry1,
              format: 'json'
            });
    const self = this;
    $.getJSON(url, function(data) {
      self.shop_brands = data;
      LoadingModal.hide();
    });
  }
}

ko.components.register('together-shops', {
  viewModel(params) {
    return new TogetherShopsViewModel(
      params.shop_brand_id,
      params.industries
    );
  },
  template: { element: 'ko-normal-template' }
});