$(() => {
  // see https://qiita.com/yuch_i/items/f7d7c8bbefa0ec19b2c9
  // bootstrapの既存のオプションはclick,focus,hover,manualの4つ。
  // clickとfocusを合わせたような挙動にしたかったが既存のオプションにはないため、
  // data-triggerをmanualにしてJavaScriptにpopoverの挙動を記述した。
  // 挙動: ボタンをクリックすることでポップオーバーが表示される。ポップオーバー以外をクリックすると非表示になる。
  const manual_popover = $('[data-toggle="popover"],[data-trigger="manual"]');
  manual_popover.on('click', function(event) {
    event.stopPropagation();
    $(this).popover('toggle');
  });

  $('body').on('click', '.popover', (event) => {
    event.stopPropagation();
  }).on('click', () => {
    manual_popover.popover('hide');
  });
});
