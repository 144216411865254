import { KnockoutModalBase } from "../view_models/knockout_modal_base";

export class LoadingModal extends KnockoutModalBase {
  constructor() {
    super();
    this.title = null;
    this.message = null;
    this.element = null;
    ko.track(this);
    const self = this;

    ko.postbox.subscribe('showLoadingModal', function(params) {
      self.title = params.title;
      self.message = params.message;
      const {
        options
      } = params;
      // 設定されていたコールバックイベントを削除(shown, hiddenのコールバックはbs_modalで利用するので消さない)
      $(self.element).off('show.bs.modal');
      $(self.element).off('hide.bs.modal');
      $(self.element).off('loaded.bs.modal');
      // 新たにコールバックイベントを設定
      if (options.show_callback != null) { $(self.element).on('show.bs.modal',   options.show_callback); }
      if (options.hide_callback != null) { $(self.element).on('hide.bs.modal',   options.hide_callback); }
      if (options.loaded_callback != null) { $(self.element).on('loaded.bs.modal', options.loaded_callback); }
      self.show();
    });

    ko.postbox.subscribe('hideLoadingModal', function() {
      self.hide();
      self.title = null;
      self.message = null;
    });
  }

  // もともとあったロードダイアログの表示方式に合わせるように、
  // Pub/Subをラップしたstatic methodを定義
  // @example
  // LoadingDialog.show("タイトル", "説明", { show_callback: -> console.log("show") })
  static show(title, message=null, options={}) {
    ko.postbox.publish('showLoadingModal', {
      title,
      message,
      options
    });
  }

  // もともとあったロードダイアログの隠蔽方式に合わせるように、
  // Pub/Subをラップしたstatic methodを定義
  // @example
  // LoadingDialog.hide()
  static hide() {
    ko.postbox.publish('hideLoadingModal');
  }

  static showSearching() {
    this.show('検索中…', `ただいま検索中です。対象データ件数が多い場合、数十秒かかる場合があります。<br>申し訳ございませんがそのままお待ち下さい。`);
  }

  static showWaiting() {
    this.show('しばらくお待ちください…');
  }

  static showCreating(options = {}) {
    this.show('Excelデータの作成中…', `ただいま作成中です。表示件数が200件以上の場合、5分以上かかる場合がございます。<br>申し訳ございませんがそのままお待ち下さい。`, options);
  }

  static showCreatingShort(options = {}) {
    this.show('Excelデータの作成中…', `ただいま作成中です。`, options);
  }

  static showAnalyzing(options = {}) {
    this.show('データの解析中…', `ただいま解析中です。対象データ件数が多い場合、数分かかる場合があります。<br>申し訳ございませんがそのままお待ち下さい。`, options);
  }
};

ko.components.register('loading-modal', {
  viewModel(params) { return new LoadingModal(); },
  template: { element: 'modal-loading-template' }
});