import { KnockoutModalBase } from "../../../shared/knockout/view_models/knockout_modal_base";

class ModalSaveConditionViewModel extends KnockoutModalBase {
  constructor() {
    super();
    this.title = '';
    ko.track(this);
    ko.postbox.subscribe('showModalSaveCondition', () => this.show());
  }

  onCreate = () => {
    const key = new URL(document.location).searchParams.get('key');
    $.ajax({
      url: Routes.modal_stored_conditions_path({ format: 'json' }),
      method: 'POST',
      data: {
        stored_condition: {
          title: this.title
        },
        search_condition: {
          key: key
        }
      }
    })
      .done((_data, _status, _xhr) => {
        Notify.success(
          `<strong>『${_.escapeHTML(this.title)}』</strong> を保存しました`
        );
        this.onCreated();
        this.hide();
      })
      .fail((_xhr, _status, _error) => Notify.error());
  };

  isDisabled = () => {
    return this.title.trim() === '';
  };

  show = () => {
    this.title = '';
    super.show();
  };

  onCreated = () => {
    $('#save-condition-button').prop('disabled', true);
    $('#result_stored_condition_title').text(`検索条件：『${_.truncate(this.title, 20)}』`);
  };
}

ko.components.register('modal-save-condition', {
  viewModel() {
    return new ModalSaveConditionViewModel();
  },
  template: { element: 'modal-template' }
});

$(() => {
  $('#save-condition-button').click(() => {
    ko.postbox.publish('showModalSaveCondition');
  });
});
