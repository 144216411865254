$(() => {
  if ($('body#compare').is('*')) {
    $(document).on('hidden.bs.modal', '#modal-select-scs-pms', () => {
      ko.postbox.publish('initializeModal');
    });

    // 検索結果から選んだPMを比較対象に追加する
    $(document).on('click', '#modal-select-scs-pms #modal-search-result .btn-default', function() {
      const model_id = $(this).data('model_id');
      const model_name = $(this).data('model_name');
      const model_type = $(this).data('model_type');
      ko.postbox.publish('addPmsFromSearchPm', {
        model_id,
        model_name,
        model_type,
      });
      // 選択した行の背景色を水色に変更
      $(this).closest('tr').addClass('info');
    });

    // 検索結果から選んだPMを比較対象から削除する
    $(document).on('click', '#modal-select-scs-pms #modal-search-result .btn-danger', function() {
      const model_id = $(this).data('model_id');
      ko.postbox.publish('removePmsFromSearchPm', {
        model_id
      });
      // 選択した行の背景色を水色に変更
      $(this).closest('tr').removeClass('info');
    });

    // マイグループのselectを初期値に戻してグループ選択を再度促す
    $(document).on('click', '#my_group-scs-tab', () => {
      ko.postbox.publish('initializeMyGroupSelect', 'sc');
    });
    $(document).on('click', '#my_group-pms-tab', () => {
      ko.postbox.publish('initializeMyGroupSelect', 'pm');
    });
  }
});