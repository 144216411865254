export class BaseSearchByPref {
  constructor(regions, selected_prefs) {
    this.toggleRegion = this.toggleRegion.bind(this);
    this.regions = regions;
    this.selected_prefs = selected_prefs;
    this.selected_regions = [];
  }

  selectAll() {
    this.selected_regions = _.pluck(this.regions, 'name');
    this.selected_prefs = _.flatten(this.regions.map(region => _.pluck(region.prefs, 'name')));
  }

  unselectAll() {
    this.selected_regions = [];
    this.selected_prefs = [];
  }

  toggleRegion(region) {
    // fat arrow演算子でメソッドを定義すると、
    // 子クラスのthisが引数と同じになる現象が発生したため、
    // もしこのクラスを継承する場合は、
    // メソッドをオーバーライドして_toggleRegionを呼んでください
    return this._toggleRegion(region);
  }

  _toggleRegion(region) {
    const pref_names = _.pluck(region.prefs, 'name');
    this.selected_prefs = _.includes(this.selected_regions, region.name) ?
      _.union(this.selected_prefs, pref_names)
      :
      _.difference(this.selected_prefs, pref_names);
    return true;
  }
};
