import { BaseSearchByIndustry } from '../../_view_models/base_search_by_industry'

class PartOfSearchApproximationByIndustries extends BaseSearchByIndustry {
  constructor(large_industries, medium_industries, small_industries,
              selected_large_industry, selected_medium_industry, selected_small_industries,
              small_industry_nicknames, large_field_name, medium_field_name, small_field_name) {
    super(large_industries, medium_industries, small_industries,
          selected_large_industry, selected_medium_industry, selected_small_industries,
          small_industry_nicknames, '');
    this.large_field_name = large_field_name;
    this.medium_field_name = medium_field_name;
    this.small_field_name = small_field_name;
    this.food_court_field_name = null;
    this.form_visible = false;
    ko.track(this,
      [
        'form_visible',
        'selected_large_industry',
        'selected_medium_industry',
        'selected_small_industries',
        'small_industry_keywords',
      ]
    );
  }

  toggleForm() { return this.form_visible = !this.form_visible; }

  // @override
  selectLarge() { return this._selectLarge(); }

  // @override
  selectMedium() { return this._selectMedium(); }
}

ko.components.register('part-of-search_approximation_by_industries', {
  viewModel(params) { return new PartOfSearchApproximationByIndustries(
    params.large_industries,
    params.medium_industries,
    params.small_industries,
    params.selected_large_industry,
    params.selected_medium_industry,
    params.selected_small_industries,
    params.small_industry_nicknames,
    'type_industry1',
    'type_industry2',
    'type_industry3[]'
  ); },
  template: {element: 'part-of-search_approximation_by_industries'}
});