import { getModelId } from './utilities';
import { C3ScTypeChart } from './charts/c3_sc_type_chart';
import { C3ShopCompanyIndustryChart } from './charts/c3_shop_compnay_industry_chart';
import { C3OccupancyChart } from './charts/c3_occupancy_chart';
import { C3OccupancyPrefsChart } from './charts/c3_occupancy_prefs_chart';
import { D3OccupancyMap } from './charts/d3_occupancy_map';

$(function () {
  // ショップ運営企業のブランドが出店しているSCのタイプのグラフ
  if ($('#shop-company-sc-type-column-chart').is('*')) {
    getShopCompanyScTypeChart();
  }

  // ショップ運営企業のブランドの業種別のグラフ
  if ($('#shop-count-by-industry-column-chart').is('*')) {
    getShopBrandsIndustryTypeChart();
  }

  // ショップ運営企業の都道府県別出店数の地図
  if ($('#shop-company-occupancy-prefs').is('*')) {
    const occupancyPrefViewModel = new OccupancyPrefViewModel();
    app_view_model.addViewModels({ occupancyPrefViewModel });

    $.getJSON(
      Routes.occupancy_prefs_shop_company_path(getModelId(), {
        format: 'json',
      }),
      (data) => {
        D3OccupancyMap.show(
          '#shop-company-occupancy-prefs',
          data.all_pref_occupancy_data,
          (pref_name) =>
            (location.href = Routes.open_sc_list_shop_company_path(
              getModelId(),
              { pref: pref_name }
            ))
        );
        new C3OccupancyPrefsChart(
          '#shop-company-occupancy-prefs-column-chart',
          data.all_pref_occupancy_data,
          (pref_name) =>
            (location.href = Routes.open_sc_list_shop_company_path(
              getModelId(),
              { pref: pref_name }
            ))
        ).show();
        data.all_pref_occupancy_data.forEach((pref) =>
          occupancyPrefViewModel.prefs.push(
            new OccupancyPref(pref.key, pref.value)
          )
        );
      }
    );
  }

  // ショップ運営企業のブランドの出退店グラフ
  if ($('#shop-company-occupancy-chart').is('*')) {
    getShopCompanyOccupancyChart();
  }

  // ショップ運営企業のブランドの出退店データ
  if ($('#shop-company-occupancy-data').is('*')) {
    showShopCompanyOccupancyData();
  }

  if ($('form#shop_company_occupancy_detail').is('*')) {
    $('form#shop_company_occupancy_detail').submit(() => {
      const form_data = $('form#shop_company_occupancy_detail').serialize();
      getShopCompanyOccupancyChart(form_data);
      showShopCompanyOccupancyData(form_data);
    });
  }
});

function showShopCompanyOccupancyData(form_data) {
  $('#shop-company-occupancy-data').load(
    Routes.occupancy_shop_company_path(getModelId(), { format: 'html' }),
    form_data
  );
}

function getShopCompanyOccupancyChart(form_data) {
  $.getJSON(
    Routes.occupancy_shop_company_path(getModelId(), { format: 'json' }),
    form_data,
    (data) =>
      new C3OccupancyChart('#shop-company-occupancy-chart', data, function (
        d,
        element
      ) {
        const state = d.value >= 0 ? 'open' : 'close';
        const category = this.categories()[d.index];
        const element_id =
          'shop_company_occupancy_' + category.replace('/', '_') + `_${state}`;
        ko.postbox.publish('scrollToElementWithDetailHeader', { element_id });
      }).show()
  );
}

function getShopCompanyScTypeChart(form_data) {
  $.getJSON(
    Routes.sc_types_shop_company_path(getModelId(), { format: 'json' }),
    form_data,
    (data) =>
      new C3ScTypeChart(
        '#shop-company-sc-type-column-chart',
        data,
        (category) =>
          (location.href = Routes.open_sc_list_shop_company_path(getModelId(), {
            sc_type: category,
          }))
      ).show()
  );
}

function getShopBrandsIndustryTypeChart(form_data) {
  $.getJSON(
    Routes.shop_count_by_industry_shop_company_path(getModelId(), {
      format: 'json',
    }),
    form_data,
    (data) =>
      new C3ShopCompanyIndustryChart(
        '#shop-count-by-industry-column-chart',
        data
      ).show()
  );
}
