import { Memo } from "./modal_memo_item";

class NavMemoButtonViewModel {
  constructor(model_id, model_type, model_name, count) {
    this.model_id = model_id;
    this.model_type = model_type;
    this.model_name = model_name;
    this.count = count;
    this.url = location.href;
    this.loading_form = false;
    this.loading_list = false;
    ko.track(this);
    const self = this;
    ko.postbox.subscribe('showLoadingMemoButton', () => self.showLoadingList());
    ko.postbox.subscribe('hideLoadingMemoButton', () => self.hideLoadingList());

    ko.postbox.subscribe('resetCountMemoButton', params => self.count = params.count);

    ko.postbox.subscribe('showLoadingMemoForm', () => self.showLoadingForm());
    ko.postbox.subscribe('hideLoadingMemoForm', () => self.hideLoadingForm());
    ko.postbox.subscribe('incrementCountMemoButton', () => self.count += 1);
  }

  showMemoList(data, event) {
    if (this.count > 0) {
      ko.postbox.publish('showModalListMemo', {model_id: this.model_id, model_type: this.model_type, url: this.url});
    }
  }

  showMemoForm() {
    const memo = new Memo();
    memo.model = { id: this.model_id, type: this.model_type, name: this.model_name };
    ko.postbox.publish('showModalFormMemo', memo);
  }

  showTooltip(data, event) {
    if (this.count > 0) {
      $(event.toElement).tooltip('disable');
    } else {
      $(event.toElement).tooltip('enable').tooltip('show');
    }
  }

  showLoadingList() { this.loading_list = true; }
  hideLoadingList() { this.loading_list = false; }
  showLoadingForm() { this.loading_form = true; }
  hideLoadingForm() { this.loading_form = false; }
}

ko.components.register('memo-button', {
  viewModel(params) {
    return new NavMemoButtonViewModel(params.model_id, params.model_type, params.model_name, params.count);
  },
  template: `\
<div class="btn-group btn-group-sm navbar-btn" role="group">
  <button type="button" class="btn btn-default ladda-button"
    data-style="zoom-out"
    data-bind="click: showMemoList, event: { mouseover: showTooltip }, ladda: loading_list"
    data-placement="bottom"
    data-title="関連メモはまだありません"
  >関連メモ&nbsp;<span class="badge" data-bind="text: count"></span></button>
  <button class="btn btn-default ladda-button"
    data-style="zoom-out"
    type="button"
    data-bind="click: showMemoForm, ladda: loading_form"><span class="glyphicon glyphicon-edit"></span><span class="sr-only">メモを書く</span></button>
</div>\
`
});