import * as Ladda from 'ladda/js/ladda';

ko.bindingHandlers.ladda = {
    init: function(element, valueAccessor) {
        const l = Ladda.create(element);

        ko.computed({
            read: function() {
                const state = ko.unwrap(valueAccessor());
                if (state)
                    l.start();
                else
                    l.stop();
            },
            disposeWhenNodeIsRemoved: element
        });
    }
};