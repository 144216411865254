class ChangedFavoritesInfoViewModel {
  constructor() {
    this.state = false;
    this.buttonStatus = function() {
      if (this.isOpen()) {
        return 'btn-danger';
      } else {
        return 'btn-default';
      }
    };

    this.buttonText = function() {
      if (this.isOpen()) {
        return '折り畳む';
      } else {
        return 'さらに見る';
      }
    };

    ko.track(this);
  }

  toggleMoreInfo() {
    return this.state = !this.state;
  }


  isOpen() {
    return this.state;
  }
}

$(function() {
  if ($('#changed_favorites_info').is('*')) {
    app_view_model.addViewModels({changedFavoritesInfoViewModel: new ChangedFavoritesInfoViewModel()});
    $('.collapse').on('jqvs-changed', function(event, state) {
      if (ko.unwrap(state.shown)) {
        return $(this).collapse('show');
      } else {
        return $(this).collapse('hide');
      }
    });
  }

  if ($('.order_name').is('*')) {
    $('.order_name li a').click(function() {
      $(this).parent().parent().children('li').removeClass('stay');
      $(this).parent().addClass('stay');
    });
  }
});
