import { KnockoutModalBase } from "../../../shared/knockout/view_models/knockout_modal_base";

class ModalLoadConditionViewModel extends KnockoutModalBase {
  constructor() {
    super();
    this.conditions = [];
    this.oldTitle = '';
    ko.track(this);
    this.loadConditions();
    ko.postbox.subscribe('showModalLoadCondition', () => this.show());
  }

  loadConditions = () => {
    $.ajax({
      url: Routes.modal_stored_conditions_path({ format: 'json' }),
      method: 'GET'
    })
      .done((data, _status, _xhr) => {
        this.conditions = _.map(data, (x) => ko.track(x, ['title', 'isEdit']));
      })
      .fail((_xhr, _status, _error) => Notify.error());
  };

  onDelete = (condition) => {
    if (
      confirm(
        `『${_.escapeHTML(condition.title)}』を削除してもよろしいですか？`
      )
    ) {
      $.ajax({
        url: Routes.modal_stored_condition_path(condition.id, {
          format: 'json'
        }),
        method: 'DELETE'
      })
        .done((_data, _status, _xhr) => {
          Notify.success(
            `<strong>『${_.escapeHTML(
              condition.title
            )}』</strong> を削除しました`
          );
          this.conditions.remove(condition);
        })
        .fail((_xhr, _status, _error) => Notify.error());
    }
  };

  onUpdate = (condition) => {
    $.ajax({
      url: Routes.modal_stored_condition_path(condition.id, { format: 'json' }),
      method: 'PATCH',
      data: {
        stored_condition: {
          title: condition.title
        }
      }
    })
      .done((_data, _status, _xhr) => {
        Notify.success(
          `<strong>『${_.escapeHTML(condition.title)}』</strong> を更新しました`
        );
        condition.isEdit = false;
      })
      .fail((_xhr, _status, _error) => Notify.error());
  };

  onEdit = (condition) => {
    this.cancelEdit();
    condition.isEdit = true;
    this.oldTitle = condition.title;
  };

  onCancel = (condition) => {
    condition.isEdit = false;
    condition.title = this.oldTitle;
  };

  isDisabled = (condition) => {
    return condition.title.trim() === '';
  };

  cancelEdit = () => {
    const index = this.conditions.findIndex((x) => x.isEdit);
    if (index >= 0) {
      this.conditions[index].isEdit = false;
      this.conditions[index].title = this.oldTitle;
    }
  };

  show = () => {
    this.cancelEdit();
    super.show();
  };
}

ko.components.register('modal-load-condition', {
  viewModel() {
    return new ModalLoadConditionViewModel();
  },
  template: { element: 'modal-template' }
});

$(() => {
  $('#load-condition-button').click(() => {
    ko.postbox.publish('showModalLoadCondition');
  });
});
