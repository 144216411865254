window.industryClassName = function(type_industry1) {
  if (type_industry1 === null) { return ""; }
  const industry_types = {
    "10000": "01",
    "20000": "02",
    "30000": "03",
    "40000": "04",
    "50000": "05",
    "60000": "06",
    "70000": "07",
    "80000": "08",
    "90000": "09",
    "100000": "10",
    "110000": "11",
    "120000": "12",
  };
  return `text-shop-category_${industry_types[type_industry1]}`;
};

$(function() {
  // PMの検索モーダルダイアログの表示と、値のフィードバック
  const dialog = $('#pm-dialog');
  dialog.on('shown.bs.modal', () => $("#modal_pm_form input[type='text']").focus());
  dialog.on('hidden.bs.modal', function() {
    $("#modal_pm_form input[type='text']").each(function() {
      $(this).val("");
    });
    $("#data_ajax").empty();
  });

  return $("#data_ajax").on('click', '.selected_pm', function() {
    const row_number = $(this).closest('#pm-dialog').data('row_number');
    const pm_id = $(this).data('pm_id');
    const pm_name = $(this).data('pm_name');
    ko.postbox.publish('sendSearchShopBrandPms', {
      pm_id,
      pm_name,
      row_number
    }
    );
    dialog.modal('hide');
  });
});
