import { getModelId } from './utilities';
import { _BaseZenrinMapViewModel } from './_base_zenrin_map_view_model';
import { AdjacentScsMap } from './adjacent_scs_map'
import { LoadingModal } from '../shared/knockout/components/loading_modal';

export class AdjacentScsViewModel extends _BaseZenrinMapViewModel {
  constructor(distance=5000, input_type='select') {
    super(distance, input_type);
    this.sc = null;
    this.adjacent_scs = [];
    this.adjacent_detail_sc_url = function() {
      if (this.sc != null) {
        return Routes.adjacent_detail_sc_path(this.sc.id, {distance: this.getDistance(), input_type: this.input_type});
      }
    };
    this.adjacent_detail_close_shop_brand_sc_url = function() {
      if (this.sc != null) {
        return Routes.adjacent_detail_close_shop_brand_sc_path(this.sc.id, {distance: this.getDistance(), input_type: this.input_type, start_date: this.getOneYearAgo(), end_date: this.getOneMonthAgo()});
      }
    };
    this.adjacent_detail_open_shop_brand_sc_url = function() {
      if (this.sc != null) {
        return Routes.adjacent_detail_open_shop_brand_sc_path(this.sc.id, {distance: this.getDistance(), input_type: this.input_type, start_date: this.getOneYearAgo(), end_date: this.getOneMonthAgo()});
      }
    };

    ko.track(this);
    ko.getObservable(this, 'input_distance').extend({integer: true});
    ko.defineProperty(this, 'open_adjacent_scs', function() {
      if (this.sc == null) { return []; }
      return this.adjacent_scs.filter(adjacent_sc => // リニューアル済SCは表示しない
        adjacent_sc.has_children === false);
    });
  }

  searchAdjacentScs() {
    if (window.adjacentScsMap != null) { window.adjacentScsMap.drawCircleOval(); }
    const distance = this.getDistance();
    if (distance <= 0) {
      return Notify.danger("1m以上の半径を入力してください");
    } else {
      this.searching = true;
      LoadingModal.showSearching();
      $.getJSON(Routes.adjacent_sc_path(getModelId(), {format: 'json', distance}), (json) => {
        this.sc = json.sc;
        this.adjacent_scs = json.adjacent_scs;
        if (window.adjacentScsMap == null) {
          window.adjacentScsMap = new AdjacentScsMap(this,
            document.getElementById('adjacent-scs-map'),
            true,
            false,
            window.useMakepla
          );
        }
        window.adjacentScsMap.drawAll();
        this.searching = false;
        LoadingModal.hide();
      });
    }
  }

  convertToMonth(str_date) {
    const [year, month, day] = str_date.split('-');
    return `${year}/${month}`
  }

  // 先月から過去１年間の近隣の退店ショップを取得するために追加
  // 近隣の退店ショップURLのend_dateに使用
  getOneMonthAgo() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    if (month >= 10) {
      return `${year}/${month}`
    } else if (month === 0) {
      return `${year - 1}/12`
    } else {
      return `${year}/0${month}`
    }
  }

  // 先月から過去１年間の近隣の退店ショップを取得するために追加
  // 近隣の退店ショップURLのstart_dateに使用
  getOneYearAgo() {
    const date = new Date;
    const year = date.getFullYear() - 1;
    const month = date.getMonth() + 1;
    if (month >= 10) {
      return `${year}/${month}`
    } else {
      return `${year}/0${month}`
    }
  }

  getLatitude = () => this.sc.latitude

  getLongitude = () => this.sc.longitude
}
if (process.env.NODE_ENV != 'test') {
  $(function() {
    if ($('#adjacent-scs-map').is('*') && $('#adjacent-scs').is('*')) {
      window.adjacentScsMap = null;
      const distance = $('#adjacent-scs').data('distance');
      const input_type = $('#adjacent-scs').data('input_type');
      const adjacentScsViewModel = new AdjacentScsViewModel(distance, input_type);
      app_view_model.addViewModels({adjacentScsViewModel});
      adjacentScsViewModel.searchAdjacentScs();
    }
  });
}
