export class SearchByPm {
  constructor(selected_pms) {
    this.remove = this.remove.bind(this);
    this.edit = this.edit.bind(this);
    this.refresh = this.refresh.bind(this);
    if (selected_pms == null) { selected_pms = []; }
    this.rows = (selected_pms || []).map((selected_pm, index) => new PmRowViewModel(
      selected_pm.id,
      selected_pm.name,
      selected_pm.condition));
    ko.track(this);

    const self = this;
    ko.postbox.subscribe('sendSearchShopBrandPms', function(params) {
      if (_.chain(self.rows).pluck('id').includes(params.pm_id).value()) {
        // すでにあるので該当行を削除する
        self.rows.splice(params.row_number, 1);
      } else {
        const pm_row = new PmRowViewModel(params.pm_id, params.pm_name);
        self.rows[params.row_number] = pm_row;
        ko.getObservable(self, 'rows').notifySubscribers();
      }
    });
  }

  add() {
    $('#pm-dialog').data('row_number', this.rows.length);
    $('#pm-dialog').modal({keyboard: true, show: true});
  }

  remove(row) { return this.rows.remove(row); }

  edit(row) {
    const index = this.rows.indexOf(row);
    $('#pm-dialog').data('row_number', index);
    $('#pm-dialog').modal({keyboard: true, show: true});
  }

  refresh(row) { ko.getObservable(this, 'rows').notifySubscribers(); }

  clear() { this.rows = []; }
};

class PmRowViewModel {
  constructor(id=null, name=null, condition) {
    this.id = id;
    this.name = name;
    if (condition == null) { condition = '1'; }
    this.condition = condition;
    ko.track(this);
  }
}

ko.components.register('part-of-search-by-pms', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new SearchByPm(params.selected_pms);
    }
  },
  template: { element: 'pm-selectable' },
  synchronous: true
});