import { LoadingModal } from '../../../shared/knockout/components/loading_modal';

class AttackListDownloadButton {
  constructor(attack_list_id, with_hyperlink) {
    this.attack_list_id = attack_list_id;
    this.with_hyperlink = with_hyperlink;
  }

  build(sort) {
    const self = this;
    $.post(
      Routes.build_mypage_attack_list_path(this.attack_list_id, {
        with_hyperlink: this.with_hyperlink,
        sort,
      })
    )
      .done(function (response, textStatus, jqXHR) {
        let timer_id = setInterval(
          () =>
            self.confirm(response.key, function () {
              clearInterval(timer_id);
              self.download(response.key);
            }),
          1500
        );
        LoadingModal.showCreating({
          hide_callback: () => {
            clearInterval(timer_id);
          }, // キャンセルした場合は定期チェックを止める
        });
      })
      .fail(function (jqXHR, textStatus, errorThrown) {});
  }

  confirm(key, callback) {
    $.get(
      Routes.download_mypage_attack_list_path(this.attack_list_id, {
        key,
        format: 'json',
      })
    )
      .done(function (response, textStatus, jqXHR) {
        if (response.result === 'completed') {
          if (callback != null) {
            callback();
          }
          LoadingModal.hide();
        }
      })
      .fail(function (jqXHR, textStatus, errorThrown) {
        alert('エラーが発生しました');
        LoadingModal.hide();
      });
  }

  download(key) {
    location.href = Routes.download_mypage_attack_list_path(
      this.attack_list_id,
      { key, format: 'xlsx' }
    );
  }
}

ko.components.register('attack_list-download-button', {
  viewModel(params) {
    return new AttackListDownloadButton(
      params.attack_list_id,
      params.with_hyperlink
    );
  },
  template: `\
<div class="btn-group">
  <button type="button" class="btn btn-xs dropdown-toggle"
    data-bind="css: with_hyperlink ? 'btn-success' : 'btn-info'"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false">
    <span data-bind="text: with_hyperlink ? 'リンク有り' : 'リンク無し'"></span>
    <span class="caret"></span>
  </button>
  <ul class="dropdown-menu">
    <li><a data-bind="click: build.bind($data, 'shop_brand_name')">候補ショップ名順</a></li>
    <li><a data-bind="click: build.bind($data, 'shop_sc_count')">候補ショップの出店数順</a></li>
    <li><a data-bind="click: build.bind($data, 'shop_company_name')">ショップ運営企業名順</a></li>
  </ul>
</div>\
`,
});
