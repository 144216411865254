import { KnockoutModalBase } from "../../../shared/knockout/view_models/knockout_modal_base";

export class ModalFormAttackListCloseShopBrands extends KnockoutModalBase {
  constructor(max_candidate_size){
    super();
    this.max_candidate_size = max_candidate_size;
    this.sending = false;
    this.attack_list = null;
    this.targets = [];
    this.selected_close_shop_brand_id = null;
    ko.track(this);

    const self = this;
    ko.postbox.subscribe('showFormAttackListCloseShopBrandList', function(params) {
      self.attack_list = params.attack_list;
      self.targets = params.targets;
      self.show();
    });
  }

  checkParams() {
    return this.selected_close_shop_brand_id !== null;
  }

  actionUrl() {
    if (this.checkParams()) {
      return Routes.add_mypage_attack_list_attack_list_shop_brand_path(
        this.attack_list.id,
        this.selected_close_shop_brand_id,
        {format: 'json'}
      );
    } else {
      return '';
    }
  }

  newAttackListShopBrandUrl() {
    if (this.attack_list !== null) {
      const params = new URLSearchParams();
      this.targets.forEach(target => params.append(`${target.model_type}_id[]`, target.model_id));
      return Routes.new_mypage_attack_list_attack_list_shop_brand_path(this.attack_list.id) + '?' + params.toString();
    }
  }

  submitForm(form_element) {
    const self = this;
    self.sending = true;
    $.ajax({
      url: form_element.action,
      method: 'PATCH',
      data: $(form_element).serialize()
    }).done(function(data, status, xhr) {
      const close_shop_brand = data;
      const index = _.findIndex(self.attack_list.close_shop_brands, shop => shop.id === self.selected_close_shop_brand_id);
      self.attack_list.close_shop_brands[index] = close_shop_brand;
      Notify.success(`<strong>${_.escapeHTML(self.attack_list.title)}（${_.escapeHTML(close_shop_brand.shop_brand_name)}）</strong>に追加しました`);
    }).fail(function(xhr, status, statusText) {
      if (xhr.responseJSON.messages != null) {
        Notify.danger(`エラーが発生しました。<br>${xhr.responseJSON.messages.map(msg => _.escapeHTML(msg)).join('<br>')}`);
      } else {
        Notify.error();
      }
    }).always( function() {
      self.sending = false;
      self.hide();
    });
    return false;
  }

  initializeForm() {
    this.attack_list = null;
    this.targets = [];
    this.selected_close_shop_brand_id = null;
  }

  snakeToPascal(snake) {
    return _(snake).classify();
  }
};

ko.components.register('modal-form-attack_list-close_shop_brands', {
  viewModel(params) { return new ModalFormAttackListCloseShopBrands(params.max_candidate_size); },
  template: { element: 'modal-lg-template' }
});