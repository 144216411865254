import { BaseSearchByPref } from '../../_view_models/base_search_by_pref'

class PartOfSearchApproximationByPref extends BaseSearchByPref {
  constructor(regions, selected_prefs) {
    super(regions, selected_prefs);
    this.form_visible = false;
    ko.track(this, ['form_visible', 'selected_prefs', 'selected_regions']);
  }

  toggleForm() { return this.form_visible = !this.form_visible; }

  toggleRegion(region) { return this._toggleRegion(region); }
}

ko.components.register('part-of-search_approximation_by_prefs', {
  viewModel(params) { return new PartOfSearchApproximationByPref(params.regions, params.selected_prefs); },
  template: {element: 'part-of-search_approximation_by_prefs'}
});