import { PartOfSearchByDeveloper } from './part_of_search_by_developer';
import { PartOfSearchByPm } from './part_of_search_by_pm';
import { SearchByPref } from '../../../_view_models/search_by_pref';
import { PartOfSearchByShopBrand } from './part_of_search_by_shop_brand';
import { PartOfSearchScsByIndustries } from './part_of_search_scs_by_industries';
import { BaseSearchForm } from '../../../_view_models/base_search_form';
import { SearchByScTypeForSc } from '../search_by_sc_types_horizontal';

class SearchFormScs extends BaseSearchForm {
  constructor(
    initials,
    developers,
    pms,
    regions,
    sc_types,
    selected_sc_type_ids,
    selected_shop_brands,
    large_industries,
    medium_industries,
    small_industries,
    selected_large_industry,
    selected_medium_industry,
    selected_small_industries,
    selected_large_industry_exclude,
    selected_medium_industry_exclude,
    selected_small_industries_exclude,
    search_conditions = {},
    small_industry_nicknames
  ) {
    super(search_conditions);

    this.shop_count_gteq = search_conditions['shop_count_gteq'] || '';
    this.shop_count_lteq = search_conditions['shop_count_lteq'] || '';
    this.area_size = search_conditions['area_size'] || null;

    this.populations_area_gteq =
      this.area_size !== null
        ? search_conditions['populations_' + this.area_size + '_area_gteq'] ||
          ''
        : '';
    this.populations_area_lteq =
      this.area_size !== null
        ? search_conditions['populations_' + this.area_size + '_area_lteq'] ||
          ''
        : '';
    this.open_date_gteq = search_conditions['open_date_gteq'] || '';
    this.open_date_lteq = search_conditions['open_date_lteq'] || '';
    this.possible_parking_gteq =
      search_conditions['latest_sales_history_possible_parking_gteq'] || '';
    this.possible_parking_lteq =
      search_conditions['latest_sales_history_possible_parking_lteq'] || '';
    this.store_space_gteq =
      search_conditions['latest_sales_history_store_space_gteq'] || '';
    this.store_space_lteq =
      search_conditions['latest_sales_history_store_space_lteq'] || '';
    this.selected_food_court = !_.isBlank(search_conditions['food_court_true'])
      ? _.toBoolean(search_conditions['food_court_true'])
      : undefined;

    const developers_id =
      search_conditions['developers_id_eq'] != null
        ? _.toNumber(search_conditions['developers_id_eq'])
        : null;
    this.part_of_search_by_developer = new PartOfSearchByDeveloper(
      initials,
      developers,
      search_conditions['developer_initial'],
      developers_id
    );

    const pms_id =
      search_conditions['pms_id_eq'] != null
        ? _.toNumber(search_conditions['pms_id_eq'])
        : null;
    this.part_of_search_by_pm = new PartOfSearchByPm(
      initials,
      pms,
      search_conditions['pm_initial'],
      pms_id
    );

    this.part_of_search_scs_by_prefs = new SearchByPref(
      regions,
      search_conditions['address_pref_in_any'] || []
    );

    this.part_of_search_scs_by_sc_types = new SearchByScTypeForSc(
      sc_types,
      selected_sc_type_ids
    );

    this.part_of_search_scs_by_shop_brands = new PartOfSearchByShopBrand(
      selected_shop_brands
    );

    this.part_of_search_scs_by_industries_include =
      new PartOfSearchScsByIndustries(
        large_industries,
        medium_industries,
        small_industries,
        selected_large_industry,
        selected_medium_industry,
        selected_small_industries,
        true,
        small_industry_nicknames,
        '-include'
      );
    this.part_of_search_scs_by_industries_exclude =
      new PartOfSearchScsByIndustries(
        large_industries,
        medium_industries,
        small_industries,
        selected_large_industry_exclude,
        selected_medium_industry_exclude,
        selected_small_industries_exclude,
        false,
        small_industry_nicknames,
        '-exclude'
      );
    this.initialize();
    this.setDelayEvaluate(this, 'shop_count_gteq');
    this.setDelayEvaluate(this, 'shop_count_lteq');
    this.setDelayEvaluate(this, 'populations_area_gteq');
    this.setDelayEvaluate(this, 'populations_area_lteq');
    this.setDelayEvaluate(this, 'open_date_gteq');
    this.setDelayEvaluate(this, 'open_date_lteq');
    this.setDelayEvaluate(this, 'possible_parking_gteq');
    this.setDelayEvaluate(this, 'possible_parking_lteq');
    this.setDelayEvaluate(this, 'store_space_gteq');
    this.setDelayEvaluate(this, 'store_space_lteq');
  }

  // @override
  canRequest() {
    if (this.populationsAreaIsNotSelect()) {
      return false;
    }
    if (this.populationsAreaIsEmpty()) {
      return false;
    }
    return _.any(
      [
        this.keywords,
        this.keywords_not,
        this.part_of_search_by_developer.selected_developer_id,
        this.part_of_search_by_pm.selected_pm_id,
        this.part_of_search_scs_by_prefs.selected_prefs,
        this.part_of_search_scs_by_sc_types.selected_sc_type_ids,
        this.part_of_search_scs_by_shop_brands.rows,
        this.part_of_search_scs_by_industries_include.selected_large_industry,
        this.part_of_search_scs_by_industries_include.selected_medium_industry,
        this.part_of_search_scs_by_industries_include.selected_small_industries,
        this.part_of_search_scs_by_industries_exclude.selected_large_industry,
        this.part_of_search_scs_by_industries_exclude.selected_medium_industry,
        this.part_of_search_scs_by_industries_exclude.selected_small_industries,
        this.shop_count_gteq,
        this.shop_count_lteq,
        this.area_size,
        this.populations_area_gteq,
        this.populations_area_lteq,
        this.open_date_gteq,
        this.open_date_lteq,
        this.possible_parking_gteq,
        this.possible_parking_lteq,
        this.store_space_gteq,
        this.store_space_lteq,
        this.selected_food_court,
      ],
      function (value) {
        if (_.isNumber(value)) {
          value = value.toString(10);
        }
        if (_.isNull(value)) {
          return false;
        } else if (_.isBoolean(value)) {
          return true;
        } else {
          return (value || '').length > 0;
        }
      }
    );
  }

  // @override
  getSearchCountUrl(params) {
    return Routes.count_scs_path({ key: params.key, format: params.format });
  }

  // @override
  clearSearchConditions() {
    this.keywords = '';
    this.keywords_not = '';
    this.shop_count_gteq = '';
    this.shop_count_lteq = '';
    this.clearAreaSize();
    this.populations_area_gteq = '';
    this.populations_area_lteq = '';
    this.open_date_gteq = '';
    this.open_date_lteq = '';
    this.possible_parking_gteq = '';
    this.possible_parking_lteq = '';
    this.store_space_gteq = '';
    this.store_space_lteq = '';
    this.part_of_search_scs_by_shop_brands.clear();
    this.part_of_search_scs_by_industries_include.clear();
    this.part_of_search_scs_by_industries_exclude.clear();
    this.part_of_search_scs_by_prefs.unselectAll();
    this.part_of_search_scs_by_sc_types.unselectAll();
    this.part_of_search_by_pm.clear();
    this.part_of_search_by_developer.clear();
    this.clearSelectedFoodCourt();
  }

  populationsAreaIsEmpty() {
    return (
      this.area_size !== null &&
      this.populations_area_gteq.length === 0 &&
      this.populations_area_lteq.length === 0
    );
  }

  populationsAreaIsNotSelect() {
    return (
      this.area_size === null &&
      (this.populations_area_gteq.length > 0 ||
        this.populations_area_lteq.length > 0)
    );
  }

  clearAreaSize() {
    this.area_size = null;
  }

  clearSelectedFoodCourt() {
    this.selected_food_court = null;
  }
}

ko.components.register('search-form-scs', {
  viewModel(params) {
    return new SearchFormScs(
      params.initials,
      params.developers,
      params.pms,
      params.regions,
      params.sc_types,
      params.selected_sc_type_ids,
      params.selected_shop_brands,
      params.large_industries,
      params.medium_industries,
      params.small_industries,
      params.selected_large_industry,
      params.selected_medium_industry,
      params.selected_small_industries,
      params.selected_large_industry_exclude,
      params.selected_medium_industry_exclude,
      params.selected_small_industries_exclude,
      params.search_conditions,
      params.small_industry_nicknames
    );
  },
  template: { element: 'ko-normal-template' },
});
