ko.components.register('search_by_prefs-horizontal', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new SearchByPref(params.regions, params.selected_prefs);
    }
  },
  template: { element: 'part-of-search_by_prefs-horizontal' },
  synchronous: true
});