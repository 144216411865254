$(function() {
  if ($('#copy_from_leader_to_billing').is('*')) {
    $('#copy_from_leader_to_billing').click(function() {
      const column_names = [
        'company',
        'postal_code',
        'address_pref',
        'address_city',
        'address_section',
        'address_building',
        'tel',
        'fax'
      ];
      $.each(column_names, function(i, column_name) {
        const value = $("#billing_address_leader_" + column_name).val();
        $(`#billing_address_billing_${column_name}`).val(value);
      });
      const value = $('#billing_address_quarter').val();
      return $('#billing_address_billing_quarter').val(value);
    });
  }
});
