export function fixTableHeader() {
  const fixed_theader = $('#compare-header-fixed');
  const normal_theader = $('#compare-header-normal');
  const legend_labels = $('#legend-duplicate-label');
  const compare_table = normal_theader.parent();
  const navbar_height = 46;
  const sum_padding_top_bottom = 10;
  const fixed_area_height = legend_labels.is('*') ?
    navbar_height + sum_padding_top_bottom + legend_labels.height()
  :
    navbar_height + sum_padding_top_bottom;
  const fixed_theader_top = normal_theader.offset().top - fixed_area_height;
  $(window).scroll(function() {
    // スクロール外でcompare_table_bottomを取得すると、動かないため、毎度計算させてる
    // しかし、遅くなるので、いい方法があれば変えたい…
    let middle;
    const compare_table_bottom = (compare_table.height() + compare_table.offset().top) - normal_theader.height() - fixed_area_height;
    if (compare_table_bottom > (middle = $(window).scrollTop()) && middle > fixed_theader_top) {
      fixed_theader.show();
    } else {
      fixed_theader.hide();
    }
  });
};

// 比較テーブルのヘッダーを固定するためのカスタムバインディング
ko.bindingHandlers.fixTableHeader = {
  update(element, valueAccessor) {
    const is_show = ko.unwrap(valueAccessor());
    if (is_show) {
      $(element).show();
      fixTableHeader();
    } else {
      $(element).hide();
    }
  }
};
