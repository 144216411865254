import { IndustryWithSuggestedKeywords } from "../../../_view_models/industry_with_suggested_keywords";

class SuggestedKeywordsEachIndustries {
  constructor(large_industries) {
    this.changeActive = this.changeActive.bind(this);
    this.industries = large_industries.map((industry, i) => {
      const active = i === 0;
      return new IndustryWithSuggestedKeywords(
        industry.name,
        industry.type_industry1,
        active,
        industry.suggested_keywords
      )
    });
    ko.track(this, ['industries']);
  }

  changeActive(item) {
    this.industries.forEach(industry => industry.active = false);
    item.active = true;
    this.showSuggestedKeywords();
  }

  showSuggestedKeywords() {
    const selected_industry = _.find(this.industries, industry => industry.active);
    document.querySelectorAll('[class^="type_industry_"]').forEach(element => element.classList.add('hidden'));
    document.querySelector(`.type_industry_${selected_industry.type_industry1}`).classList.remove('hidden')
  }
}

ko.components.register('suggested-keywords-each-industries', {
  viewModel(params) {
    return new SuggestedKeywordsEachIndustries(params.large_industries);
  },
  template: { element: 'ko-normal-template' },
  synchronous: true
});