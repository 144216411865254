import 'jquery-match-height';
import imagesLoaded from 'imagesloaded';

imagesLoaded.makeJQueryPlugin($);

$(() => {
  $(document).on('change', '#all_japan', function () {
    $("[name='advertise[pref_ids][]'").prop('checked', $(this).prop('checked'));
  });

  $(document).on('change', '.all_region', function () {
    const region = $(this).val();
    $(`#advertise_pref_ids_[region='${region}']`).prop(
      'checked',
      $(this).prop('checked')
    );
  });

  // /mypage/advertises用
  if ($('#advertise_form').is('*')) {
    $(document).on('focus', 'input#advertise_open_date_input', () => {
      $('#advertise_open_date_input').datepicker({
        format: 'yyyy/mm',
        language: 'ja',
        autoclose: true,
        minViewMode: 'months',
      });
    });
  }

  // /search/advertises用
  if ($('#advertise_search').is('*')) {
    $('#q_open_date_gteq').datepicker({
      format: 'yyyy/mm',
      language: 'ja',
      autoclose: true,
      minViewMode: 'months',
    });
  }

  $(document.body).imagesLoaded({ background: true }, () =>
    $('.thumbnail').matchHeight()
  );
});
