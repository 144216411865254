$(function() {
  if ($('body#search').is('*')) {
    const dialog = $('#shop_brand-dialog');
    dialog.on('shown.bs.modal', () => $("#modal_shop_brand_form input[type='text']").focus());
    dialog.on('hidden.bs.modal', function() {
      $("#modal_shop_brand_form input[type='text']").each(function() {
        $(this).val("");
      });
      $("#data_ajax").empty();
    });

    $("#data_ajax").on('click', '.selected_shop_brand', function() {
      const row_number = $(this).closest('#shop_brand-dialog').data('row_number');
      const shop_brand_id = $(this).data('shop_brand_id');
      const shop_brand_name = $(this).data('shop_brand_name');
      const class_name = $(this).data('shop_brand_class_name');
      ko.postbox.publish('sendSearchScShopBrands', {
        shop_brand_id,
        shop_brand_name,
        class_name,
        row_number
      }
      );
      dialog.modal('hide');
    });
  }

  $('#similar_scs a[data-toggle="tab"]').on('show.bs.tab', function(event) {
    const element = $(event.target.hash);
    if (element.is(':empty')) { element.load(element.data('url')); }
  });

  if ($('#similar_scs .tab-pane').is('*')) { $('#similar_scs ul.nav-tabs a:first').tab('show'); }
});