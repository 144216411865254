$(function() {
  $(document).on('focus', 'input.date_picker', function() { return setDatePicker(this); });
  $(document).on('focus', 'input.date_range', function() { return setDatePicker(this); });
});

window.setDatePicker = function(elm) {
  $(elm).datepicker({
    format: 'yyyy/mm/dd',
    language: 'ja',
    autoclose: true
  });
//    altFormat: "yy-mm-dd"
//    dateFormat: "yy-mm-dd"
//    altField: $(elm).next()
  $(elm).change(() => $(elm).next().val($(elm).val()));
};
