import { C3ButtonDropdown } from "./c3_button_dropdown";
import { C3AxisImpl } from "./c3_axis_impl";
import { getPageTitle } from "../utilities";
import * as d3 from 'd3';

export class C3OccupancyPrefsChart extends C3ButtonDropdown {
  constructor(element_id, json_data, click_event) {
    super(element_id);
    this.json_data = json_data;
    this.click_event = click_event;
    Object.assign(C3OccupancyPrefsChart.prototype, C3AxisImpl);
  }

  show() {
    const self = this;
    const max_count = d3.max(_.pluck(this.json_data, 'value'));
    this.generate({
      title: {
        text: `${getPageTitle()}の都道府県別出店数`,
        position: 'top-center'
      },
      size: {
        height: 400
      },
      data: {
        json: this.json_data,
        type: 'bar',
        keys: {
          value: ['value']
        },
        names: {
          value: '出店数'
        },
        classes: {
          count: 'c3-occupancy_prefs'
        },
        colors: {
          value: '#59B200'
        },
        labels: true,
        onclick(d, element) {
          return self.click_event(_.pluck(self.json_data, 'key')[d.index]);
        }
      },
      bar: {
        zerobased: true
      },
      axis: {
        x: {
          type: 'category',
          categories: _.pluck(this.json_data, 'key'),
          tick: {
            rotate: -90,
            multiline: false,
          },
          height: 50
        },
        y: {
          label: {
            text: '出店数',
            position: 'outer-middle',
          },
          tick: {
            format: d3.format('01,d'),
            values: self._getAxisYTickValues(max_count)
          },
          min: 0,
          padding: { bottom: 0 },
          max: self._getAxisYMax(max_count)
        }
      },
      legend: {
        hide: true
      }
    });
  }
};
