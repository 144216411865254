// いつもNAVI API 3.0 JavaScript API マニュアル
// https://support.e-map.ne.jp/manuals/V30/

import { _BaseZenrinMapViewModel } from './_base_zenrin_map_view_model';
import { AdjacentScsMap } from './adjacent_scs_map';
import store from 'store';

class SearchScsOnMapViewModel extends _BaseZenrinMapViewModel {
  // 有効期限は1時間
  static DURATION = 60 * 60 * 1000;

  /**
   * コンストラクタ
   * @param {number} distance 中心からの距離(メートル)
   * @param {string} input_type 入力タイプ
   * @param {Array<string>} address_prefs 都道府県名の配列
   */
  constructor(distance = 5000, input_type = 'select', address_prefs) {
    super(distance, input_type);
    this.address_prefs = address_prefs;
    this.address_cities = [];
    this.address_sections = [];
    this.selected_pref = '';
    this.selected_city = '';
    this.selected_section = '';

    // デフォルトは東京都庁
    const search_conditions = store.get('searchScsOnMap');
    if (search_conditions != null) {
      this.latlon = new ZDC.LatLon(
        search_conditions.latitude,
        search_conditions.longitude
      );
      this.input_distance = search_conditions.input_distance;
      this.selected_distance = search_conditions.selected_distance;
      this.input_type = search_conditions.input_type;
      this.selected_pref = search_conditions.selected_pref;
    } else {
      this.latlon = new ZDC.LatLon(35.6863167, 139.6950472);
      this.selected_pref = '東京都';
    }

    this.address = null;
    this.adjacent_scs = [];

    ko.track(this, [
      'latlon',
      'address',
      'input_distance',
      'selected_distance',
      'input_type',
      'selected_genres',
      'searching',
      'adjacent_scs',
      'address_prefs',
      'address_cities',
      'address_sections',
      'selected_pref',
      'selected_city',
      'selected_section',
    ]);
    ko.getObservable(this, 'input_distance').extend({ integer: true });
    ko.defineProperty(this, 'open_adjacent_scs', () => {
      return this.adjacent_scs.filter(
        (
          adjacent_sc // リニューアル済SCは表示しない
        ) => adjacent_sc.has_children === false
      );
    });
    ko.defineProperty(this, 'search_address', () => {
      return _.escapeHTML(
        (this.selected_pref || '') +
          (this.selected_city || '') +
          this.selected_section
      );
    });

    if (search_conditions != null) {
      this.selected_genres = this.genres.filter((genre) =>
        _.includes(
          search_conditions.selected_genres.map(
            (selected_genre) => selected_genre.name
          ),
          genre.name
        )
      );
      this.changeCities(() => {
        this.selected_city = search_conditions.selected_city;
        this.changeSections(
          () => (this.selected_section = search_conditions.selected_section)
        );
      });
    } else {
      this.changeCities(() => {
        this.selected_city = '新宿区';
        this.changeSections(() => (this.selected_section = ''));
      });
    }
    ko.getObservable(this, 'selected_city').subscribe(() =>
      this.changeSections()
    );
    this.searchAdjacentScs();
  }

  /**
   * 日本測地系を世界測地系に変換して緯度を取得
   * @return {number} 緯度
   */
  getLatitude() {
    return ZDC.tkyTowgs(this.latlon).lat;
  }

  /**
   * 日本測地系を世界測地系に変換して経度を取得
   * @return {number} 経度
   */
  getLongitude() {
    return ZDC.tkyTowgs(this.latlon).lon;
  }

  /**
   * 入力された住所から緯度経度を検索して移動する
   */
  searchAddress() {
    if (this.checkParams()) {
      const params = { word: this.search_address };
      ZDC.Search.getByZmaps('address/word', params, (status, res) => {
        if (status.code === 200) {
          if (res.item.length > 0) {
            const item = res.item[0];
            this.address = item.text;
            const itemLatlon = new ZDC.LatLon(item.point.lat, item.point.lon);
            window.adjacentScsMap.moveAndDraw(itemLatlon);
          } else {
            alert('みつかりませんでした。');
          }
        } else {
          alert(`エラーが発生しました。\ntext: ${_.escapeHTML(status.text)}`);
        }
      });
    }
  }

  /**
   * 近隣のSCをSC GATEに問い合わせる
   */
  searchAdjacentScs() {
    if (window.adjacentScsMap != null) {
      window.adjacentScsMap.drawCircleOval();
    }
    const distance = this.getDistance();
    if (distance === 0) {
      alert('半径を入力してください');
      return;
    }
    this.searching = true;
    // ゼンリンは日本測地系で、DB内のデータは世界測地系なので、変換しなければならない
    $.getJSON(
      Routes.result_on_map_scs_path({
        format: 'json',
        distance,
        longitude: this.getLongitude(),
        latitude: this.getLatitude(),
      }),
      (json) => {
        this.adjacent_scs = json.adjacent_scs;
        if (window.adjacentScsMap == null) {
          window.adjacentScsMap = new AdjacentScsMap(
            this,
            document.getElementById('search-scs-on-map'),
            true,
            true,
            window.useMakepla
          );
        }
        window.adjacentScsMap.drawAll();
        this.saveSearchConditionsToLocalStorage();
        this.searching = false;
      }
    );
  }

  /**
   * 検索条件をLocalStorageに保存する
   */
  saveSearchConditionsToLocalStorage() {
    store.set(
      'searchScsOnMap',
      {
        latitude: this.latlon.lat,
        longitude: this.latlon.lon,
        selected_pref: this.selected_pref,
        selected_city: this.selected_city,
        selected_section: this.selected_section,
        input_distance: this.input_distance,
        selected_distance: this.selected_distance,
        input_type: this.input_type,
        selected_genres: this.selected_genres,
      },
      new Date().getTime() + SearchScsOnMapViewModel.DURATION
    );
  }

  /**
   * 選択された都道府県を基に市区町村を取得する
   * @param {Function} callback
   */
  changeCities(callback) {
    this.selected_city = '';
    this.selected_section = '';
    this.address_sections.removeAll();
    this.address_cities.removeAll();
    if (!_.isBlank(this.selected_pref)) {
      $.getJSON(
        Routes.cities_scs_path({ pref: this.selected_pref, format: 'json' })
      )
        .done((address_cities) => {
          this.address_cities = address_cities;
          if (callback != null && typeof callback === 'function') {
            return callback();
          }
        })
        .fail((jqXHR, textStatus, errorThrown) =>
          alert(_.escapeHTML(jqXHR.responseJSON.error))
        );
    }
  }

  /**
   * 選択された都道府県・市区町村を基に詳細な住所を取得する
   * @param {Function} callback
   */
  changeSections(callback) {
    this.selected_section = '';
    this.address_sections.removeAll();
    if (!_.isBlank(this.selected_city)) {
      $.getJSON(
        Routes.sections_scs_path({
          pref: this.selected_pref,
          city: this.selected_city,
          format: 'json',
        })
      ).done((address_sections) => {
        this.address_sections = address_sections;
        if (callback != null && typeof callback === 'function') {
          return callback();
        }
      });
    }
  }

  /**
   * 検索される住所が入力されているかチェックする
   * @return {boolean} 住所が入力されていたらtrueを返す
   */
  checkParams() {
    return !_.isBlank(this.search_address);
  }
}

if (process.env.NODE_ENV != 'test') {
  ko.components.register('search-scs-on-map', {
    viewModel(params) {
      return new SearchScsOnMapViewModel(5000, 'select', params.prefs);
    },
    template: { element: 'ko-normal-template' },
  });
}
