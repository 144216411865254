export class PartOfSearchByShopBrand {
  constructor(selected_shop_brands) {
    this.remove = this.remove.bind(this);
    this.edit = this.edit.bind(this);
    this.refresh = this.refresh.bind(this);
    if (selected_shop_brands == null) { selected_shop_brands = []; }
    this.rows = (selected_shop_brands || []).map((selected_shop_brand, index) => new ShopBrandRowViewModel(
      selected_shop_brand.id,
      selected_shop_brand.name,
      selected_shop_brand.class_name,
      selected_shop_brand.condition));
    ko.track(this);

    const self = this;
    ko.postbox.subscribe('sendSearchScShopBrands', function(params) {
      if (_.chain(self.rows).pluck('id').includes(params.shop_brand_id).value()) {
        // すでにあるので該当行を削除する
        return self.rows.splice(params.row_number, 1);
      } else {
        const shop_brand_row = new ShopBrandRowViewModel(
          params.shop_brand_id,
          params.shop_brand_name,
          params.class_name
        );
        self.rows[params.row_number] = shop_brand_row;
        ko.getObservable(self, 'rows').notifySubscribers();
      }
    });
  }

  add() {
    $('#shop_brand-dialog').data('row_number', this.rows.length);
    $('#shop_brand-dialog').modal({keyboard: true, show: true});
  }

  remove(row) { this.rows.remove(row); }

  edit(row) {
    const index = this.rows.indexOf(row);
    $('#shop_brand-dialog').data('row_number', index);
    $('#shop_brand-dialog').modal({keyboard: true, show: true});
  }

  refresh(row) { ko.getObservable(this, 'rows').notifySubscribers(); }

  clear() { this.rows = []; }
};

class ShopBrandRowViewModel {
  constructor(id=null, name=null, class_name=null, condition) {
    this.id = id;
    this.name = name;
    this.class_name = class_name;
    if (condition == null) { condition = '1'; }
    this.condition = condition;
    ko.track(this);
  }
}

ko.components.register('part-of-search-by-shop_brands', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new PartOfSearchByShopBrand(params.selected_shop_brands);
    }
  },
  template: { element: 'shop_brand-selectable' },
  synchronous: true
});