export class SearchShopCompanyPrefsViewModel {
  constructor(prefs, or_conditions) {
    this.remove = this.remove.bind(this);
    this.edit = this.edit.bind(this);
    this.prefs = prefs;
    if (or_conditions == null) { or_conditions = []; }
    this.or_conditions = [];
    ko.track(this);

    const self = this;
    // 検索結果から戻ってきた際に、検索条件をフォームに反映する
    this.or_conditions = _(or_conditions).toArray().map(function(or_condition) {
      const {
        pref_id
      } = or_condition;
      const pref = _.find(self.prefs, pref => pref.id === _.toNumber(pref_id));
      const cities = or_condition.cities || [];
      return new SearchShopCompanyPrefConditionViewModel(pref, cities);
    });

    // モーダルが閉じる時に検索条件を送ってくるのでそれを受け取って条件に入れる
    ko.postbox.subscribe('addSearchShopCompanyPrefCondition', function(params) {
      const changed_index = self.or_conditions.indexOf(params.item);
      if ((params.item.pref !== null) && (changed_index === -1)) {
        self.or_conditions.push(params.item);
      } else if (changed_index >= 0) {
        ko.getObservable(self, 'or_conditions').notifySubscribers();
      }
      if (params.add_and_next) { return self.add(); }
    }); // 続けて追加ボタンが押された場合は直ちにモーダル立ち上げ
  }


  remove(item) { this.or_conditions.remove(item); }

  edit(item) { this.showModalSelectPrefCities(item); }

  add() { this.showModalSelectPrefCities(new SearchShopCompanyPrefConditionViewModel()); }

  showModalSelectPrefCities(search_shop_company_pref_condition) {
    ko.postbox.publish(
      'showModalSearchShopCompanyPrefCondition',
      {search_shop_company_pref_condition}
    );
  }

  clear() { this.or_conditions = []; }
};

export class SearchShopCompanyPrefConditionViewModel {
  constructor(pref = null, cities) { this.pref = pref; if (cities == null) { cities = []; } this.cities = cities; ko.track(this); }
};

ko.components.register('search_shop_company_prefs', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new SearchShopCompanyPrefsViewModel(params.prefs, params.or_conditions);
    }
  },
  template: `\
<div class="form-group">
  <label class="col-xs-3 control-label">本社所在地</label>
  <div class="col-xs-9">
    <div class="row">
      <div class="col-xs-12">
        <div class="panel panel-default">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th class="col-xs-3">都道府県</th>
                <th class="col-xs-5">市区町村</th>
                <th class="col-xs-2"></th>
                <th class="col-xs-2"></th>
              </tr>
            </thead>
            <tbody data-bind="foreach: or_conditions">
              <tr>
                <td data-bind="text: pref.name"></td>
                <td>
                  <!-- ko if: cities.length > 0 -->
                    <ul class="list-inline" data-bind="foreach: { data: cities, as: 'city'}">
                      <li data-bind="text: city"></li>
                    </ul>
                  <!-- /ko -->
                  <!-- ko ifnot: cities.length > 0-->
                    全域
                  <!-- /ko -->
                </td>
                <td>
                  <!-- ko if: cities.length > 0 -->
                  <span>いずれかに所在</span>
                  <!-- /ko -->
                </td>
                <td class="text-center">
                  <button type="button" class="btn btn-xs btn-default" data-bind="click: $parent.edit">編集</button>
                  <button type="button" class="btn btn-xs btn-danger" data-bind="click: $parent.remove">削除</button>
                  <input type="hidden" data-bind="attr: {
                      name: 'pref_conditions[' + $index() + '][pref_id]'
                    }, value: pref.id">
                  <!-- ko foreach: { data: cities, as: 'city' } -->
                  <input type="hidden" data-bind="attr: {
                      name: 'pref_conditions[' + $parentContext.$index() + '][cities][]'
                    }, value: city">
                  <!-- /ko -->
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" class="text-right">
                  <button type="button" class="btn btn-xs btn-default" data-bind="click: add">
                    OR条件を追加
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>\
`,
  synchronous: true
});