export class SearchModelForCompare {
  constructor() {
    this.searching = false;
    this.submitting = false;
    this.form_element = null;

    this.parent_view_model = null;
  }

  initialize() {
    ko.track(this);

    ko.postbox.subscribe('submitSearchForm', () => this.submitSearchForm());

    ko.defineProperty(this, 'is_enable', () => (this.searching === false) && (this.submitting === false));
    ko.defineProperty(this, 'text_submit_btn', function() {
      if ((this.searching === false) && (this.submitting === false)) {
        return '検索';
      } else if (this.searching === true) {
        return '件数取得中';
      } else {
        return '送信中';
      }
    });

    $(document).on('ajax:complete', this.form_element, () => {
      this.initializeSearchButton();
    });
  }

  setDelayEvaluate(scope, property_name) {
    ko.getObservable(scope, property_name).extend({ rateLimit: 400 });
  }

  setSelectedModelColor() {
    const buttons = document.querySelectorAll('#modal-search-result .btn-default');
    if (this.parent_view_model.list.length > 0) {
      const search_results = Array.from(buttons).map((element) => parseInt(element.dataset.model_id, 10));
      this.parent_view_model.list.forEach((model) => {
        const index = search_results.indexOf(model.model_id);
        if (index !== -1) {
          Array.from(buttons)[index].closest('tr').classList.add('info');
        }
      });
    }
  }

  clearSearchConditions() {
    throw new Error('実装してください');
  }

  submitSearchForm() {
    this.submitting = true;
    if (this.form_element != null) { return $(this.form_element).submit(); }
  }

  initializeSearchButton() {
    this.submitting = false;
    this.searching = false;
  }
}