import { LoadingModal } from "../shared/knockout/components/loading_modal";

$(function() {
  $(document).on('mouseenter', '.text-compare', function(event) {
    const shop_brand_id = $(this).data('shop_brand_id');
    if (shop_brand_id != null) {
      const filtered_elements = $('.text-compare').filter(function() {
        return $(this).data('shop_brand_id') === shop_brand_id;
      });
      if (filtered_elements.length >= 2) { filtered_elements.addClass('text-blink'); }
    }
  });

  $(document).on('mouseleave', '.text-compare', function(event) {
    const shop_brand_id = $(this).data('shop_brand_id');
    if (shop_brand_id != null) {
      const filtered_elements = $('.text-compare').filter(function() {
        return $(this).data('shop_brand_id') === shop_brand_id;
      });
      if (filtered_elements.length >= 2) { filtered_elements.removeClass('text-blink'); }
    }
  });

  $(document).on('click', '.btn-collapse-list', function(event) {
    const self = this;
    const collapse_area = $(self).closest('.title-bar').next();
    if (collapse_area.is(':empty')) {
      const compare_condition_key = collapse_area.data('compare_condition_key');
      const loadBothBrandList = func => $.get(Routes.compare_both_brand_list_path({compare_condition_key}), function(response) {
        if ((response != null) && (response.length > 0) && collapse_area.is(':empty')) {
          if (func != null) { func(); }
          collapse_area.html(response);
          LoadingModal.hide();
          collapse_area.collapse('toggle');
          return;
        }
      });

      loadBothBrandList(); // 共通ショップのリストデータ作成依頼。既にあったら表示する
      let timer_id = setInterval( () => // データができたかどうか定期的にチェックする
        loadBothBrandList(() => clearInterval(timer_id))
      , 1500);

      LoadingModal.showAnalyzing({
        hide_callback: () => { // キャンセルした場合は定期チェックを止める
          clearInterval(timer_id);
          if (collapse_area.is(':empty')) {
            $(self).removeClass('btn-danger').addClass('btn-default').text('一覧を表示');
          } else {
            $(self).removeClass('btn-default').addClass('btn-danger').text('一覧非表示');
          }
        }
      });
    } else {
      collapse_area.collapse('toggle');
      if ($(self).hasClass('btn-default')) {
        $(self).removeClass('btn-default').addClass('btn-danger').text('一覧非表示');
      } else {
        $(self).removeClass('btn-danger').addClass('btn-default').text('一覧を表示');
      }
    }
  });
});

