import { Spinner } from 'spin.js/spin';

ko.bindingHandlers.spinner = {
  update(element, valueAccessor) {
    let spinner;
    const visibility = ko.unwrap(valueAccessor());
    const options =  {
      color: '#fff',
      length: 6,
      width: 2,
      radius: 6,
      top: '25%',
      left: '63%'
    };
    if (visibility) {
      spinner = new Spinner(options).spin();
      $(spinner.el).appendTo(element).hide().fadeIn();
    } else {
      spinner = $(element).find('.spinner');
      if (spinner.is('*')) {
        $(spinner).fadeOut( () => spinner.remove());
      }
    }
  }
};
ko.expressionRewriting._twoWayBindings['spinner'] = true;