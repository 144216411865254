import { SelectModel } from '../../select_model';
import { SelectShopBrand } from '../../select_model';

class AttackListShopBrandsViewModel {
  constructor(max_candidate_count, shop_brand_name, candidate_targets) {
    this.removeRow = this.removeRow.bind(this);
    this.max_candidate_count = max_candidate_count;
    this.shop_brand_name = shop_brand_name ?? '';
    candidate_targets ??= [];
    this.list = [
      ...candidate_targets.map((x) => {
        if (x.model_type === 'shop_brand') {
          return new SelectShopBrand(
            x.id,
            x.name,
            x.model_type,
            x.type_industry1,
            x.type_industry2,
            x.type_industry3
          );
        } else if (x.model_type === 'shop_company') {
          return new SelectModel(x.id, x.name, x.model_type);
        }
      })
    ];
    this.list_changeable = () => {
      return this.list.length < this.max_candidate_count;
    };
    this.form_enable = () => {
      return (
        this.shop_brand_name.length > 0 &&
        this.list.length <= this.max_candidate_count
      );
    };
    ko.track(this);
  }

  selectShopBrands() {
    ko.postbox.publish('showModalMyGroupSelect', { parent_view_model: this });
  }

  selectShopCompanies() {
    ko.postbox.publish('showModalMyGroupSelectCompanies', {
      parent_view_model: this
    });
  }

  clearTargets() {
    if (confirm('本当に全て削除してよろしいですか？')) {
      this.list.removeAll();
    }
  }

  removeRow(item) {
    item.selected = false;
    this.list.remove(item);
  }

  snakeToPascal(snake) {
    return _(snake).classify();
  }
}

ko.components.register('attack_list-shop_brands-form', {
  viewModel(params) {
    return new AttackListShopBrandsViewModel(
      params.max_candidate_count,
      params.shop_brand_name,
      params.candidate_targets
    );
  },
  template: { element: 'ko-normal-template' }
});
