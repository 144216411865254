export class BaseMyGroupButtonViewModel {
  constructor(max_my_group_size, my_groups = []) {
    this.addMyGroup = this.addMyGroup.bind(this);
    this.max_my_group_size = max_my_group_size;
    this.my_groups = my_groups;
    this.loading_my_groups = false;
    this.only_my_groups = ko.computed(() =>
      this.my_groups.filter((my_group) => my_group.mine)
    );
  }

  showFormNewMyGroup() {
    throw new Error('Please override this method!');
  }

  addMyGroup() {
    throw new Error('Please override this method!');
  }

  reloadMyGroups(func = null) {
    const url = (() => {
      switch (this.model.type) {
        case 'sc':
          return Routes.scs_my_groups_path({ format: 'json' });
        case 'pm':
          return Routes.pms_my_groups_path({ format: 'json' });
        case 'shop_brand':
          return Routes.shop_brands_my_groups_path({ format: 'json' });
        case 'shop_company':
          return Routes.shop_companies_my_groups_path({ format: 'json' });
        case 'advertise':
          return Routes.advertises_my_groups_path({ format: 'json' });
      }
    })();

    this.loading_my_groups = true;
    $.getJSON(url, (data) => {
      this.my_groups = data;
      this.loading_my_groups = false;
      if (func != null) {
        return func.call();
      }
    });
  }
}
