import { LoadingModal } from "../../../shared/knockout/components/loading_modal";

// くらべるのモデル選択するところのViewModelの基底クラス
export class BaseForCompare {
  constructor(max_count) {
    this.removeRow = this.removeRow.bind(this);
    this.max_count = max_count;
    this.list = [];
    this.prepared = false;
  }

  initialize() {
    ko.track(this);

    $.when($.ready).then(() => this.setSelectedModelOnReady());
    ko.getObservable(this, 'list').subscribe(() => {
      this.prepared = false;
    });
  }

  selectModels() {
    ko.postbox.publish('showModalMyGroupSelect', { parent_view_model: this });
  }

  removeRow(item) {
    item.selected = false;
    this.list.remove(item);
  }

  getEncodedQuery() {
    if (this.list.length >= 2) {
      const params = new URLSearchParams();
      this.list.forEach(function(it) {
        params.append('model_id[]', it.model_id);
        params.append('model_type[]', it.model_type);
      });
      return params.toString();
    } else {
      return null;
    }
  }

  showCompare(show_modal) {
    if (show_modal == null) { show_modal = true; }
    const self = this;
    if (this.getEncodedQuery() != null) {
      this.prepared = false;
      if (show_modal) {
        LoadingModal.showAnalyzing();
      }
      setTimeout( function() {
        self.compare();
        ko.postbox.publish('updateCompareHistoryState', { pathname: location.pathname, query: self.getEncodedQuery() });
      }
      , 400);
    }
  }

  setSelectedModelOnReady(show_modal){
    if (show_modal == null) { show_modal = true; }
    throw new Error('実装してください');
  }

  compare() {
    throw new Error('実装してください');
  }

  showModalCompareModelAndSendCurrentComponent() {
    ko.postbox.publish('showModalCompareModelAndSendCurrentComponent', { parent_view_model: this });
  }
};
