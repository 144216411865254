class CompareTabMenus {
  constructor() {
    const self = this;
    this.query = null;
    ko.track(this);
    ko.postbox.subscribe('updateCompareHistoryState', function(params) {
      self.query = params.query;
      switch (params.pathname) {
        case Routes.compare_open_sc_list_path():
          history.replaceState('compareState', null, self.linkToCompareOpenScList());
          break;
        case Routes.compare_brands_path():
          history.replaceState('compareState', null, self.linkToCompareShop());
          break;
        case Routes.compare_path():
          history.replaceState('compareState', null, self.linkToCompareSc());
          break;
        default:
          null;
      }
    });
  }

  getEncodedQuery() { return this.query; }

  linkToCompareSc() {
    let encodedQuery;
    if (location.pathname !== Routes.compare_open_sc_list_path()) { encodedQuery = this.getEncodedQuery(); }
    if (encodedQuery != null) {
      return Routes.compare_path() + '?' + encodedQuery;
    } else {
      return Routes.compare_path();
    }
  }

  linkToCompareShop() {
    let encodedQuery;
    if (location.pathname !== Routes.compare_open_sc_list_path()) { encodedQuery = this.getEncodedQuery(); }
    if (encodedQuery != null) {
      return Routes.compare_brands_path() + '?' + encodedQuery;
    } else {
      return Routes.compare_brands_path();
    }
  }

  linkToCompareOpenScList() {
    let encodedQuery;
    if (location.pathname === Routes.compare_open_sc_list_path()) { encodedQuery = this.getEncodedQuery(); }
    if (encodedQuery != null) {
      return Routes.compare_open_sc_list_path() + '?' + encodedQuery;
    } else {
      return Routes.compare_open_sc_list_path();
    }
  }
}


ko.components.register('compare-tab-menus', {
  viewModel() { return new CompareTabMenus(); },
  template: { element: 'ko-normal-template' },
});