ko.bindingHandlers.element = {
  init(element, valueAccessor, allBindings) {
    // バインディングしたViewModelの要素自身をViewModelに持たせる
    const propWriters = allBindings()['_ko_property_writers'];
    const value = valueAccessor();
    if (ko.isObservable(value)) {
      return value(element);
    } else {
      return propWriters.element(element);
    }
  }
};
ko.expressionRewriting._twoWayBindings['element'] = true;