class NavbarDeleteMyGroupsButtonViewModel {
  constructor() {
    this.count = 0;
    this.my_group_ids = [];
    this.submitting = false;
    ko.track(this);

    const self = this;

    ko.defineProperty(this, 'is_enable', () => (self.submitting === false) && (self.count > 0));

    ko.defineProperty(this, 'text_submit_btn', function() {
      if (self.submitting === false) {
        return '削除';
      } else {
        return '送信中';
      }
    });

    $("input[name='model_id[]']").on('change', () => self.count = $("input[name='model_id[]']:checked").length);
  }

  multiDestroy() {
    if (confirm('本当に削除してもよろしいですか？')) {
      this.submitting = true;
      this.my_group_ids = _.map($("input[name='model_id[]']:checked"), element => $(element).val());
      return true;
    }
  }
}

ko.components.register('navbar-delete-my_groups-button', {
  viewModel(params) {
    return new NavbarDeleteMyGroupsButtonViewModel();
  },
  template: { element: 'ko-normal-template' }
});