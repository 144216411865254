import { LoadingModal } from "../shared/knockout/components/loading_modal";

$(function() {
    if ($("#login_count_files_form").is("*")) {
        $('#start_date, #end_date').datepicker({
            format: 'yyyy/mm',
            language: 'ja',
            autoclose: true,
            minViewMode: 'months'
        });

        app_view_model.addViewModels({loginCountFilesViewModel: new loginCountFilesViewModel()});
    }
});

class loginCountFilesViewModel {
    constructor() {
        this.start_date = '';
        this.end_date = '';
        const format = /^\d{4}\/(0[1-9]|1[0-2])$/;
        this.enable_submit = function() {
            return !format.test(this.start_date) || !format.test(this.end_date);
        };

        ko.track(this);
    }

    build() {
      const self = this;
      let timer_id = null;
      $.post(
        Routes.mypage_login_count_files_path({start_date: this.start_date, end_date: this.end_date})
      ).done( function(response, textStatus, jqXHR) {
        timer_id = setInterval( () => self.confirm(response.key, function() {
          clearInterval(timer_id);
          self.download(response.key);
        })
        , 1500);
        LoadingModal.showCreating({
          hide_callback: () => { clearInterval(timer_id); } // キャンセルした場合は定期チェックを止める
        });
      }).fail( function(jqXHR, textStatus, errorThrown) {
          if (timer_id != null) {
            clearInterval(timer_id);
          }
          const response = jqXHR.responseJSON;
          Notify.danger(response.error_message);
      })
    }

    confirm(key, callback) {
      $.get(
        Routes.mypage_download_login_count_file_path({key, format: 'json'})
      ).done(function(response, textStatus, jqXHR) {
        if (response.result === 'completed') {
          if (callback != null) { callback(); }
          LoadingModal.hide();
        }
      }).fail(function(jqXHR, textStatus, errorThrown) {
        alert("エラーが発生しました");
        LoadingModal.hide();
      });
    }

    download(key) {
      location.href = Routes.mypage_download_login_count_file_path(key, {format: 'xlsx'});
    }
}
