import { BaseForCompare } from '../../_view_models/base_for_compare';
import { SelectShopBrand } from '../../../select_model';
import { LoadingModal } from '../../../../shared/knockout/components/loading_modal';

class SelectShopBrandsForCompare extends BaseForCompare {
  constructor(max_count, max_count_compare) {
    super(max_count);
    this.max_count_compare = max_count_compare;
    this.compared = false;
    this.compare_condition_key = null;
    this.initialize();
  }

  url(options) {
    if (options != null) {
      return Routes.compare_open_sc_list_path(options);
    } else {
      return Routes.compare_open_sc_list_path();
    }
  }

  setSelectedModelOnReady() {
    if (location.search.length > 0) {
      LoadingModal.showWaiting();

      $.getJSON(
        this.url({ format: 'json' }) + location.search,
        (selected_models) => {
          this.list = selected_models.map(
            (selected_model) =>
              new SelectShopBrand(
                selected_model.model_id,
                selected_model.model_name,
                selected_model.model_type,
                selected_model.type_industry1,
                selected_model.type_industry2,
                selected_model.type_industry3
              )
          );
          if (this.getEncodedQuery()) {
            this.showCompare(false);
          } else {
            LoadingModal.hide();
          }
        }
      );
    }
  }

  compare() {
    if (this.getEncodedQuery() != null) {
      this.compared = false;
      this.prepared = false;
      $.getJSON(
        Routes.compare_open_sc_list_compare_path({ format: 'json' }),
        this.getEncodedQuery(),
        (data) => {
          this.compared = data.shop_brands.length > 0;
          ko.postbox.publish('setCompareOpenScList', data);
          LoadingModal.hide();
        }
      );
    }
  }

  download() {
    return (location.href = Routes.compare_download_open_sc_list_path({
      key: this.compare_condition_key
    }));
  }

  build() {
    // compare_condition_key を作成する
    const params = { model_id: [], model_type: [] };
    this.list.forEach((x) => {
      params.model_id.push(x.model_id);
      params.model_type.push(x.model_type);
    });
    $.post(
      Routes.compare_create_compare_condition_for_build_path({
        format: 'json'
      }),
      params
    ).done((data, _textStatus, _jqXHR) => {
      this.compare_condition_key = data.compare_condition_key;
      execBuild();
    });

    // Excel のビルド処理を行う
    const execBuild = () => {
      const buildExcelData = (callback) =>
        $.get(
          Routes.compare_build_open_sc_list_path({
            compare_condition_key: this.compare_condition_key,
            format: 'json'
          }),
          (data) => {
            if (data.result === 'completed') {
              if (callback != null) {
                callback();
              }
              LoadingModal.hide();
              this.prepared = true;
            }
          }
        );
      buildExcelData();
      const timer_id = setInterval(
        () =>
          // データができたかどうか定期的にチェックする
          buildExcelData(() => clearInterval(timer_id)),
        1500
      );
      LoadingModal.showCreating({
        hide_callback: () => { clearInterval(timer_id); } // キャンセルした場合は定期チェックを止める
      });
    };
  }
}

ko.components.register('select-shop_brands-for-compare', {
  viewModel(params) {
    return new SelectShopBrandsForCompare(
      params.max_count,
      params.max_count_compare
    );
  },
  template: { element: 'ko-normal-template' },
  synchronous: true
});
