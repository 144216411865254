import { SelectModel } from '../../../select_model';

class MyGroupSelectModelForCompare {
  constructor(max_count=null, my_group_type=null) {
    this.toggle = this.toggle.bind(this);
    this.max_count = max_count;
    this.my_group_type = my_group_type;
    this.sc_my_group_id = '';
    this.pm_my_group_id = '';
    this.my_group_id = () => {
      switch (this.my_group_type) {
        case 'sc': return this.sc_my_group_id;
        case 'pm': return this.pm_my_group_id;
      }
    };

    this.parent_view_model = null;
    this.list = [];

    this.list_scs = () => this.list.filter(favorite_model => favorite_model.model_type === 'sc');
    this.list_pms = () => this.list.filter(favorite_model => favorite_model.model_type === 'pm');

    ko.track(this);

    ko.postbox.subscribe('showModalCompareModelAndSendCurrentComponent', (params) => {
      this.parent_view_model = params.parent_view_model;
      ko.postbox.publish('showModalCompareModel');
    });

    ko.postbox.subscribe('initializeMyGroupSelect', (my_group_type) => {
      this.changeMyGroupType(my_group_type);
    });
  }

  toggle(item) {
    item.selectToggle();
    if (item.selected) {
      this.parent_view_model.list.push(item);
    } else {
      this.parent_view_model.list.remove(item);
    }
    if ((this.max_count != null) && (this.parent_view_model.list.length === this.max_count)) {
      ko.postbox.publish('hideModalCompareModel');
    }
  }

  changeMyGroup() {
    if (this.my_group_id() !== '') {
      const self = this;
      self.list.removeAll();
      return $.getJSON(Routes.list_my_group_path(self.my_group_id(), {format: 'json'}), function(group_items) {
        const temp_array = [];
        group_items.forEach(function(item) {
          const model = new SelectModel(item.model_id, item.model_name, item.model_type);
          // すでに選択しているSCを区別する
          const filtered_models = self.parent_view_model.list.filter(selected_model => (selected_model.model_type === model.model_type) && (selected_model.model_id === model.model_id));
          if (filtered_models.length > 0) {
            filtered_models.forEach(function(selected_model) {
              selected_model.selected = true;
              temp_array.push(selected_model);
            });
          } else {
            temp_array.push(model);
          }
        });
        self.list = temp_array;
      });
    }
  }

  changeMyGroupType(group_type) {
    // マイグループのselectを初期値に戻してグループ選択を再度促す
    this.sc_my_group_id = '';
    this.pm_my_group_id = '';
    this.my_group_type = group_type;
  }

  // 現在のマイグループのSCを全選択する
  addAllScs() {
    this._addItems(this.list_scs());
  }

  // 現在のマイグループのSCを全解除する
  removeAllScs() {
    this._removeItems(this.list_scs());
  }

  // 現在のマイグループのPMを全選択する
  addAllPms() {
    this._addItems(this.list_pms());
  }

  // 現在のマイグループのPMを全解除する
  removeAllPms() {
    this._removeItems(this.list_pms());
  }

  _addItems(items) {
    const self = this;
    items.forEach(function(item) {
      item.selected = true;
      if (self.parent_view_model.list.indexOf(item) === -1) { self.parent_view_model.list.push(item); }
    });
  }

  _removeItems(items) {
    const self = this;
    items.forEach(function(item) {
      item.selected = false;
      if (self.parent_view_model.list.indexOf(item) !== -1) { self.parent_view_model.list.remove(item); }
    });
  }
}

ko.components.register('my_groups-select-scs-for-compare', {
  viewModel(params) {
    return new MyGroupSelectModelForCompare(params.max_candidate_count, params.my_group_type);
  },
  template: {element: 'ko-normal-template'}
});

ko.components.register('my_groups-select-pms-for-compare', {
  viewModel(params) {
    return new MyGroupSelectModelForCompare(params.max_candidate_count, params.my_group_type);
  },
  template: { element: 'ko-normal-template' }
});
