import { C3CompareShopBrandKindsChart } from "../../../charts/c3_compare_shop_brand_kinds_chart";
import { BaseForCompare } from "../../_view_models/base_for_compare";
import { C3CompareChart } from '../../../charts/c3_compare_chart';
import { SelectModel } from '../../../select_model';
import { LoadingModal } from "../../../../shared/knockout/components/loading_modal";

class SelectScsForCompare extends BaseForCompare {
  constructor(max_count) {
    super(max_count);
    this.initialize();
  }

  setSelectedModelOnReady() {
    if (location.search.length > 0) {
      const self = this;
      LoadingModal.showWaiting();
      const url = Routes.compare_path({format: 'json'});

      $.getJSON(url + location.search, function(selected_models) {
        self.list = selected_models.map(selected_model => new SelectModel(selected_model.model_id,
          selected_model.model_name,
          selected_model.model_type));
        if (self.getEncodedQuery()) {
          self.showCompare(false);
        } else {
          LoadingModal.hide();
        }
      });
    }
  }

  compare() {
    if (this.getEncodedQuery() != null) {
      $.getJSON(Routes.compare_scs_by_graph_path({format: 'json'}), this.getEncodedQuery(), function(data) {
        $('.result-before').removeClass('result-before');
        const chart_types = {
          //annual_sales: '売上(円)',
          //annual_visitors: '来客数(人)',
          store_space: '店舗面積(平方メートル)',
          possible_parking: '駐車場台数(台)',
          //shop_count: 'ショップ数(件)'
        };
        $.each(chart_types, (column_name, y_title) => new C3CompareChart(column_name, y_title, data).show());
        new C3CompareShopBrandKindsChart('#compare_shop_brand_kinds_chart', data, false).show();
        new C3CompareShopBrandKindsChart('#compare_shop_brand_kinds_percentage_chart', data, true).show();
        LoadingModal.hide();
      });
    }
  }
}


ko.components.register('select-scs-for-compare', {
  viewModel(params) { return new SelectScsForCompare(params.max_count); },
  template: { element: 'ko-normal-template' },
  synchronous: true
});