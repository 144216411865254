import * as d3 from 'd3';
import { getPageTitle } from '../utilities'

export class D3OccupancyMap {
  static CATEGORIES = ['0店', '1〜4店', '5〜9店', '10〜19店', '20〜29店', '30〜39店', '40〜49店', '50店以上'];
  static COLORS = d3.scaleLinear().domain([0, D3OccupancyMap.CATEGORIES.length / 2, D3OccupancyMap.CATEGORIES.length]).range(['white', '#90ED7D', '#015401']);

  constructor(element_id, data, width=578, height=500) {
    this.data = data;
    this.width = width;
    this.height = height;
    this.svg = d3.select(element_id).append('svg')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('preserveAspectRatio', 'xMidYMid')
      .attr('width', '100%')
      .attr('height', '100%');
    this.drawDropdownButton(element_id);
  }

  // 凡例を描く
  drawLegends() {
    const legends = this.svg.selectAll('.legends')
      .data(D3OccupancyMap.CATEGORIES)
      .enter()
      .append('g')
      .attr('class', 'legends')
      .attr('transform', (d, i) => `translate(15, ${15 + (i * 20)})`);

    legends.append('rect')
      .attr('x', 0)
      .attr('y', 45)
      .attr('width', 10)
      .attr('height', 10)
      .style('fill', (d, i) => D3OccupancyMap.COLORS(i));
    legends.append('text')
      .attr('x', 20)
      .attr('y', 55)
      .text(d => d)
      .attr('class', 'textselected')
      .style('text-anchor', 'start')
      .style('font-size', 14);
  }

  // タイトルを描く
  drawTitle() {
    this.svg.append('text')
      .html(this._getLineBreakText([getPageTitle(), 'のショップ・ブランドの都道府県別出店数']))
      .attr('x', this.width / 2)
      .attr('y', 20)
      .attr('class', 'd3-title')
      .style('text-anchor', 'middle')
      .style('font-size', 18);
  }

  drawDropdownButton(element_id) {
    const self = this;
    const map = document.querySelector(`${element_id} svg`);
    const contextMenu = d3.select(element_id).append('div')
      .style('position', 'absolute')
      .style('z-index', 1000)
      .style('padding', '24px')
      .style('visibility', 'hidden')
      .style('right', '-24px')
      .style('top', '10px');
    const menuBox = contextMenu.append('div')
      .style('box-shadow', 'rgb(136, 136, 136) 3px 3px 10px')
      .style('border', '1px solid rgb(160, 160, 160)')
      .style('background', 'white')
      .style('padding', '5px 0');
    menuBox.append('div')
      .style('font-size', 11)
      .style('color', 'rgb(48,48,48)')
      .style('background', 'none')
      .style('padding', '0 10px')
      .html('チャートを印刷')
      .on('mouseover', function(d) {
        d3.select(this)
          .style('background', 'rgb(69, 114, 165)')
          .style('color', 'white');
      }).on('mouseout', function(d) {
      d3.select(this)
        .style('background', 'none')
        .style('color', 'rgb(48,48,48)');
      contextMenu.style('visibility', 'hidden');
      dropdownButton.select('rect').style('stroke', 'none');
    }).on('click', function(d) {
      dropdownButton.style('visibility', 'hidden');
      const printWindow = window.open('', 'PrintMap', 'width=800, height=800');
      printWindow.document.writeln(new XMLSerializer().serializeToString(map));
      printWindow.document.close();
      printWindow.print();
      // afterprintイベントを使うとIE11で動かなかったので、
      // 苦肉の策でsetTimeoutを使用している…
      // @see https://stackoverflow.com/questions/18325025/how-to-detect-window-print-finish/24325151#24325151
      setTimeout(function() {
          dropdownButton.style('visibility', 'visible');
          printWindow.close();
        }
        , 1000);
    });

    let dropdownButton = this.svg.append('g')
      .attr('transform', `translate(${this.width - 25}, 0)`)
      .attr('stroke-linecap', 'round')
      .attr('class', 'd3-button')
      .on('mouseover', function(d) {
        d3.select(this).select('rect').style('stroke', '#68A');
      }).on('mouseout', function(d) {
        if (contextMenu.style('visibility') === 'hidden') {
          d3.select(this).select('rect').style('stroke', 'none');
        }
      }).on('click', d => contextMenu.style('visibility', 'visible'));

    dropdownButton.append('rect')
      .attr('width', 25)
      .attr('height', 22)
      .style('fill', 'white')
      .style('stroke', 'none')
      .style('stroke-width', 1)
      .style('rx', 2)
      .style('ry', 2);
    dropdownButton.append('path')
      .style('fill', '#E0E0E0')
      .style('stroke', '#666')
      .style('stroke-width', 3)
      .attr('d', 'M 6 6.5 L 20 6.5 M 6 11.5 L 20 11.5 M 6 16.5 L 20 16.5');
  }

  drawMap(geojson, click_event) {
    const self = this;
    const projection = d3.geoMercator();
    projection.fitSize([this.width, this.height - 45], geojson);
    const path = d3.geoPath().projection(projection);

    const tooltip = d3.select("body").append("div").attr("class", "d3-tooltip");

    this.svg.selectAll(".pref")
      .data(geojson.features)
      .enter()
      .append('path')
      .attr('transform', 'translate(0, 45)')
      .attr('d', path)
      .style('stroke', '#aaa')
      .style('fill', d => self.getFillColor(self.getDatum(d)))
      .on('mouseover', function(d) {
        if (d.properties["local-name"] == null) { return; }

        d3.select(this).style('fill', 'pink');
        const datum = self.getDatum(d);
        tooltip
          .style("visibility", "visible")
          .html(`${datum.key}: ${datum.value}`);
      }).on('mouseout', function(d) {
        if (d.properties["local-name"] == null) { return; }

        d3.select(this).style('fill', self.getFillColor(self.getDatum(d)));
        tooltip.style("visibility", "hidden");
      }).on('mousemove', d => tooltip
        .style("top", (d3.event.pageY - 20) + "px")
        .style("left", (d3.event.pageX + 10) + "px")).on('click', d => click_event(d.properties["local-name"]));
  }

  getFillColor(datum) {
    if (datum == null) { return 'rgba(0,0,0,0)'; }

    switch (false) {
      case datum.value !== 0: return D3OccupancyMap.COLORS(0);
      case !(datum.value < 5): return D3OccupancyMap.COLORS(1);
      case !(datum.value < 10): return D3OccupancyMap.COLORS(2);
      case !(datum.value < 20): return D3OccupancyMap.COLORS(3);
      case !(datum.value < 30): return D3OccupancyMap.COLORS(4);
      case !(datum.value < 40): return D3OccupancyMap.COLORS(5);
      case !(datum.value < 50): return D3OccupancyMap.COLORS(6);
      default: return D3OccupancyMap.COLORS(7);
    }
  }

  getDatum(d) {
    return _.find(this.data, datum => datum.key === d.properties["local-name"]);
  }

  _getLineBreakText(array) {
    return array.map((text, i) => `<tspan y='${1 + (i * 1.2)}em' x='${this.width / 2}'>${text}</tspan>`).join('');
  }

  static show(element_id, data, click_event = null) {
    const instance = new D3OccupancyMap(element_id, data);
    instance.drawTitle();
    instance.drawLegends();
    const geojson = require('../../jp-all.geo.json');
    instance.drawMap(geojson, click_event);
  }
}
