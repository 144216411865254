export class AppViewModel {
  addViewModel(view_model_name, view_model) {
    Object.defineProperty(this, view_model_name, {
      value: view_model,
      writable: false,
      enumerable: true
    });
  }

  addViewModels(hash) {
    const self = this;
    $.each(hash, (key, value) => self.addViewModel(key, value));
  }

  addObservable(obserbable_name) {
    Object.defineProperty(this, obserbable_name, {
      value: ko.observable(''),
      writable: false,
      enumerable: true
    });
  }
};
