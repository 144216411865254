import { BaseMyGroupButtonViewModel } from '../_view_models/base_my_group_button_view_model';

class MyGroupButtonViewModel extends BaseMyGroupButtonViewModel {
  constructor(model_id, model_type, max_my_group_size, my_groups = []) {
    super(max_my_group_size, my_groups);
    this.addMyGroup = this.addMyGroup.bind(this);
    this.model = { id: model_id, type: model_type };
    ko.track(this);

    ko.defineProperty(this, 'text_add_button', function () {
      switch (this.model.type) {
        case 'sc':
          return 'このSCをマイグループに追加';
        case 'shop_brand':
          return 'このショップをマイグループに追加';
        case 'pm':
          return 'このPMをマイグループに追加';
        case 'shop_company':
          return 'このショップ運営企業をマイグループに追加';
        case 'advertise':
          return 'このショップをマイグループに追加';
        default:
          return '';
      }
    });

    const self = this;
    ko.postbox.subscribe('addMyGroupFromMyGroupButton', (my_group) =>
      self.addMyGroup(my_group)
    );

    ko.postbox.subscribe('reloadMyGroupsFromMyGroupButton', () =>
      self.reloadMyGroups(() =>
        ko.postbox.publish('syncToNavbarMyGroupButton', {
          model_type: self.model.type,
          my_groups: self.my_groups,
        })
      )
    );

    ko.postbox.subscribe('syncMyGroupButton', function (params) {
      if (self.model.type === params.model_type) {
        return (self.my_groups = params.my_groups);
      }
    });
  }

  showFormNewMyGroup() {
    ko.postbox.publish('showFormNewMyGroup', {
      class_name: 'MyGroupButtonViewModel',
      model_type: _.classify(this.model.type),
    });
  }

  addMyGroup(my_group) {
    const self = this;
    $.post(
      Routes.add_my_group_path(my_group.id),
      { model_id: this.model.id, model_type: this.model.type },
      function (data, status, xhr) {
        Notify.success(
          `グループ <strong>${_.escapeHTML(
            my_group.name
          )}</strong>に追加しました`
        );
      }
    ).fail(function (xhr, status, statusText) {
      switch (xhr.status) {
        case 403: // マイグループ内の登録数を超えている
          Notify.warning(
            'グループ登録数の上限を超えるため、登録できませんでした'
          );
          break;
        case 409: // 登録済
          Notify.warning(
            `グループ <strong>${_.escapeHTML(
              my_group.name
            )}</strong>に既に追加済です`
          );
          break;
        case 404: // 存在しない
          Notify.danger('指定されたデータは存在しません');
          break;
        default:
          Notify.error();
      }
    });
  }
}

ko.components.register('my_group-button', {
  viewModel(params) {
    return new MyGroupButtonViewModel(
      params.model_id,
      params.model_type,
      params.max_my_group_size,
      params.my_groups
    );
  },
  template: { element: 'ko-normal-template' },
});
