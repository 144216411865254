import * as ko from 'knockout-es5';
import { ScType } from 'src/domains/sc_type';

export class SearchByScTypeForSc {
  private readonly sc_types: ScType[];
  public selected_sc_type_ids: number[];

  /**
   * コンストラクタ
   * @param {Array<ScType>} sc_types
   * @param {Array<number>} selected_sc_type_ids=[]
   */
  constructor(sc_types: ScType[], selected_sc_type_ids: number[] = []) {
    this.sc_types = sc_types;
    this.selected_sc_type_ids = selected_sc_type_ids;
    ko.track(this);
  }

  /**
   * SCタイプを全選択する
   */
  selectAll() {
    this.selected_sc_type_ids = this.sc_types.map((sc_type) => sc_type.id);
  }

  /**
   * SCタイプの選択を全解除する
   */
  unselectAll() {
    this.selected_sc_type_ids = [];
  }
}

ko.components.register('search_by_sc_types-horizontal', {
  viewModel: (params) => {
    if (params.viewModel) {
      return params.viewModel;
    } else {
      return new SearchByScTypeForSc(
        params.sc_types,
        params.selected_sc_type_ids
      );
    }
  },
  template: { element: 'part-of-search_by_sc_types-horizontal' },
  synchronous: true,
});
