$(document).on('ajax:success', '#sc_search', function(xhr, data, status) {
  if (xhr.target.tagName === 'FORM') {
    location.href = Routes.result_scs_path({key: data.key});
  }
});

$(document).on('ajax:success', '#shop_brand_search', function(xhr, data, status) {
  if (xhr.target.tagName === 'FORM') {
    location.href = Routes.result_shop_brands_path({key: data.key});
  }
});

$(document).on('ajax:success', '#pm_search', function(xhr, data, status) {
  if (xhr.target.tagName === 'FORM') {
    location.href = Routes.result_pms_path({key: data.key});
  }
});

$(document).on('ajax:success', '#shop_company_search', function(xhr, data, status) {
  if (xhr.target.tagName === 'FORM') {
    location.href = Routes.result_shop_companies_path({key: data.key});
  }
});

$(function(){
  $('#advertise_search').on('ajax:success', function(xhr, data, status) {
    if (xhr.target.tagName === 'FORM') {
      location.href = Routes.result_advertises_path({key: data.key});
    }
  });
})
