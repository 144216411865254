export class AdvertisePhotoViewModel {
  constructor(attached_file_id, url) {
    Object.defineProperty(this, 'MAX_FILE_SIZE', { value: 2 * 1024 * 1024 });
    this.attached_file_id = attached_file_id;
    this.url = url;
    this.showLadda = false;
    ko.track(this);
  }

  openDialog(data, event, index) {
    $(`#fileupload_${index}`).click();
  }

  clear() {
    this.attached_file_id = null;
    this.url = null;
  }

  changed(file) {
    if (file == null) {
      return;
    }
    if (file.size > this.MAX_FILE_SIZE) {
      alert('ショップ写真は2MB以内のファイルを指定してください');
      return;
    }

    this.showLadda = true;
    const fd = new FormData();
    fd.append('upload_file', file);

    const postData = {
      type: 'POST',
      dataType: 'json',
      data: fd,
      processData: false,
      contentType: false,
    };

    $.ajax(Routes.attached_files_path(), postData).done((data) => {
      if (data.result) {
        this.attached_file_id = data.id;
        this.url = data.url.middle;
        ko.postbox.publish('matchPhotosHeight');
      } else {
        alert(data.message);
      }
      this.showLadda = false;
    });
  }
}
