// 各モデルの検索フォームの共通処理を含めたViewModel
export class BaseSearchForm {
  static DEFAULT_RATE_LIMIT = { timeout: 400, method: 'notifyWhenChangesStop' };

  constructor(search_conditions = {}) {
    this.searching = false;
    this.submitting = false;
    this.search_count = null;
    this.form_element = null;
    this.keywords = search_conditions['keywords'] || '';
    this.keywords_not = search_conditions['keywords_not'] || '';
  }

  initialize() {
    ko.track(this);
    ko.getObservable(this, 'searching').syncWith('sync_searching');
    ko.getObservable(this, 'submitting').syncWith('sync_submitting');
    this.jqXHR = null;
    this.setDelayEvaluate(this, 'keywords');
    this.setDelayEvaluate(this, 'keywords_not');

    // 検索件数を取得して、フッターに表示する
    ko.computed(this.requestSearchCount, this).extend({ rateLimit: 0 });

    let self = this;
    ko.postbox.subscribe('submitSearchForm', () => self.submitSearchForm());

    self = this;
    ko.defineProperty(
      this,
      'is_enable',
      () =>
        self.searching === false &&
        self.submitting === false &&
        (self.search_count === null || self.search_count > 0)
    );
    ko.defineProperty(this, 'text_submit_btn', function () {
      if (self.searching === false && self.submitting === false) {
        return '検索';
      } else if (self.searching === true) {
        return '件数取得中';
      } else {
        return '送信中';
      }
    });
  }

  /** プロパティの変更の検知を遅らせる
   * @param scope スコープ。基本的にはthisを渡すか、ViewModelを渡す
   * @param property_name スコープ変数が持つプロパティ名の文字列
   * @param rate_limit 遅延評価設定
   */
  setDelayEvaluate(
    scope,
    property_name,
    rate_limit = BaseSearchForm.DEFAULT_RATE_LIMIT
  ) {
    ko.getObservable(scope, property_name).extend({ rateLimit: rate_limit });
  }

  // 検索件数を取得して、フッターに表示する
  requestSearchCount() {
    if (this.jqXHR != null) {
      this.jqXHR.abort();
    } // 以前の通信の結果の受け取りを拒否する
    if (this.canRequest()) {
      if (this.form_element != null) {
        this.searching = true;
        ko.postbox.publish('showSearchCountNavigation');
        const params = $(this.form_element).serialize();
        this.jqXHR = $.post($(this.form_element).attr('action'), params)
          .done((data) => {
            $.get(this.getSearchCountUrl({ key: data.key, format: 'json' }))
              .done((data) => {
                this.search_count = data.count;
                ko.postbox.publish('updateSearchCountNavigation', data);
              })
              .fail((jqXHR, _textStatus, _errorThrown) => {
                this.search_count = jqXHR.responseJSON.count;
                ko.postbox.publish('invalidSearchConditions');
              })
              .always(() => (this.searching = false));
          })
          .fail((jqXHR, textStatus, errorThrown) => {
            if (textStatus !== 'abort') {
              alert('エラーが発生しました。');
            }
            this.searching = false;
          });
      }
    } else {
      this.search_count = null;
      ko.postbox.publish('hideSearchCountNavigation');
    }
  }

  // 検索ヒット数を取得する通信を呼び出していいか判定する
  // 検索パラメータがあればtrue、なければfalseを返すように実装してください
  // @abstract
  // @return [Boolean]
  canRequest() {
    throw new Error('Please override this method!');
  }

  // 検索用のURLを取得する
  // 各コントローラーの検索結果の件数取得するURLを取得すること
  // @abstract
  // @example
  //   getSearchCountUrl: (params) ->
  //     Routes.count_pms_path(key: params.key, format: params.json)
  // @param [Hash] key: SearchConditionのキー, format: 'json'
  // @return [String] URL文字列
  getSearchCountUrl(params) {
    throw new Error('Please override this method!');
  }

  // 検索条件をクリアする
  // @abstract
  clearSearchConditions() {
    throw new Error('Please override this method!');
  }

  submitSearchForm() {
    this.submitting = true;
    if (this.form_element != null) {
      return $(this.form_element).submit();
    }
  }

  openModalIndustriesList() {
    ko.postbox.publish('showModalIndustriesList');
  }
}
