import { BaseSearchForm } from '../../../_view_models/base_search_form';
import { SearchByPref} from '../../../_view_models/search_by_pref';

class SearchFormPms extends BaseSearchForm {
  constructor(regions, search_conditions={}) {
    super(search_conditions);
    this.search_by_pref = new SearchByPref(regions, search_conditions['address_pref_in_any'] || []);
    this.initialize();
  }

  // @override
  canRequest() {
    return _.any([this.keywords,
           this.keywords_not,
           this.search_by_pref.selected_prefs], value => value.length > 0);
  }

  // @override
  getSearchCountUrl(params) {
    return Routes.count_pms_path({key: params.key, format: params.format});
  }

  // @override
  clearSearchConditions() {
    this.keywords = '';
    this.keywords_not = '';
    this.search_by_pref.unselectAll();
  }
}

ko.components.register('search-form-pms', {
  viewModel(params) { return new SearchFormPms(params.regions, params.search_conditions); },
  template: { element: 'ko-normal-template' }
});
