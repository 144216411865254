import { C3AxisImpl } from "./c3_axis_impl";
import { getPageTitle } from "../utilities";
import * as d3 from 'd3';
import { C3ButtonDropdown } from './c3_button_dropdown'

export class C3OccupancyChart extends C3ButtonDropdown {
  constructor(element_id, json_data, click_event=null) {
    super(element_id);
    this.json_data = json_data;
    this.click_event = click_event;
    Object.assign(C3OccupancyChart.prototype, C3AxisImpl);
  }

  show() {
    const self = this;
    const max_count = d3.max(this.getOpenShopCounts())
    const min_count = d3.min(this.getCloseShopCounts()) || 0
    const y_tick_values = self._getAxisYTickValues(max_count, min_count)
    const y_max_value = _.isEmpty(y_tick_values) ? undefined : Math.max(...y_tick_values)
    const y_min_value = _.isEmpty(y_tick_values) ? undefined : Math.min(...y_tick_values)
    this.generate({
      title: {
        text: `${getPageTitle()}のショップ・ブランドの出退店`,
        position: 'top-center'
      },
      size: {
        height: 400
      },
      data: {
        json: this._makeColumns(),
        type: 'bar',
        keys: {
          value: ['open', 'close']
        },
        names: {
          open: '出店',
          close: '退店',
        },
        colors: {
          open: '#59B200',
          close: '#AAAAAA',
        },
        classes: {
          open: 'stacked',
          close: 'stacked-negative',
        },
        labels: true,
        groups: [['open', 'close']],
        order: null,
        onclick: this.click_event
      },
      axis: {
        x: {
          type: 'category',
          categories: this._getCategories(),
          tick: {
            rotate: this._getRotateAngle(),
            multiline: false,
            culling: this._hasCulling(),
          },
        },
        y: {
          label: {
            text: '退店数・出店数',
            position: '-middle',
          },
          tick: {
            format: d3.format('01,d'),
            values: _.isEmpty(y_tick_values) ? undefined : y_tick_values
          },
          max: y_max_value,
          min: y_min_value,
        }
      },
      grid: {
        y: {
          lines: [
            { value: 0 }
          ],
        },
      },
      onrendered() {
        const font_size = self._getFontSize(self.json_data.open_data.length);
        const open_bar_centers = d3.selectAll(`${self.element_id} .c3-chart-bars .c3-target-open .c3-bar`).nodes().map(el => el.getBBox().height/2);
        d3.selectAll(`${self.element_id} .c3-texts-open text.c3-text`).nodes().forEach((el, i) => d3.select(el)
          .style('font-size', font_size)
          .attr('transform', `translate(0, ${open_bar_centers[i]})`));

        const close_bar_centers = d3.selectAll(`${self.element_id} .c3-chart-bars .c3-target-close .c3-bar`).nodes().map(el => el.getBBox().height / 2);

        d3.selectAll(`${self.element_id} .c3-texts-close text.c3-text`).nodes().forEach((el, i) => d3.select(el)
          .style('font-size', font_size).style('line-height', '1rem')
          .attr('transform', `translate(0, -${close_bar_centers[i] + font_size})`));

        return d3.selectAll(`${self.element_id} .c3-texts-close text.c3-text, ${self.element_id} .c3-texts-open text.c3-text`).nodes().forEach(function(el, i) {
          const value = parseInt(el.innerHTML, 10);
          if (value === 0) { return d3.select(el).style('display', 'none'); }
        });
      }
    });
  }

  _makeColumns() {
    const open_shop_counts = this.getOpenShopCounts();
    const close_shop_counts = this.getCloseShopCounts();
    return __range__(0, open_shop_counts.length, false).map(idx => ({
      open: open_shop_counts[idx],
      close: close_shop_counts[idx]
    }));
  }

  _getCategories() { return _.pluck(this.json_data.open_data, 'year_month'); }

  _getRotateAngle() {
    const size =  this._getCategories().length;
    if (size > 15) { return -45; } else { return 0; }
  }

  _hasCulling() {
    const size = this._getCategories().length;
    if (size < 25) { return false; }

    return { max: (size / 3)  + 1 };
  }

  getOpenShopCounts() {
    return this.json_data.open_data.map(open_datum => open_datum.shop_brands.length);
  }

  getCloseShopCounts() {
    return this.json_data.close_data.map(close_datum => 0 - close_datum.shop_brands.length);
  }
}

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}