import { KnockoutModalBase } from "../../../shared/knockout/view_models/knockout_modal_base";

class ModalFormNewMyGroupViewModel extends KnockoutModalBase {
  constructor() {
    super();
    this.initializeForm();
    this.checkParams = function() {
      return !_.isBlank(this.name) && !_.isBlank(this.status) && !_.isBlank(this.model_type);
    };

    this.isLimited = function() { return this.status === 'range_limited'; };

    ko.track(this);
    ko.postbox.subscribe('showFormNewMyGroup', function(params) {
      this.invoker_view_model_name = params.class_name;
      this.model_type = params.model_type;
      this.show();
    }
    , this);
  }

  initializeForm() {
    this.name = null;
    this.model_type = null;
    this.status = null;
    this.description = null;
  }

  submitForm(form_element) {
    const self = this;
    return $.post(Routes.my_groups_path({format: 'json'}), $(form_element).serialize()).done(function(data, status, xhr) {
      if (self.invoker_view_model_name === 'MyGroupButtonViewModel') {
        ko.postbox.publish('addMyGroupFromMyGroupButton', data.my_group);
        ko.postbox.publish('reloadMyGroupsFromMyGroupButton');
      } else {
        ko.postbox.publish('addMyGroupFromNavbarMyGroupButton', data.my_group);
        ko.postbox.publish('reloadMyGroupsFromNavbarMyGroupButton');
      }
      $(form_element).get(0).reset();
      self.initializeForm();
    }).fail(function(xhr, status, statusText) {
      switch (xhr.status) {
        case 403:
          Notify.danger(_.escapeHTML(xhr.responseJSON.errors.messages.max_my_group_size[0]));
          break;
        default:
          $.notify('エラーが発生しました。後ほどお試しいただくか、お問い合わせよりご連絡ください。', {type: 'danger'});
      }
    }).always( () => self.hide());
  }
}

ko.components.register('modal-form-new-my_group', {
  viewModel() { return new ModalFormNewMyGroupViewModel(); },
  template: { element: 'modal-template' }
});

