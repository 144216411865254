// モデルの詳細画面で使うモデルの情報を取得する関数
export function getModelId() {
    return $(document.body).data('model_id');
}

export function getModelType() {
    return $(document.body).data('model_type');
}

// 詳細ページのタイトルを取得する関数
export function getPageTitle() {
    const pageTitle = $('h1.page-title').html().split('<br>')
    return _.chain(pageTitle).first().stripTags().unescape().value();
}

export function numberWithDelimiter(value) {
    const decimal = String(value).split('.')[1];
    const decimal_length = (decimal != null) ? decimal.length : 0;
    return _.numberFormat(value, decimal_length);
};
