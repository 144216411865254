export const C3IndustryColors = {
  colors: [
    '#CDB967', // GMS
    '#AAAAAA', // 百貨店
    '#FF00FF', // ファッション
    '#6FDD00', // ファッション雑貨
    '#59B200', // 生活雑貨
    '#6772E5', // インテリア
    '#02B4F7', // スポーツ・ホビー
    '#824879', // 飲食
    '#B28500', // 食品
    '#FF7F00', // サービス
    '#E70F1F', // アミューズメント
    '#00D5D5' // その他
  ]
};