export class SelectModel {
  constructor(model_id, model_name, model_type) {
    this.model_id = model_id;
    this.model_name = model_name;
    this.model_type = model_type;
    this.selected = false;
    this.selectedStatus = function() {
      if (this.selected) {
        return 'label-info';
      } else {
        return 'label-default';
      }
    };
    ko.track(this);
  }

  selectToggle() {
    this.selected = !this.selected;
  }
}

export class SelectShopBrand extends SelectModel {
  constructor(model_id, model_name, model_type, type_industry1, type_industry2=null, type_industry3=null) {
    super(model_id, model_name, model_type);
    this.type_industry1 = type_industry1;
    this.type_industry2 = type_industry2;
    this.type_industry3 = type_industry3;
    ko.track(this);
  }
}
