import { KnockoutModalBase } from '../../../shared/knockout/view_models/knockout_modal_base';
import { SelectModel } from '../../select_model';

class ModalMyGroupSelectCompaniesViewModel extends KnockoutModalBase {
  constructor(max_count = null, my_group_type = null, modal_title = '') {
    super();
    this.toggle = this.toggle.bind(this);
    this.max_count = max_count;
    this.my_group_type = my_group_type;
    this.modal_title = modal_title;
    this.shop_company_my_group_id = '';
    this.my_group_id = () => this.shop_company_my_group_id;

    this.parent_view_model = null;
    this.list = [];

    this.list_shop_companies = () =>
      this.list.filter((x) => x.model_type === 'shop_company');

    ko.track(this);

    ko.postbox.subscribe('showModalMyGroupSelectCompanies', (params) => {
      this.parent_view_model = params.parent_view_model;
      this.show();
    });
  }

  toggle(item) {
    item.selectToggle();
    if (item.selected) {
      this.parent_view_model.list.push(item);
    } else {
      this.parent_view_model.list.remove(item);
    }
    if (
      this.max_count != null &&
      this.parent_view_model.list.length === this.max_count
    ) {
      this.hide();
    }
  }

  changeMyGroup() {
    if (this.my_group_id() !== '') {
      this.list.removeAll();
      return $.getJSON(
        Routes.list_my_group_path(this.my_group_id(), { format: 'json' }),
        (group_items) => {
          const temp_array = [];
          group_items.forEach((item) => {
            const model = new SelectModel(
              item.model_id,
              item.model_name,
              item.model_type
            );
            const filtered_models = this.parent_view_model.list.filter(
              (selected_model) =>
                selected_model.model_type === model.model_type &&
                selected_model.model_id === model.model_id
            );
            if (filtered_models.length > 0) {
              filtered_models.forEach((selected_model) => {
                selected_model.selected = true;
                temp_array.push(selected_model);
              });
            } else {
              temp_array.push(model);
            }
          });
          this.list = temp_array;
        }
      );
    }
  }

  changeMyGroupType(group_type) {
    // マイグループのselectを初期値に戻してグループ選択を再度促す
    this.shop_company_my_group_id = '';
    this.my_group_type = group_type;
  }

  // 現在のマイグループのショップ運営企業を全選択する
  addAllShopCompanies() {
    this._addItems(this.list_shop_companies());
  }

  // 現在のマイグループのショップ運営企業を全解除する
  removeAllShopCompanies() {
    this._removeItems(this.list_shop_companies());
  }

  _addItems(items) {
    items.forEach((item) => {
      item.selected = true;
      if (this.parent_view_model.list.indexOf(item) === -1) {
        this.parent_view_model.list.push(item);
      }
    });
  }

  _removeItems(items) {
    items.forEach((item) => {
      item.selected = false;
      if (this.parent_view_model.list.indexOf(item) !== -1) {
        this.parent_view_model.list.remove(item);
      }
    });
  }
}

ko.components.register('modal-my_groups-select-shop_companies', {
  viewModel(params) {
    return new ModalMyGroupSelectCompaniesViewModel(
      params.max_candidate_count,
      params.model_type,
      params.modal_title
    );
  },
  template: { element: 'modal-my_groups-select_template' }
});
