import { BaseForCompare } from '../../_view_models/base_for_compare';
import { SelectModel } from '../../../select_model';
import { LoadingModal } from '../../../../shared/knockout/components/loading_modal';

class SelectScsPmsForCompare extends BaseForCompare {
  constructor(max_count, max_count_compare) {
    super(max_count);
    this.max_count_compare = max_count_compare;
    this.compared = false;
    this.compare_condition_key = null;
    // Ajaxで取得したHTMLをKnockout.jsで再解釈させるためのobservableを作成
    app_view_model.addObservable('compare_result_html');
    this.initialize();
  }

  setSelectedModelOnReady() {
    if (location.search.length > 0) {
      LoadingModal.showWaiting();
      const url = Routes.compare_brands_path({ format: 'json' });

      $.getJSON(url + location.search, (selected_models) => {
        this.list = selected_models.map(
          (selected_model) =>
            new SelectModel(
              selected_model.model_id,
              selected_model.model_name,
              selected_model.model_type
            )
        );
        if (this.getEncodedQuery()) {
          return this.showCompare(false);
        } else {
          return LoadingModal.hide();
        }
      });
    }
  }

  compare() {
    if (this.getEncodedQuery()) {
      this.compared = false;
      this.prepared = false;
      $.get(
        Routes.compare_brands_compare_path({ format: 'html' }),
        this.getEncodedQuery(),
        (data) => {
          app_view_model.compare_result_html(data);
          this.compared = true;
          const type_industry1 = $('#brands_result').data('type_industry1');
          if (type_industry1 != null) {
            ko.postbox.publish('selectByIndustry', type_industry1);
            $('#brands_result').data('type_industry1', null); // 大業種は初回のみ。後は影響してほしくないので削除
          } else {
            ko.postbox.publish('showFilterIndustry');
          }
          LoadingModal.hide();
          $('html,body').animate(
            { scrollTop: $('#filter-industry').offset().top - 46 },
            { queue: false }
          );
        }
      );
    }
  }
  download() {
    location.href = Routes.compare_download_brands_compare_path({
      key: this.compare_condition_key
    });
  }

  build() {
    // compare_condition_key を作成する
    const params = { model_id: [], model_type: [] };
    this.list.forEach((x) => {
      params.model_id.push(x.model_id);
      params.model_type.push(x.model_type);
    });
    $.post(
      Routes.compare_create_compare_condition_for_build_path({
        format: 'json'
      }),
      params
    ).done((data, _textStatus, _jqXHR) => {
      this.compare_condition_key = data.compare_condition_key;
      execBuild();
    });

    // Excel のビルド処理を行う
    const execBuild = () => {
      const buildExcelData = (callback) =>
        $.getJSON(
          Routes.compare_build_brands_compare_path({
            compare_condition_key: this.compare_condition_key,
            format: 'json'
          }),
          (data) => {
            if (data.result === 'completed') {
              if (callback != null) {
                callback();
              }
              LoadingModal.hide();
              this.prepared = true;
            }
          }
        );

      buildExcelData();
      const timer_id = setInterval(
        () =>
          // データができたかどうか定期的にチェックする
          buildExcelData(() => clearInterval(timer_id)),
        1500
      );
      LoadingModal.showCreating({
        hide_callback: () => { clearInterval(timer_id); } // キャンセルした場合は定期チェックを止める
      });
    };
  }
}

ko.components.register('select-scs-pms-for-compare', {
  viewModel(params) {
    return new SelectScsPmsForCompare(
      params.max_count,
      params.max_count_compare
    );
  },
  template: { element: 'ko-normal-template' },
  synchronous: true
});
