// 整数を強制するko拡張を定義
ko.extenders.integer = function(target, bool) {
  if (!bool) { return target; }
  const result = ko.pureComputed({
    read: target,
    write(new_value) {
      const current = target();
      let value_to_write = parseInt(new_value, 10);
      if (isNaN(value_to_write) || (value_to_write < 0)) { value_to_write = 0; }
      if (current !== value_to_write) {
        return target(value_to_write);
      } else if (current !== new_value) {
        return target.notifySubscribers(new_value);
      }
    }
  }).extend({notify: 'always'});
  result(target());
  return result;
};