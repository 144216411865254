import { KnockoutModalBase } from '../../../shared/knockout/view_models/knockout_modal_base';
import { SelectShopBrand } from '../../select_model'

class ModalMyGroupSelectShopBrandsViewModel extends KnockoutModalBase {
  constructor(max_count=null, my_group_type=null, modal_title = '') {
    super();
    this.toggle = this.toggle.bind(this);
    this.max_count = max_count;
    this.my_group_type = my_group_type;
    this.modal_title = modal_title;
    this.my_group_id = '';

    this.parent_view_model = null;
    this.list = [];

    ko.track(this);

    ko.postbox.subscribe('showModalMyGroupSelect', (params) => {
      this.parent_view_model = params.parent_view_model;
      this.show();
    });
  }

  list_shop_brands_by_large_industry(type_industry1, strict) {
    if (strict == null) { strict = false; }
    return this.list.filter((model) => {
      if (strict) {
        return (model.model_type === 'shop_brand') &&
          (model.type_industry1 === type_industry1) &&
          (model.type_industry2 === null) &&
          (model.type_industry3 === null);
      } else {
        return (model.model_type === 'shop_brand') && (model.type_industry1 === type_industry1);
      }
    });
  }

  list_shop_brands_by_medium_industry(type_industry1, type_industry2) {
    return this.list.filter(model => (model.model_type === 'shop_brand') &&
      (model.type_industry1 === type_industry1) &&
      (model.type_industry2 === type_industry2));
  }

  list_shop_brands_by_small_industry(type_industry1, type_industry2, type_industry3) {
    return this.list.filter(model => (model.model_type === 'shop_brand') &&
      (model.type_industry1 === type_industry1) &&
      (model.type_industry2 === type_industry2) &&
      (model.type_industry3 === type_industry3));
  }

  toggle(item) {
    item.selectToggle();
    if (item.selected) {
      this.parent_view_model.list.push(item);
    } else {
      this.parent_view_model.list.remove(item);
    }
    if ((this.max_count != null) && (this.parent_view_model.list.length === this.max_count)) { this.hide(); }
  }

  changeMyGroup() {
    if (this.my_group_id !== '') {
      this.list.removeAll();
      $.getJSON(Routes.list_my_group_path(this.my_group_id, {format: 'json'}), (group_items) => {
        const temp_array = [];
        group_items.forEach((item) => {
          const model = new SelectShopBrand(
            item.model_id,
            item.model_name,
            item.model_type,
            item.type_industry1,
            item.type_industry2,
            item.type_industry3
          );
          const filtered_models = this.parent_view_model.list.filter(selected_model => (selected_model.model_type === model.model_type) && (selected_model.model_id === model.model_id));
          if (filtered_models.length > 0) {
            filtered_models.forEach((selected_model) => {
              selected_model.selected = true;
              temp_array.push(selected_model);
            });
          } else {
            temp_array.push(model);
          }
        });
        this.list = temp_array;
      });
    }
  }

  changeMyGroupType(group_type) {
    // マイグループのselectを初期値に戻してグループ選択を再度促す
    this.my_group_id = '';
    this.my_group_type = group_type;
  }

  // 現在のマイグループの指定された大業種のショップを全選択する
  addAllByLargeIndustry(type_industry1) {
    this._addItems(this.list_shop_brands_by_large_industry(type_industry1));
  }

  // 現在のマイグループの指定された大業種のショップを全解除する
  removeAllByLargeIndustry(type_industry1) {
    this._removeItems(this.list_shop_brands_by_large_industry(type_industry1));
  }

  // 現在のマイグループのショップを全選択する
  addAllItems() {
    this._addItems(this.list);
  }

  // 現在のマイグループのショップを全解除する
  removeAllItems() {
    this._removeItems(this.list);
  }

  _addItems(items) {
    items.forEach((item) => {
      item.selected = true;
      if (this.parent_view_model.list.indexOf(item) === -1) { this.parent_view_model.list.push(item); }
    });
  }

  _removeItems(items) {
    items.forEach((item) => {
      item.selected = false;
      if (this.parent_view_model.list.indexOf(item) !== -1) { this.parent_view_model.list.remove(item); }
    });
  }
}

ko.components.register('modal-my_groups-select-shop_brands', {
  viewModel(params) {
    return new ModalMyGroupSelectShopBrandsViewModel(params.max_candidate_count, params.model_type, params.modal_title);
  },
  template: { element: 'modal-my_groups-select_template' }
});