class SearchShopBrandPrefViewModel {
  constructor(prefs, and_conditions = [], only_closed = false) {
    this.remove = this.remove.bind(this);
    this.edit = this.edit.bind(this);
    this.prefs = prefs;
    this.and_conditions = [];
    this.only_closed = only_closed;
    ko.track(this);

    const self = this;
    // 検索結果から戻ってきた際に、検索条件をフォームに反映する
    this.and_conditions = _(and_conditions).toArray().map((and_condition) => {
      const {
        pref_id
      } = and_condition;
      const pref = _.find(self.prefs, pref => pref.id === _.toNumber(pref_id));
      const cities = and_condition.cities || [];
      const condition = and_condition.condition === '1' ? true : false;
      const {
        city_condition
      } = and_condition;
      const only_closed = self.only_closed;
      return new SearchShopBrandPrefConditionViewModel(pref, cities, condition, city_condition, only_closed);
    });

    ko.postbox.subscribe('changedOnlyClosedValue', only_closed => self.only_closed = only_closed);
  }

  remove(or_condition_index, item) {
    this.and_conditions.remove(item);
    // 削除ボタン押下時のみ or_condition を消すか判定する
    if (or_condition_index !== null && this.and_conditions.length === 0) {
      ko.postbox.publish('removeOrCondition', { or_condition_index })
    }
  }

  edit(or_condition_index, item) {
    this.showModalSelectPrefCities(item, or_condition_index);
  }

  add_with_condition_index(or_condition_index) {
    const viewModel = new SearchShopBrandPrefConditionViewModel();
    viewModel.only_closed = this.only_closed;
    this.showModalSelectPrefCities(viewModel, or_condition_index);
  }

  showModalSelectPrefCities(search_shop_brand_pref_condition, condition_index) {
    ko.postbox.publish(
      'showModalSearchShopBrandPrefCondition', {
      search_shop_brand_pref_condition,
      condition_index
    }
    );
  }

  is_exists(item) {
    return _.any(this.and_conditions, and_condition => and_condition.valueOf() === item.valueOf());
  }
}

export class SearchShopBrandPrefConditionViewModel {
  constructor(pref = null, cities = [], opening = true, city_condition = 'or', only_closed = false) {
    this.pref = pref;
    this.cities = cities;
    this.opening = opening;
    this.city_condition = city_condition;
    this.only_closed = only_closed;
    ko.track(this);
    ko.defineProperty(this, 'text_opening', () => {
      if (this.only_closed) {
        if (this.opening) {
          return "退店済み";
        } else {
          return "出退店したことがない";
        }
      } else {
        if (this.opening) {
          return "出店している";
        } else {
          return "出店していない";
        }
      }
    });

    const self = this;

    ko.postbox.subscribe('changedOnlyClosedValue', only_closed => self.only_closed = only_closed);
  }

  valueOf() { return JSON.stringify(this); }
};

export class SearchShopBrandPrefsViewModel {
  constructor(_prefs, or_conditions, only_closed = false) {
    this.remove = this.remove.bind(this);
    this.add_and_condition = this.add_and_condition.bind(this);
    this._prefs = _prefs;
    this.or_conditions = [];
    this.only_closed = only_closed;
    ko.track(this, ['or_conditions']);

    const self = this;

    // 検索条件を復元する
    this.or_conditions = _(or_conditions).toArray().map(and_conditions => new SearchShopBrandPrefViewModel(self._prefs, and_conditions, self.only_closed));

    // モーダルが閉じる時に検索条件を送ってくるのでそれを受け取って条件に入れる
    ko.postbox.subscribe('addSearchShopBrandPrefCondition', (params) => {
      const {
        condition_index
      } = params;
      const condition = self.or_conditions[condition_index];
      if ((params.item.pref !== null) && (condition.is_exists(params.item) === false)) {
        condition.and_conditions.push(params.item);
      } else {
        const changed_index = condition.and_conditions.indexOf(params.item);
        if (changed_index >= 0) {
          ko.getObservable(condition, 'and_conditions').notifySubscribers();
        }
      }

      if (params.add_and_next) { 
        return condition.add_with_condition_index(condition_index); 
      } else if (condition.and_conditions.length === 0) {
        // and_condition がなければ or_condition を削除する
        ko.postbox.publish('removeOrCondition', { or_condition_index: condition_index })
      }
    }); // 続けて追加ボタンが押された場合は直ちにモーダル立ち上げ

    ko.postbox.subscribe('addSearchShopBrandPrefConditions', (params) => {
      const {
        condition_index
      } = params;
      const condition = self.or_conditions[condition_index];
      if (params.prefs.length > 0) {
        condition.remove(null, params.remove_item);
        params.prefs.forEach((pref_id) => {
          const pref = _.find(self._prefs, pref => pref.id === _.toNumber(pref_id));
          const cities = [];
          const city_condition = 'or';
          const item = new SearchShopBrandPrefConditionViewModel(
            pref,
            cities,
            params.opening,
            city_condition,
            params.only_closed,
          );
          if (!condition.is_exists(item)) { return condition.and_conditions.push(item); }
        });
      }
      if (params.add_and_next) { return condition.add_with_condition_index(condition_index); }
    }); // 続けて追加ボタンが押された場合は直ちにモーダル立ち上げ

    ko.postbox.subscribe('changedOnlyClosedValue', only_closed => self.only_closed = only_closed);

    ko.postbox.subscribe('removeOrCondition', params => {
      this.or_conditions.splice(params.or_condition_index, 1);
    })
  }

  add() {
    const or_condition = new SearchShopBrandPrefViewModel(this._prefs, [], this.only_closed);
    this.or_conditions.push(or_condition);
    this.add_and_condition(or_condition);
  }

  remove(item) {
    this.or_conditions.remove(item);
  }

  add_and_condition(or_condition) {
    const or_condition_index = this.or_conditions.indexOf(or_condition);
    or_condition.add_with_condition_index(or_condition_index);
  }

  clear() { this.or_conditions = []; }
};

ko.components.register('search_shop_brand_prefs', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new SearchShopBrandPrefsViewModel(params.prefs, params.or_conditions, params.only_closed);
    }
  },
  template: `\
<div class="form-group">
  <label class="col-xs-3 control-label">出店都道府県</label>
  <div class="col-xs-9">
    <!-- ko foreach: { data: or_conditions, as: 'and_conditions' } -->
    <div class="row">
      <div class="col-xs-12">
        <div class="panel panel-default">
          <div class="panel-heading">
            <span data-bind='text: "条件" + ($index() + 1)'></span>
            <button type="button" class="btn btn-danger btn-xs pull-right" data-bind="click: $parent.remove">この条件を削除</button>
          </div>
          <table class="table table-condensed"
                 data-bind="visible: and_conditions.length > 0">
            <thead>
              <tr>
                <th class="col-xs-3">都道府県</th>
                <th class="col-xs-4">市区町村</th>
                <th class="col-xs-3"></th>
                <th class="col-xs-2"></th>
              </tr>
            </thead>
            <tbody data-bind="foreach: and_conditions">
              <tr>
                <td data-bind="text: pref.name"></td>
                <td>
                  <!-- ko if: cities.length > 0 -->
                    <ul class="list-inline" data-bind="foreach: { data: cities, as: 'city'}">
                      <li data-bind="text: city"></li>
                    </ul>
                  <!-- /ko -->
                  <!-- ko ifnot: cities.length > 0-->
                    全域
                  <!-- /ko -->
                </td>
                <td>
                  <!-- ko if: cities.length > 0 -->
                  <span data-bind="text: city_condition === 'or' ? 'いずれか |' : '全て |'"></span>
                  <!-- /ko -->
                  <span data-bind="text: text_opening"></span>
                </td>
                <td class="text-center">
                  <button type="button" class="btn btn-xs btn-default" data-bind="click: $parent.edit.bind($data, $parentContext.$index())">編集</button>
                  <button type="button" class="btn btn-xs btn-danger" data-bind="click: $parent.remove.bind($data, $parentContext.$index())">削除</button>
                  <input type="hidden" data-bind="attr: {
                      name: 'pref_conditions[' + $parentContext.$index() + '][' + $index() + '][pref_id]'
                    }, value: pref.id">
                  <input type="hidden" data-bind="attr: {
                      name: 'pref_conditions[' + $parentContext.$index() + '][' + $index() + '][condition]'
                    }, value: opening ? 1 : 0">
                  <!-- ko if: cities.length > 0 -->
                  <input type="hidden" data-bind="attr: {
                      name: 'pref_conditions[' + $parentContext.$index() + '][' + $index() + '][city_condition]'
                    }, value: city_condition">
                  <!-- /ko -->
                  <!-- ko foreach: { data: cities, as: 'city' } -->
                  <input type="hidden" data-bind="attr: {
                      name: 'pref_conditions[' + $parentContext.$parentContext.$index() + '][' + $parentContext.$index() + '][cities][]'
                    }, value: city">
                  <!-- /ko -->
                </td>
              </tr>
            </tbody>
          </table>
          <div class="panel-footer text-right">
            <button type="button" class="btn btn-xs btn-default" data-bind="click: $parent.add_and_condition">AND条件を追加</button>
          </div>
        </div>
      </div>
    </div>
    <!-- /ko -->
    <hr data-bind="visible: or_conditions.length > 0">
    <button type="button" class="btn btn-sm btn-default" data-bind="click: add, text: or_conditions.length > 0 ? 'OR条件を追加' : '条件を追加'"></button>
  </div>
</div>\
`,
  synchronous: true
});
