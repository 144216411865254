import { BaseSearchByIndustry } from '../../../_view_models/base_search_by_industry'

export class PartOfSearchScsByIndustries extends BaseSearchByIndustry {
  constructor(large_industries, medium_industries, small_industries,
    selected_large_industry, selected_medium_industry, selected_small_industries,
    include, small_industry_nicknames, id_suffix) {
    super(large_industries, medium_industries, small_industries,
      selected_large_industry, selected_medium_industry, selected_small_industries,
      small_industry_nicknames, id_suffix)
    this.include = include;
    this.form_visible = (this.selected_large_industry != null);
    this.food_court_field_name = null; // SC検索においては、フードコートは別項目になるため
    ko.track(this,
      [
        'form_visible',
        'selected_large_industry',
        'selected_medium_industry',
        'selected_small_industries',
        'small_industry_keywords',
      ]
    );
    ko.getObservable(this, 'small_industry_keywords').extend({ rateLimit: 2000 });

    ko.defineProperty(this, 'large_field_name', function() {
      if (this.include) {
        return "q[type_industry1_eq]";
      } else {
        return "q[type_industry1_ne]";
      }
    });
    ko.defineProperty(this, 'medium_field_name', function() {
      if (this.include) {
        return "q[type_industry2_eq]";
      } else {
        return "q[type_industry2_ne]";
      }
    });
    ko.defineProperty(this, 'small_field_name', function() {
      if (this.include) {
        return "q[type_industry3_in][]";
      } else {
        return "q[type_industry3_not_in][]";
      }
    });
  }

  toggleForm() { return this.form_visible = !this.form_visible; }

  // @override
  selectLarge() { return this._selectLarge(); }

  // @override
  selectMedium() { return this._selectMedium(); }
};

ko.components.register('part-of-search-scs-by-industries', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new PartOfSearchScsByIndustries(
        params.large_industries,
        params.medium_industries,
        params.small_industries,
        params.selected_large_industry,
        params.selected_medium_industry,
        params.selected_small_industries,
        params.include,
        params.selected_small_industries,
        ''
      );
    }
  },
  template: {element: 'part-of-search_by_industries-horizontal'},
  synchronous: true
});