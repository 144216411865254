export class Memo {
  constructor(id, model, user_name, title, status, status_i18n,
                content, content_raw, updated_at, url, mine, members) {
    this.id = id;
    this.model = model;
    this.user_name = user_name;
    this.title = title;
    this.status = status;
    this.status_i18n = status_i18n;
    this.content = content;
    this.content_raw = content_raw;
    this.updated_at = updated_at;
    this.url = url;
    this.mine = mine;
    if (members == null) { members = []; }
    this.members = members;
    this.panel_status = ko.computed(function() {
      switch (this.status) {
        case 'range_open': return 'panel-info';
        case 'range_limited': return 'panel-warning';
        case 'range_close': return 'panel-danger';
      }
    }
    , this);
    this.loading_form = false;
    ko.track(this, ['loading_form']);

    const self = this;
    ko.postbox.subscribe('showLoadingEditMemo', function(params) {
      if (self.id === params.id) { return self.showLoadingForm(); }
    });
    ko.postbox.subscribe('hideLoadingEditMemo', function(params) {
      if (self.id === params.id) { return self.hideLoadingForm(); }
    });
  }

  destroy() {
    if (confirm('本当に削除してもよろしいですか？')) {
      const self = this;
      $.ajax({
        url: Routes.memo_path(self.id, {format: 'json'}),
        method: 'DELETE'
      }).done( function(data, status, xhr) {
        Notify.success(`メモ <strong>『${_.escapeHTML(self.title)}』</strong> を削除しました`);
        return ko.postbox.publish('refreshListMemo');
      }).fail( (xhr, status, error) => Notify.error());
    }
  }

  edit() {
    ko.postbox.publish('showModalFormMemo', this);
  }

  showLoadingForm() { this.loading_form = true; }
  hideLoadingForm() { this.loading_form = false; }
};


ko.components.register('modal-memo-item', {
  viewModel(params) { return params.item; }, // params.item はMemoのインスタンス
  template: `\
<div class="row">
  <div class="col-xs-3">
    <span class="glyphicon glyphicon-user"
          data-bind="text: user_name, css: { 'text-info': mine == true, 'text-warning': mine == false }"></span>
    <br>
    <span data-bind="text: moment(updated_at).format('YYYY/MM/DD HH:mm:ss')"></span>
    <div data-bind="if: mine == true">
      <button class="btn btn-default btn-sm ladda-button"
        type="button" data-style="zoom-out"
        data-bind="click: edit, ladda: loading_form">更新する</button>
      &nbsp;
      <button class="btn btn-danger btn-sm"
        type="button"
        data-bind="click: destroy">削除する</button>
    </div>
  </div>
  <div class="col-xs-9">
    <div class="panel margin-bottom-none" data-bind="css: panel_status">
      <div class="panel-heading">
        <h3 class="bold" data-bind="text: title"></h3>
      </div>
      <div class="panel-body" data-bind="html: content"></div>
      <!-- ko if: status == 'range_limited' -->
      <div class="panel-footer" data-bind="css: panel_status">
        <p>閲覧可能メンバー</p>
        <ul class="list-inline padding-none" data-bind="foreach: {data: members, as: 'member'}">
          <li>
            <span class="glyphicon glyphicon-user">&nbsp;<span data-bind="text: member.full_name"></span>
          </li>
        </ul>
      </div>
      <!-- /ko  -->
    </div>
  </div>
</div>\
`
});