// 地図にSCを表示する場合に使うベースクラス
export class _BaseZenrinMapViewModel {
  constructor(distance=5000, input_type='select') {
    this.input_distance = distance;
    this.selected_distance = distance;
    this.input_type = input_type;
    this.searching = false;
    this.selected_genres = [];

    this.distances = [
      {distance: 500, text: '500m'},
      {distance: 1000, text: '1km'},
      {distance: 1500, text: '1.5km'},
      {distance: 2000, text: '2km'},
      {distance: 3000, text: '3km'},
      {distance: 5000, text: '5km'},
      {distance: 10000, text: '10km'},
      {distance: 20000, text: '20km'},
      {distance: 30000, text: '30km'},
      {distance: 50000, text: '50km'},
    ];

    this.close_shop_distances = [
      {distance: 500, text: '500m'},
      {distance: 1000, text: '1km'},
      {distance: 1500, text: '1.5km'},
      {distance: 2000, text: '2km'},
      {distance: 3000, text: '3km'},
      {distance: 5000, text: '5km'},
      {distance: 10000, text: '10km'},
    ];

    this.open_shop_distances = [
      {distance: 500, text: '500m'},
      {distance: 1000, text: '1km'},
      {distance: 1500, text: '1.5km'},
      {distance: 2000, text: '2km'},
      {distance: 3000, text: '3km'},
      {distance: 5000, text: '5km'},
      {distance: 10000, text: '10km'},
    ];

    this.genres = [
      {first_code: '00140', second_code: '00110', name: 'デパート'},
      {first_code: '00140', second_code: '00230', name: 'ドラッグストア'},
      {first_code: '00140', second_code: '00240', name: '本屋'},
      {first_code: '00140', second_code: '00120', name: '生活雑貨'},
      {first_code: '00140', second_code: '00140', name: 'ディスカウントショップ'},
      {first_code: '00140', second_code: '00150', name: 'ホームセンター'},
      {first_code: '00140', second_code: '00160', name: '複合モール'},
      {first_code: '00140', second_code: '00170', name: 'スーパー'},
      {first_code: '00140', second_code: '00180', name: 'コンビニ'},
      {first_code: '00240', second_code: '', name: 'グルメ'},
      {first_code: '00250', second_code: '', name: '大学・短大・高校・その他学校'}
    ];
  }

  getDistance() {
    if (this.input_type === 'select') {
      return this.selected_distance;
    } else {
      return this.input_distance;
    }
  }

  // 世界測地系の緯度を返す
  getLatitude() { throw new Error("実装してください"); }

  // 世界測地系の経度を返す
  getLongitude() { throw new Error("実装してください"); }

  // 近隣のSCを検索
  searchAdjacentScs() { throw new Error("実装してください"); }
}
