class CompareOpenScList {
  constructor() {
    this.scs = [];
    this.shop_brands = [];
    ko.track(this);
    ko.defineProperty(this, 'compared', function() {
      return this.shop_brands.length > 0;
    });

    const self = this;
    ko.postbox.subscribe('setCompareOpenScList', function(params) {
      self.scs = params.scs;
      self.shop_brands = params.shop_brands;
    });
  }
}

ko.components.register('compare-open-sc-list', {
  viewModel() { return new CompareOpenScList(); },
  template: { element: 'ko-normal-template' },
  synchronous: true
});