$(() => $("[data-bind^='with:'][data-bind$='AddressViewModel']").each(function(e) {
  const prefix = $(this).data('prefix');
  const postal_code = $(this).find("[data-bind='value: postal_code']").val();
  const pref = $(this).find("[data-bind='value: pref']").val();
  const city = $(this).find("[data-bind='value: city']").val();
  const section = $(this).find("[data-bind='value: section']").val();
  app_view_model.addViewModel(`${prefix}AddressViewModel`, new AddressViewModel(postal_code, pref, city, section));
}));

export class AddressViewModel {
  constructor(postal_code, pref, city, section) {
    this.selected = this.selected.bind(this);
    this.postal_code = postal_code;
    this.pref = pref;
    this.city = city;
    this.section = section;

    this.lists = [];
    this.searching = false;
    this.show_modal = false;
    ko.track(this, ['postal_code', 'pref', 'city', 'section', 'lists', 'searching', 'show_modal']);
  }

  search() {
    if ((this.postal_code === null) || (this.postal_code.length === 0)) {
      return alert("郵便番号を入力してください");
    }

    this.searching = true;
    const self = this;
    $.getJSON(Routes.postal_codes_search_address_path(), {postal_code: this.postal_code}).done(function(data) {
      if (data.length > 1) {
        self.lists = data;
        self.show_modal = true;
      } else {
        self.pref = data[0].pref;
        self.city = data[0].city;
        self.section = data[0].section;
      }
    }).fail(function(jqXHR, textStatus, errorThrown) {
      alert('見つかりませんでした');
      self.pref = null;
      self.city = null;
      self.section = null;
    }).always( () => self.searching = false);
  }

  selected(selected) {
    this.pref = selected.pref;
    this.city = selected.city;
    this.section = selected.section;
    this.show_modal = false;
  }
}
