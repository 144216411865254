import { BaseFixedBottomNavigation } from '../../_view_models/base_fixed_bottom_navigation';

class SearchCountNavigation extends BaseFixedBottomNavigation {
  constructor() {
    super();
    this.count = 0;
    this.search_conditions = null;
    this.searching = false;
    this.submitting = false;
    this.invalid_search_conditions = false;
    ko.track(this);
    ko.getObservable(this, 'searching').syncWith('sync_searching');
    ko.getObservable(this, 'submitting').syncWith('sync_submitting');

    ko.defineProperty(
      this,
      'is_enable',
      () => !this.searching && !this.submitting && this.count > 0
    );
    ko.defineProperty(this, 'text_submit_btn', () => {
      if (!this.searching && !this.submitting) {
        return '検索';
      } else if (this.searching) {
        return '件数取得中';
      } else {
        return '送信中';
      }
    });

    ko.postbox.subscribe('invalidSearchConditions', () => {
      this.invalid_search_conditions = true;
      this.count = 0;
    });

    ko.postbox.subscribe('updateSearchCountNavigation', (params) => {
      this.invalid_search_conditions = false;
      this.count = params.count;
      this.search_conditions = params.search_conditions;
    });

    ko.postbox.subscribe('showSearchCountNavigation', () => this.show());
    ko.postbox.subscribe('hideSearchCountNavigation', () => {
      this.count = 0;
      this.search_conditions = null;
      this.invalid_search_conditions = false;
      this.hide();
    });
  }

  sendToSubmit() {
    this.submitting = true;
    ko.postbox.publish('submitSearchForm');
  }
}

ko.components.register('search-count-navigation', {
  viewModel(params) {
    return new SearchCountNavigation();
  },
  template: { element: 'fixed_bottom_navigation' },
});
