class NavbarAttackListButtonViewModel {
  constructor(model_type) {
    this.selectCloseShop = this.selectCloseShop.bind(this);
    this.attack_lists = [];
    this.page = 1;
    this.downloading = false;
    this.is_last_page = false;
    this.model_type = model_type;
    ko.track(this);
    this.downloadAttackLists();
  }

  getTargets() {
    return _.map($("input[name='model_id[]']:checked"), (element) => {
      return {
        model_id: $(element).val(),
        model_type: this.model_type
      };
    });
  }

  validConditions() {
    if (!$("input[name='model_id[]']:checked").is('*')) {
      Notify.danger('追加対象を1件以上選択してください');
      return false;
    }
    return true;
  }

  selectCloseShop(attack_list) {
    if (this.validConditions()) {
      ko.postbox.publish('showFormAttackListCloseShopBrandList', {
        attack_list,
        targets: this.getTargets()
      });
    }
  }

  redirectToNewAttackList() {
    if (this.validConditions()) {
      const params = new URLSearchParams();
      this.getTargets().forEach((target) =>
        params.append(`${target.model_type}_id[]`, target.model_id)
      );
      location.href =
        Routes.new_mypage_attack_list_path() + '?' + params.toString();
    }
  }

  downloadIfScrollBottom(_, event) {
    if (this.isScrolledBottom(event.currentTarget)) {
      if (!this.is_last_page && !this.downloading) {
        this.downloadAttackLists();
      }
    }
  }

  downloadAttackLists() {
    const self = this;
    self.downloading = true;
    $.getJSON(Routes.mypage_attack_lists_path({ format: 'json', p: self.page }))
      .done((data) => {
        ko.utils.arrayPushAll(self.attack_lists, data.attack_lists);
        self.page = data.next_page;
        return (self.is_last_page = data.last_page);
      })
      .always(() => (self.downloading = false));
  }

  isScrolledBottom(element) {
    return element.scrollHeight - element.scrollTop < element.clientHeight + 10;
  }
}

ko.components.register('navbar-attack_list-button', {
  viewModel(params) {
    return new NavbarAttackListButtonViewModel(params.model_type);
  },
  template: `\
<div id="btn-navbar-attack_list" class="btn-group">
  <button class="btn btn-default btn-sm dropdown-toggle navbar-btn"
    type="button" data-toggle="dropdown" aria-expanded="false"
  >リーシングリストに追加&nbsp;<span class="caret"></span></button>
  <ul class="dropdown-menu scrollable-menu" role="menu" data-bind="event: { scroll: downloadIfScrollBottom }">
    <li><a data-bind="click: redirectToNewAttackList">新規作成</a></li>
    <li class="divider" data-bind="visible: attack_lists.length > 0"></li>
    <li class="dropdown-header" data-bind="visible: attack_lists.length > 0">既存リストに追加</li>
    <!-- ko foreach: { data: attack_lists, as: 'attack_list'} -->
    <li><a data-bind="text: attack_list.title, click: $parent.selectCloseShop"></a></li>
    <!-- /ko -->
    <li class="dropdown-header" data-bind="visible: downloading">
      <div class="progress margin-bottom-none">
        <div class="progress-bar progress-bar-striped active" role="progressbar" style="width: 100%">
          <span>ロード中</span>
        </div>
      </div>
    </li>
  </ul>
</div>\
`
});
