ko.bindingHandlers.cursor_wait = {
    init: function(element, valueAccessor) {
        ko.computed({
            read: function() {
                if (ko.unwrap(valueAccessor()))
                    document.body.style.cursor = 'wait';
                else
                    document.body.style.cursor = 'auto';
            },
            disposeWhenNodeIsRemoved: element
        });
    }
};