class NavbarMyGroupDeleteItemsButtonViewModel {
  constructor(model_type) {
    this.model_type = model_type;
    this.count = 0;
    this.item_ids = [];
    this.submitting = false;
    ko.track(this);

    ko.defineProperty(
      this,
      'is_enable',
      () => this.submitting === false && this.count > 0
    );

    ko.defineProperty(this, 'text_submit_btn', () => {
      if (this.submitting === false) {
        return '削除';
      } else {
        return '送信中';
      }
    });

    $(document).on('change', "input[name='model_id[]']", () => {
      this.count = $("input[name='model_id[]']:checked").length;
    });

    // ソートしたら選択が外れるようにしなければならない
    ko.postbox.subscribe('changeItemList', () => this.resetCount());
  }

  removeItems() {
    if (confirm('本当に削除してもよろしいですか？')) {
      this.submitting = true;
      this.item_ids = _.map($("input[name='model_id[]']:checked"), (element) =>
        $(element).val()
      );
      return true;
    }
  }

  resetCount() {
    this.count = 0;
  }
}

ko.components.register('navbar-my_group-delete_items-button', {
  viewModel(params) {
    return new NavbarMyGroupDeleteItemsButtonViewModel(params.model_type);
  },
  template: { element: 'ko-normal-template' },
});
