import { KnockoutModalBase } from '../../../shared/knockout/view_models/knockout_modal_base';
import { Memo } from './modal_memo_item';

class ModalListMemoViewModel extends KnockoutModalBase {
  constructor() {
    super();
    this.model_id = null;
    this.model_type = null;
    this.url = null;
    this.list_items = [];
    this.list_my_items = () => this.list_items.filter(memo => memo.mine === true);
    this.list_others_items = () => this.list_items.filter(memo => memo.mine === false);
    ko.track(this);

    const self = this;
    $(document).on('show.bs.modal', '#modal-list-memo', () => $.when(self.load()).done(function() {
      ko.postbox.publish('resetCountMemoButton', {count: self.list_items.length});
      ko.postbox.publish('hideLoadingMemoButton');
    }));

    ko.postbox.subscribe('showModalListMemo', function(params) {
      ko.postbox.publish('showLoadingMemoButton');
      self.model_id = params.model_id;
      self.model_type = params.model_type;
      self.url = params.url;
      self.show();
    });

    ko.postbox.subscribe('refreshListMemo', () => $.when(self.load()).done(function() {
      ko.postbox.publish('resetCountMemoButton', {count: self.list_items.length});
      if (!(self.list_items.length > 0)) { return self.hide(); }
    }));
  }

  load() {
    const self = this;
    const params = {};
    if (this.model_id != null) { params.model_id = this.model_id; }
    if (this.model_type != null) { params.model_type = this.model_type; }
    if (this.url != null) { params.url = this.url; }
    return $.getJSON(Routes.memos_path({format: 'json'}), params, function(memos) {
      self.list_items.removeAll();
      const list_memos = memos.map(memo => new Memo(
        memo.id,
        memo.model,
        memo.user.full_name,
        memo.title,
        memo.status,
        memo.status_i18n,
        memo.content,
        memo.content_raw,
        memo.updated_at,
        memo.url,
        memo.mine,
        memo.members
      ));
      self.list_items = list_memos;
    });
  }
}

ko.components.register('modal-list-memo', {
  viewModel(params) {
    return new ModalListMemoViewModel();
  },
  template: `\
<div id="modal-list-memo" class="modal fade" role="dialog" data-bind="bs_modal: modal_state">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden='true'>&times;</span></button>
        <h3 class="modal-title">関連するメモ一覧</h3>
      </div>
      <div class="modal-body">
        <div role="tabpanel">
          <!-- nav-tabs start -->
          <ul class="nav nav-tabs" role="tablist">
            <li class="active" role="presentation">
              <a data-toggle="tab" role="tab" aria-controls="memo_all" href="#memo_all">
                全員のメモ&nbsp;<span class="badge" data-bind="text: list_items.length"></span>
              </a>
            </li>
            <li role="presentation">
              <a data-toggle="tab" role="tab" aria-controls="memo_all" href="#memo_mine">
                自分のメモ&nbsp;<span class="badge" data-bind="text: list_my_items().length"></span>
              </a>
            </li>
            <li role="presentation">
              <a data-toggle="tab" role="tab" aria-controls="memo_all" href="#memo_other">
                メンバーのメモ&nbsp;<span class="badge" data-bind="text: list_others_items().length"></span>
              </a>
            </li>
          </ul>
          <!-- nav-tabs end -->

          <!-- tab panes start -->
          <div class="tab-content">
            <!-- 全員のメモ -->
            <div id="memo_all" class="tab-pane active" role="tabpanel">
              <div class="container-fluid memo-list" data-bind="foreach: list_items">
                <modal-memo-item params="item: $data"></modal-memo-item>
                <hr data-bind="visible: ($index() != ($parent.list_items.length - 1))">
              </div>
            </div>
            <!-- 自分のメモ -->
            <div id="memo_mine" class="tab-pane" role="tabpanel">
              <div class="container-fluid memo-list" data-bind="foreach: list_my_items()">
                <modal-memo-item params="item: $data"></modal-memo-item>
                <hr data-bind="visible: ($index() != ($parent.list_my_items().length - 1))">
              </div>
            </div>
            <!-- メンバーのメモ -->
            <div id="memo_other" class="tab-pane" role="tabpanel">
              <div class="container-fluid memo-list" data-bind="foreach: list_others_items()">
                <modal-memo-item params="item: $data"></modal-memo-item>
                <hr data-bind="visible: ($index() != ($parent.list_others_items().length - 1))">
              </div>
            </div>
          </div>
          <!-- tab panes end -->
        </div>
      </div>
      <div class="modal-footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-offset-10 col-xs-2">
              <button type="button" class="btn btn-cancel btn-block" data-dismiss="modal">閉じる</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>\
`
});