export class PartOfSearchByPm {
  constructor(initials, pms, selected_initial = null, selected_pm_id = null) {
    this.getPms = this.getPms.bind(this);
    this.initials = initials;
    if (pms == null) { pms = {}; }
    this.pms = pms;
    this.selected_initial = selected_initial;
    this.selected_pm_id = selected_pm_id;
    ko.track(this, ['pms', 'selected_initial', 'selected_pm_id']);
    const self = this;
    ko.defineProperty(this, 'pms_initials', () => _.keys(self.pms));
  }

  getPms(initial) {
    if (this.selected_initial !== initial) {
      const self = this;
      this.selected_initial = initial;
      $.getJSON(Routes.pms_path({initial, format: 'json'}), data => self.pms = data);
    }
  }

  clear() {
    this.pms = {};
    this.selected_initial = null;
    this.selected_pm_id = null;
  }
};

ko.components.register('part-of-search_by_pm', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new PartOfSearchByPm(
        params.initials,
        params.pms,
        params.selected_initial,
        params.selected_pm_id
      );
    }
  },
  template: { element: 'part-of-search_by_pm' }
});