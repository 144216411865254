class DetailHeaderViewModel {
  constructor() {
    this.element = null;
    this.element_offset_top = null;
    this.pos = $(window).scrollTop();
    ko.track(this);

    ko.defineProperty(this, 'fixed', function() {
      if (this.element_offset_top == null) { return false; }
      const fixed_header_top = this.element_offset_top - this.getFixedAreaHeight();
      return this.pos > fixed_header_top;
    });

    ko.defineProperty(this, 'header_height', function() {
      // 原因が不明だけれど、@getNormalHeader().outerHeight(true)の値が
      // コンポーネント化する前とした後で11ズレているので、30-11=19で調整した
      return this.getNormalHeader().outerHeight(true) + 19;
    }); // 30はタブのmargin-bottom

    this.setOnScrollListener();

    const self = this;
    ko.postbox.subscribe('scrollToElementWithDetailHeader', params => self.scrollToElement(document.getElementById(params.element_id)));
  }

  fixedStatus() {
    if (this.fixed) {
      return 'model-header-fixed';
    } else {
      return 'model-header-normal';
    }
  }


  activeArea(index) {
    const current_section_top = index === 0 ? 0 : $($("section")[index]).offset().top - 1;
    const next_section_top = index === ($("section").length - 1) ? $(document).height() : $($("section")[index + 1]).offset().top - 1;
    const model_header_height = this.getNormalHeader().height();
    const current_pos = this.pos + this.getFixedAreaHeight() + model_header_height;

    if (this.isScrolledToBottom()) { return index === ($("section").length - 1); }

    return current_section_top <= current_pos && current_pos < next_section_top;
  }

  // ウィンドウの一番上の位置を記録する
  setOnScrollListener() {
    const self = this;
    $(window).scroll(() => self.pos = $(window).scrollTop());
  }

  scrollToSection(section_no) {
    this.scrollToElement($('section')[section_no]);
  }

  scrollToElement(element) {
    const model_header_height = this.getNormalHeader().height();
    const element_top = $(element).offset().top - (this.getFixedAreaHeight() + model_header_height);
    $("html, body").animate({scrollTop: element_top + 1}, 300);
  }

  getFixedAreaHeight() {
    const navbar_height = 46;
    const sum_padding_top_bottom = 10;
    return navbar_height + sum_padding_top_bottom;
  }

  getNormalHeader() {
    if (this.element != null) { return $(this.element.children[0]); }
  }

  isScrolledToBottom() {
    return ($(window).scrollTop() + $(window).height()) === $(document).height();
  }
}

ko.components.register('detail-header', {
  viewModel() { return new DetailHeaderViewModel(); },
  template: { element: 'detail-header-template' }
});