ko.bindingHandlers.elementOffsetTop = {
  init(element, valueAccessor, allBindings) {
    // バインディングしたViewModelの要素の位置をViewModelに持たせる
    const propWriters = allBindings()['_ko_property_writers'];
    const value = valueAccessor();
    if (ko.isObservable(value)) {
      return value($(element).offset().top);
    } else {
      return propWriters.elementOffsetTop($(element).offset().top);
    }
  }
};
ko.expressionRewriting._twoWayBindings['elementOffsetTop'] = true;