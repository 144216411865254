import { IndustryViewModel } from '../../_view_models/industry_view_model'

class FilterCompareIndustries {
  constructor(large_industries) {
    this.changeActive = this.changeActive.bind(this);
    this.industries = large_industries.map(industry => new IndustryViewModel(industry.name, industry.type_industry1, false));
    this.industries.unshift(new IndustryViewModel('すべて', 0, true));
    this.industries.push(new IndustryViewModel('どのSC・PMにもない業種', 'not_include', false))
    ko.track(this, ['industries']);

    const self = this;
    ko.postbox.subscribe('selectByIndustry', type_industry1 => self.selectByIndustry(type_industry1));

    ko.postbox.subscribe('showFilterIndustry', () => self.showFilterIndustry());
  }

  changeActive(item) {
    this.industries.forEach(industry => industry.active = false);
    item.active = true;
    $('#brands_result').data('type_industry1', item.type_industry1);
    this.showFilterIndustry();
  }

  showFilterIndustry() {
    const selected_industry = this.industries.filter(industry => industry.active)[0];
    if (selected_industry.type_industry1 === 0) {
      $("[class^=type_industry_]").show();
    } else {
      $("[class^=type_industry_]").hide();
      $(`.type_industry_${selected_industry.type_industry1}`).show();
    }
    this.showBothShopCount();
  }

  showBothShopCount() {
    $('.model-list').each(function() {
      const model_list = this;
      const title_bar = $(model_list).prev();
      if ($(model_list).is(':empty')) {
        $('.shop_brand_count', title_bar).html("(店舗数：一覧表示後にわかります)");
      } else {
        const count = $('table > tbody > tr', model_list).filter(function() { return $(this).css('display') !== 'none'; }).length;
        $('.shop_brand_count', title_bar).html(`(${count}店舗)`);
      }
    });
  }

  selectByIndustry(type_industry1) {
    const item = _.find(this.industries, industry => industry.type_industry1 === type_industry1);
    this.changeActive(item);
  }
}

ko.components.register('filter-compare-industries', {
  viewModel(params) { return new FilterCompareIndustries(params.large_industries); },
  template: { element: 'ko-normal-template' },
  synchronous: true
});