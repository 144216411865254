import { C3AxisImpl} from "./c3_axis_impl";
import { C3ButtonDropdown } from "./c3_button_dropdown";
import { getPageTitle } from "../utilities";
import * as d3 from 'd3';

export class C3ShopCompanyIndustryChart extends C3ButtonDropdown {
  constructor(element_id, json_data) {
    super(element_id);
    this.json_data = json_data;
    this.back_button = null;
    this.drilldown_data = null;
    Object.assign(C3ShopCompanyIndustryChart.prototype, C3AxisImpl);
  }

  show() {
    const self = this;
    const max_count = d3.max(_.pluck(this.json_data.industries, 'y'));

    const chart = this.generate({
      bar: {
        zerobased: true
      },
      title: {
        text: `${getPageTitle()}の業種別ショップ・ブランド`,
        position: 'top-center'
      },
      size: {
        height: 400
      },
      data: {
        json: this.json_data.industries,
        type: 'bar',
        keys: {
          value:['y']
        },
        names: {
          y: 'ショップ・ブランド数'
        },
        labels: {
          format: d3.format('01,d')
        },
        colors: {
          y: '#59B200'
        },
        order: null,
        onclick(d, element) {
          self.back_button.show();
          const selected_industry = _.find(self.json_data.drilldown_data, data => data.name === this.categories()[d.index]);
          if (selected_industry != null) {
            self.drilldown_data = selected_industry;
            if (self.drilldown_data.data.length > 1) {
              const categories = _.pluck(self.drilldown_data.data, 'name');
              chart.load({
                unload: true,
                json: self.drilldown_data.data,
                keys: {
                  value:['y']
                },
                categories
              });
            }
          }
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: this._industryNames(),
          tick: {
            rotate: -15,
            multiline: false,
          },
          height:  100
        },
        y: {
          label: {
            text: 'ショップ・ブランド数',
            position: 'outer-middle'
          },
          tick: {
            format: d3.format('01,d'),
            values: self._getAxisYTickValues(max_count)
          },
          min: 0,
          padding: { bottom: 0 },
          max: self._getAxisYMax(max_count)
        }
      },
      legend: {
        hide: true
      },
      oninit: () => {
        this.onInit();
        this.svg.append('text')
          .attr('x', this.svg.attr('width') / 2)
          .attr('y', 40)
          .style('text-anchor', 'middle')
          .style('font-size', 12)
          .text('グラフをクリックすると業種をドリルダウンできます');

        this.back_button = $('#industry-type .btn-back-drilldown');
        this.back_button.on('click', () => {
          if (this.drilldown_data != null) {
            this.drilldown_data = (this.drilldown_data.parent != null) ?
              _.find(this.json_data.drilldown_data, data => data.name === this.drilldown_data.parent)
            :
              null;

            const data = (this.drilldown_data != null) ? this.drilldown_data.data : this.json_data.industries;
            chart.load({
              unload: true,
              json: data,
              keys: {
                value:['y']
              },
              categories: _.pluck(data, 'name')
            });
            if (this.drilldown_data == null) { return this.back_button.hide(); }
          }
        });
      }
    });
  }

  _industryNames() {
    return _.pluck(this.json_data.industries, 'name');
  }
};
