class CheckboxOnOffButtonsViewModel {
  constructor(target_class) {
    if (target_class == null) { target_class = '.model_id'; }
    this.target_class = target_class;
  }

  checkOnAll() {
    $(this.target_class).prop('checked', true).trigger('change');
  }

  checkOffAll() {
    $(this.target_class).prop('checked', false).trigger('change');
  }
}

ko.components.register('checkbox_on_off_buttons', {
  viewModel(params) {
    return new CheckboxOnOffButtonsViewModel(params.target_class);
  },
  template: `\
<div class="row">
  <div class="col-xs-12">
    <button class="btn btn-default btn-sm" data-bind="click: checkOnAll">ページ内全選択</button>
    &nbsp;
    <button class="btn btn-warning btn-sm" data-bind="click: checkOffAll">ページ内全解除</button>
  </div>
</div>\
`
});