import { BaseMyGroupButtonViewModel} from "../_view_models/base_my_group_button_view_model";

class NavbarMyGroupButtonViewModel extends BaseMyGroupButtonViewModel {
  constructor(model_type, max_my_group_size, my_groups_with_list = []) {
    super(max_my_group_size, my_groups_with_list);
    this.addMyGroup = this.addMyGroup.bind(this);
    this.model = { type: model_type };
    ko.track(this);

    const self = this;
    ko.postbox.subscribe('addMyGroupFromNavbarMyGroupButton', my_group => self.addMyGroup(my_group));

    ko.postbox.subscribe('reloadMyGroupsFromNavbarMyGroupButton', () => self.reloadMyGroups(() => ko.postbox.publish('syncMyGroupButton', {model_type: self.model.type, my_groups: self.my_groups})));

    ko.postbox.subscribe('syncToNavbarMyGroupButton', function(params) {
      if (self.model.type === params.model_type) { return self.my_groups = params.my_groups; }
    });
  }

  addMyGroup(my_group) {
    if (!$("input[name='model_id[]']:checked").is("*")) {
      Notify.danger('追加対象を選択してください。');
      return;
    }

    const model_ids = _.map($("input[name='model_id[]']:checked"), element => $(element).val());

    $.post(Routes.add_all_my_group_path({group_type: this.model.type}),
      {my_group_id: my_group.id, model_id: model_ids}
    ).done((data, status, xhr) => Notify.success(`グループ <strong>${_.escapeHTML(my_group.name)}</strong>に追加しました`)).fail(function(xhr, status, statusText) {
      switch (xhr.status) {
        case 403:
          Notify.warning("グループ登録数の上限を超えるため、登録できませんでした");
          break;
        default:
          Notify.error();
      }
    });
  }

  showFormNewMyGroup() {
    if (!$("input[name='model_id[]']:checked").is("*")) {
      Notify.danger('追加対象を選択してください。');
      return;
    }
    ko.postbox.publish("showFormNewMyGroup", {
      class_name: 'NavbarMyGroupButtonViewModel',
      model_type: _.classify(this.model.type)
    });
  }
}

ko.components.register('navbar-my_group-button', {
  viewModel(params) {
    return new NavbarMyGroupButtonViewModel(params.model_type, params.max_my_group_size, params.my_groups_with_list);
  },
  template: `\
<div id="btn-navbar-my-group" class="btn-group">
  <button class="btn btn-default btn-sm dropdown-toggle navbar-btn ladda-button"
    type="button" data-toggle="dropdown" aria-expanded="false"
    data-style="zoom-out" data-size="s" data-bind="ladda: loading_my_groups"
  >マイグループに追加&nbsp;<span class="caret"></span></button>
  <ul class="dropdown-menu scrollable-menu" role="menu">
    <!-- ko if: only_my_groups.length >= max_my_group_size -->
      <li><a href="javascript:void(0)">グループ数が上限に達しています</a></li>
    <!-- /ko -->
    <!-- ko ifnot: only_my_groups.length >= max_my_group_size -->
      <li><a data-bind="click: showFormNewMyGroup">新規作成</a></li>
    <!-- /ko -->
    <li class="divider" data-bind="visible: my_groups.length > 0"></li>
    <!-- ko foreach: my_groups -->
      <li><a data-bind="click: $parent.addMyGroup, text: name"></a></li>
    <!-- /ko -->
  </ul>
</div>\
`
});