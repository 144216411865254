export class PartOfSearchByDeveloper {
  constructor(
    initials,
    developers,
    selected_initial = null,
    selected_developer_id = null
  ) {
    this.getDevelopers = this.getDevelopers.bind(this);
    this.initials = initials;
    if (developers == null) {
      developers = {};
    }
    this.developers = developers;
    this.selected_initial = selected_initial;
    this.selected_developer_id = selected_developer_id;
    ko.track(this, ['developers', 'selected_initial', 'selected_developer_id']);
    ko.defineProperty(this, 'developers_initials', () =>
      _.keys(this.developers)
    );
  }

  getDevelopers(initial) {
    if (this.selected_initial !== initial) {
      this.selected_initial = initial;
      $.getJSON(
        Routes.developers_path({ initial, format: 'json' }),
        (data) => (this.developers = data)
      );
    }
  }

  clear() {
    this.developers = {};
    this.selected_initial = null;
    this.selected_developer_id = null;
  }
}

ko.components.register('part-of-search_by_developer', {
  viewModel(params) {
    if (params.viewModel != null) {
      return params.viewModel;
    } else {
      return new PartOfSearchByDeveloper(
        params.initials,
        params.developers,
        params.selected_initial,
        params.selected_developer_id
      );
    }
  },
  template: { element: 'part-of-search_by_developer' },
});
