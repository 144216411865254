import * as d3 from 'd3';
import { C3ButtonDropdown } from './c3_button_dropdown';

export class C3CompareChart extends C3ButtonDropdown {
  constructor(column_name, title, json_data) {
    super(`#compare_${column_name}_chart`);
    this.column_name = column_name;
    this.title = title;
    this.json_data = json_data;
  }

  show() {
    this.generate({
      bar: {
        zerobased: true
      },
      title: {
        text: this.title,
        position: 'top-center'
      },
      size: {
        height: 400
      },
      data: {
        json: this.json_data.latest_sales_histories,
        type: 'bar',
        keys: {
          value: [this.column_name]
        },
        names: {
          [this.column_name]: this.title
        },
        colors: {
          [this.column_name]: '#59B200'
        },
        labels: true
      },
      axis: {
        x: {
          type: 'category',
          categories: _.pluck(this.json_data.latest_sales_histories, 'sc_name'),
          tick: {
            multiline: true
          }
        },
        y: {
          label: {
            text: this.title,
            position: 'outer-middle'
          },
          tick: {
            format: d3.format('01,d')
          },
          min: 0,
          padding: { bottom: 0 }
        }
      },
      legend: {
        hide: true
      }
    });
  }
};
