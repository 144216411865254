// いつもNAVI API 3.0 JavaScript API マニュアル
// https://support.e-map.ne.jp/manuals/V30/

import MapIcon from '../../images/map_icons/mapicons01-064.png';

export class AdjacentScsMap {
  static getCircleOval(latlon, distance) {
    return new ZDC.Oval(
      {
        latlon,
        x: distance,
        y: distance,
      },
      {
        strokeColor: '#0000ff',
        strokeWeight: 2,
        fillColor: '#0000ff',
        lineOpacity: 0.3,
        fillOpacity: 0.1,
        circle: true,
      }
    );
  }

  static createLatLon(lat, lon) {
    return ZDC.wgsTotky(new ZDC.LatLon(lat, lon));
  }

  constructor(
    view_model,
    map_element,
    clickable,
    search_scs_on_map,
    use_makepla
  ) {
    this.view_model = view_model;
    if (clickable == null) {
      clickable = false;
    }
    if (search_scs_on_map == null) {
      search_scs_on_map = false;
    }
    if (use_makepla == null) {
      use_makepla = false;
    }
    this.use_makepla = use_makepla;
    this.main_marker = null;
    this.adjacent_scs_markers = [];
    this.circle_oval = null;
    this.poi_markers = [];

    let latlon = AdjacentScsMap.createLatLon(
      this.view_model.getLatitude(),
      this.view_model.getLongitude()
    );
    this.map = new ZDC.Map(map_element, {
      latlon,
      zoom: 7,
      wheelType: 3,
    });
    // コントロールを作成
    this.map.addWidget(
      new ZDC.Control({
        pos: { top: 10, left: 10 },
        type: ZDC.CTRL_TYPE_NORMAL,
      })
    );
    // 吹き出しを作成
    this.message = new ZDC.MsgInfo(latlon, { offset: ZDC.Pixel(0, -18) });
    this.map.addWidget(this.message);

    // SEARCHBOX用の吹き出しを作成
    this.makepla_message = new ZDC.MsgInfo(latlon);
    this.map.addWidget(this.makepla_message);
    this.drawMainMarker(latlon);
    this.drawCircleOval();

    if (clickable) {
      if (search_scs_on_map) {
        ZDC.addListener(this.map, ZDC.MAP_CLICK, () => {
          this.message.close();
          return this.moveAndDraw(this.map.getPointerPosition());
        });
      } else {
        if (this.use_makepla) {
          ZDC.addListener(this.map, ZDC.MAP_CLICK, () => {
            latlon = this.map.getPointerPosition();
            const msg = this.makepla_message;
            msg.moveLatLon(latlon);
            msg.setHtml(`\
              <a class='btn btn-makepla btn-sm'
              href='${this.getMakeplaUrlByLatLon(latlon)}'
              target='_blank'
              ><i class='glyphicon glyphicon-map-marker'></i>&nbsp;SEARCHBOXで見る</a>\
            `);
            return msg.open();
          });
        }
      }
      return;
    }
  }

  moveLatLon(latlon) {
    this.map.setHome(latlon);
    this.main_marker.moveLatLon(latlon);
    this.map.moveHome();
    if (this.view_model.latlon != null) {
      this.view_model.latlon = latlon;
    }
  }

  moveAndDraw(latlon) {
    this.moveLatLon(latlon);
    this.drawCircleOval();
    this.view_model.searchAdjacentScs();
  }

  drawAll() {
    this.drawCircleOval();
    this.showAdjacentScsMarkers();
    this.showPoiMarkers();
  }

  drawMainMarker(latlon) {
    if (this.main_marker != null) {
      this.map.removeWidget(this.main_marker);
    }
    this.main_marker = new ZDC.Marker(latlon, {
      color: ZDC.MARKER_COLOR_ID_BLUE_L,
    });
    this.map.addWidget(this.main_marker);
    if (this.use_makepla) {
      if (this.view_model.sc) {
        return ZDC.addListener(this.main_marker, ZDC.MARKER_MOUSEDOWN, () => {
          this.message.moveLatLon(this.main_marker.getLatLon());
          this.message.setHtml(`\
              <a class='btn btn-makepla btn-sm'
                href='${this.getMakeplaUrlById(this.view_model.sc.id)}'
                target='_blank'
              ><i class='glyphicon glyphicon-map-marker'></i>&nbsp;SEARCHBOXで見る</a>\
            `);
          this.message.open();
        });
      } else {
        return ZDC.addListener(this.main_marker, ZDC.MARKER_MOUSEDOWN, () => {
          this.message.moveLatLon(this.main_marker.getLatLon());
          this.message.setHtml(`\
              <a class='btn btn-makepla btn-sm'
                href='${this.getMakeplaUrlByLatLon(
                  this.main_marker.getLatLon()
                )}'
                target='_blank'
              ><i class='glyphicon glyphicon-map-marker'></i>&nbsp;SEARCHBOXで見る</a>\
            `);
          this.message.open();
        });
      }
    }
  }

  drawCircleOval() {
    if (this.circle_oval != null) {
      this.map.removeWidget(this.circle_oval);
    }
    this.circle_oval = AdjacentScsMap.getCircleOval(
      this.main_marker.getLatLon(),
      this.view_model.getDistance()
    );
    this.map.addWidget(this.circle_oval);
  }

  clearAdjacentScsMakers() {
    this.adjacent_scs_markers.forEach((marker) =>
      this.map.removeWidget(marker)
    );
    this.adjacent_scs_markers = [];
  }

  showAdjacentScsMarkers() {
    this.clearAdjacentScsMakers();

    this.view_model.open_adjacent_scs.forEach((adjacent_sc, index) => {
      const latlon = AdjacentScsMap.createLatLon(
        adjacent_sc.latitude,
        adjacent_sc.longitude
      );
      const marker = new ZDC.Marker(latlon, {
        color: ZDC.MARKER_COLOR_ID_RED_S,
      });
      marker.setTitle(adjacent_sc.name);
      this.map.addWidget(marker);
      this.adjacent_scs_markers.push(marker);
      const self = this;
      ZDC.bind(
        marker,
        ZDC.MARKER_MOUSEDOWN, // ZDC.MARKER_CLICKはスマートフォンでは動かないため、変更
        {
          id: adjacent_sc.id,
          name: adjacent_sc.name,
          latlon,
          distance: adjacent_sc.distance,
          info: this.message,
        },
        function () {
          this.info.moveLatLon(this.latlon);
          if (self.use_makepla) {
            this.info.setHtml(`\
              <b>${index + 1}. <a href='${Routes.sc_path(
              this.id
            )}'>${_.escapeHTML(this.name)}</b></a><br>
              距離: ${_.numberFormat(this.distance)}m<br>
              <a class='btn btn-makepla btn-sm'
                href='${self.getMakeplaUrlById(this.id)}'
                target='_blank'
              ><i class='glyphicon glyphicon-map-marker'></i>&nbsp;SEARCHBOXで見る</a>\
              `);
          } else {
            this.info.setHtml(`\
              <b>${index + 1}. <a href='${Routes.sc_path(
              this.id
            )}'>${_.escapeHTML(this.name)}</b></a><br>
              距離: ${_.numberFormat(this.distance)}m\
            `);
          }
          this.info.open();
        }
      );
    });
  }

  showPoiMarkers() {
    this.clearPoiMakers();
    if (this.view_model.selected_genres.length !== 0) {
      const group = this.view_model.selected_genres.reduce(
        (hash, currentValue) => {
          if (hash[currentValue.first_code] == null) {
            hash[currentValue.first_code] = [];
          }
          if (currentValue.first_code === '00250') {
            // 大学・短大・専門学校・高校・高専
            hash[currentValue.first_code] = [
              '00110',
              '00120',
              '00130',
              '00140',
              '00200',
            ];
          } else {
            hash[currentValue.first_code].push(currentValue.second_code);
          }
          return hash;
        },
        {}
      );
      // first_codeが同一の場合のみ複数検索が可能なので、first_codeの数だけ検索を繰り返す
      $.each(group, (first_code, second_codes) => {
        const genre_codes = second_codes
          .map((second_code) => first_code + second_code)
          .join(',');
        this.getPois(genre_codes);
      });
    }
  }

  getPois(genre_codes_string) {
    const radius =
      this.view_model.getDistance() > 10000
        ? 10000
        : this.view_model.getDistance();
    const latlon = `${this.view_model.getLatitude()},${this.view_model.getLongitude()}`;
    const params = {
      latlon: latlon,
      radius: radius,
      genrecode: genre_codes_string,
      limit: '0,1000',
    };
    return ZDC.Search.getByZmaps('poi/latlon', params, (status, res) => {
      if (status.code !== 200) {
        console.log(`POIの取得に失敗しました。${status.code}: ${status.text}`);
        return;
      }
      return res.item.forEach((item) => this.makePoiMarker(item));
    });
  }

  makePoiMarker(item) {
    const latlon = new ZDC.LatLon(item.poi.point.lat, item.poi.point.lon);
    const custom = {
      base: {
        src: MapIcon,
      },
    };
    const marker = new ZDC.Marker(latlon, {
      offset: new ZDC.Pixel(-16, -32),
      custom,
    });
    this.map.addWidget(marker);
    this.poi_markers.push(marker);
    const self = this;
    ZDC.bind(
      marker,
      ZDC.MARKER_MOUSEDOWN, // ZDC.MARKER_CLICKはスマートフォンでは動かないため、変更
      { item, latlon, info: this.message },
      function () {
        const name = this.item.poi.text;
        const genre = this.item.poi.genre.text;
        const { distance } = this.item;
        if (self.use_makepla) {
          this.info.moveLatLon(this.latlon);
          this.info.setHtml(`\
            <b>${_.escapeHTML(name)}</b><br>
            ジャンル: ${_.escapeHTML(genre)}<br>
            距離: ${_.numberFormat(distance)}m<br>
            <a class='btn btn-makepla btn-sm'
              href='${self.getMakeplaUrlByLatLon(this.latlon)}'
              target='_blank'
            ><i class='glyphicon glyphicon-map-marker'></i>&nbsp;SEARCHBOXで見る</a>\
          `);
          this.info.open();
        } else {
          this.info.moveLatLon(this.latlon);
          this.info.setHtml(`\
            <b>${_.escapeHTML(name)}</b><br>
            ジャンル: ${_.escapeHTML(genre)}<br>
            距離: ${_.numberFormat(distance)}m\
          `);
          this.info.open();
        }
      }
    );
  }

  clearPoiMakers() {
    const self = this;
    this.poi_markers.forEach((marker) => self.map.removeWidget(marker));
    return (this.poi_markers = []);
  }

  getMakeplaUrlByLatLon(latlon) {
    const wgs_latlon = ZDC.tkyTowgs(latlon);
    return Routes.makepla_scs_path({
      lon: wgs_latlon.lon,
      lat: wgs_latlon.lat,
    });
  }

  getMakeplaUrlById(sc_id) {
    return Routes.makepla_sc_path(sc_id);
  }
}
