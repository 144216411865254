export const C3AxisImpl = {
  _getAxisYTickValues(max_count, min_count=null) {
    if (min_count === null) {
      switch (false) {
        case !(max_count <= 5): return [0, 1, 2, 3, 4, 5];
        case !(max_count <= 10): return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        case !(max_count <= 20): return _.range(0, 21, 5);
        case !(max_count <= 30): return _.range(0, 31, 5);
        case !(max_count <= 50): return _.range(0, 51, 10);
        case !(max_count <= 100): return _.range(0, 101, 10);
        default: return [];
      }
    } else {
      const abs_count = Math.max(max_count, Math.abs(min_count))
      switch (false) {
        case !(abs_count <= 3): return _.range(-3, 4);
        case !(abs_count <= 5): return _.range(-5, 6);
        case !(abs_count <= 8): return _.range(-8, 9, 2);
        case !(abs_count <= 10): return _.range(-10, 11, 2);
        case !(abs_count <= 20): return _.range(-20, 21, 5);
        case !(abs_count <= 30): return _.range(-30, 31, 5);
        case !(abs_count <= 50): return _.range(-50, 51, 10);
        default: return [];
      }
    }
  },

  _getAxisYMax(max_count) {
    switch (false) {
      case !(max_count <= 5): return 5;
      case !(max_count <= 10): return 10;
      case !(max_count <= 20): return 20;
      case !(max_count <= 30): return 30;
      case !(max_count <= 50): return 50;
      default: return undefined;
    }
  },

  _getFontSize(data_size) {
    switch (false) {
      case !(data_size <= 6): return 14;
      case !(data_size < 9): return 13;
      default: return 11;
    }
  }
};
