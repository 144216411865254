// fat arrow演算子でメソッドを定義すると、
// 子クラスのthisが引数と同じになる現象が発生したため、
// もしこのクラスを継承する場合は、
// メソッドをオーバーライドしてアンダーバー付きメソッドを呼んでください
export class BaseSearchByIndustry {
  static RESTAURANT_AND_GROCERY = [70000, 120000];

  constructor(large_industries, medium_industries, small_industries,
    selected_large_industry, selected_medium_industry, selected_small_industries,
    small_industry_nicknames, id_suffix) {
    this.selectLarge = this.selectLarge.bind(this);
    this.selectMedium = this.selectMedium.bind(this);
    this.large_industries = large_industries;
    this.medium_industries = medium_industries;
    this.small_industries = small_industries;
    this.selected_large_industry = selected_large_industry;
    this.selected_medium_industry = selected_medium_industry;
    this.selected_small_industries = selected_small_industries;
    this.small_industry_nicknames = small_industry_nicknames;
    this.filter_medium_industries = function() { return this.medium_industries[this.selected_large_industry]; };
    this.filter_small_industries = function() { return this.small_industries[this.selected_medium_industry]; };
    this.isChoicesActive = this.selected_large_industry || this.selected_small_industries.length === 0;
    this.id_suffix = id_suffix ?? '';
    this.small_industry_keywords = '';
    this.medium_industries_flatten = Object.values(medium_industries).flat();
    this.small_industries_flatten = Object.values(small_industries).flat();
    this.filtered_by_keywords = [];
    this.filter_by_keywords = () => {
      // ニックネームで絞り込みし、type_industries3 を保持
      const type_industries3 = [];
      if (this.small_industry_keywords.trim() === '') {
        return this.filtered_by_keywords = [];
      }
      for (const type_industry3 in small_industry_nicknames) {
        for (const nicknames of small_industry_nicknames[type_industry3]) {
          if (nicknames.indexOf(this.small_industry_keywords) > -1) {
            type_industries3.push(parseInt(type_industry3, 10));
            break;
          }
        }
      }
      // ニックネームと小業種名で絞り込み
      const filtered = this.small_industries_flatten.filter(x => {
        return type_industries3.includes(x.type_industry3) || x.name.indexOf(this.small_industry_keywords) > -1
      });
      // [{ type_industry2: xxx, name: xxx, small_industries: [{type_industry3: xxx, name}] }] の形式に変換
      const result = [];
      filtered.forEach(x => {
        if (result.find(value => value.type_industry2 === x.type_industry2)) {
          // 中業種がすでに入っている場合、小業種のみ挿入
          result.find(value => value.type_industry2 === x.type_industry2).small_industries.push({
            type_industry3: x.type_industry3,
            name: x.name
          });
        } else {
          // 中業種が入っていない場合、中業種と小業種を挿入
          result.push({
            type_industry2: x.type_industry2,
            name: `中業種：${this.medium_industries_flatten.find(value => value.type_industry2 === x.type_industry2).name}`,
            small_industries: [{
              type_industry3: x.type_industry3,
              name: x.name
            }]
          });
        }
      });
      return this.filtered_by_keywords = result;
    };
    this.filter_by_same_medium = () => {
      if (this.small_industry_keywords.trim() === '') {
        return this.filtered_by_same_medium = [];
      }
      // 中業種と絞り込み結果をキーのみの配列で保持
      const medium_industries = this.filtered_by_keywords.map(x => x.type_industry2);
      const search_result = this.filtered_by_keywords.map(x => x.small_industries).flat().map(x => x.type_industry3);
      // 中業種ごとに小業種をグループ化
      const result = [];
      medium_industries.forEach(type_industry2 => {
        const small_industries = this.small_industries[type_industry2].filter(x => !search_result.includes(x.type_industry3));
        if (small_industries.length !== 0) {
          result.push({
            type_industry2: type_industry2,
            name: `中業種：${this.medium_industries_flatten.find(value => value.type_industry2 === type_industry2).name}`,
            small_industries: small_industries
          });
        }
      });
      return this.filtered_by_same_medium = result;
    };
    this.selected_small_industries_with_name = () => {
      return this.selected_small_industries.map(type_industry3 => {
        return {
          type_industry3: type_industry3,
          name: this.small_industries_flatten.find(small => small.type_industry3 === type_industry3).name
        }
      });
    };
  }

  initialize() {
    ko.track(this,
      [
        'selected_large_industry',
        'selected_medium_industry',
        'selected_small_industries',
        'selected_food_court',
        'small_industry_keywords',
      ]
    );
    return this;
  }

  selectLarge() { return this._selectLarge(); }

  _selectLarge() {
    if (!this.isSelectedRestaurantOrGrocery()) { this.selected_food_court = null; }
    this.selected_medium_industry = null;
    this.clearAllSmall();
    if (this.filter_medium_industries().length === 1) {
      this.selected_medium_industry = _.first(this.filter_medium_industries()).type_industry2;
      if (this.filter_small_industries().length === 1) { this.selectAllSmall(); }
    }
    return true;
  }

  clearSelectedLarge() {
    this.selected_large_industry = null;
    this.selected_medium_industry = null;
    this.selected_small_industries = [];
    this.clearSelectedFoodCourt();
  }

  clearSelectedFoodCourt() { return this.selected_food_court = null; }

  selectMedium() { return this._selectMedium(); }

  _selectMedium() {
    this.clearAllSmall();
    if (this.filter_small_industries().length === 1) { this.selectAllSmall(); }
    return true;
  }

  clearSelectedMedium() { 
    this.selected_medium_industry = null; 
    this.selected_small_industries = [];
  }

  selectAllSmall() {
    return this.selected_small_industries = _.pluck(this.filter_small_industries(), 'type_industry3');
  }

  clearAllSmall() {
    if (!_.isEmpty(this.selected_small_industries)) { this.selected_small_industries = []; }
  }

  clear() {
    this.clearSelectedLarge();
    this.clearSelectedMedium();
    this.clearAllSmall();
  }

  isSelectedRestaurantOrGrocery() {
    if (this.selected_large_industry) {
      return _.includes(BaseSearchByIndustry.RESTAURANT_AND_GROCERY, this.selected_large_industry);
    } else {
      return false;
    }
  }

  changeTab() {
    this.clear();
  }

  removeSelectedSmall = (element) => {
    this.selected_small_industries.remove(element.type_industry3);
  };
};
