// 対象文字列をtruncateするKnockout.jsのハンドラ
// https://dimakuzmich.wordpress.com/2013/06/24/knockoutjs-truncated-bindhandler/
ko.bindingHandlers.truncatedText = {
  update(element, valueAccessor, allBindingsAccessor) {
    const originalText = ko.utils.unwrapObservable(valueAccessor());
    // 10 is a default maximum length
    const length = ko.utils.unwrapObservable(allBindingsAccessor().maxTextLength) || 20;
    const truncatedText = originalText.length > length ? originalText.substring(0, length) + "..." : originalText;
    // updating text binding handler to show truncatedText
    return ko.bindingHandlers.text.update(element, () => truncatedText);
  }
};