class NavbarCompareButtonViewModel {
  constructor(model_type) {
    this.model = { type: model_type };
    ko.track(this);
  }

  moveToCompareScGraph() {
    if (this.validCompareConditions(5)) {
      location.href = Routes.compare_path() + '?' + this.getEncodedQuery(this.getModelIds());
    }
  }

  moveToCompareBrands() {
    if (this.validCompareConditions(5)) {
      location.href = Routes.compare_brands_path() + '?' + this.getEncodedQuery(this.getModelIds());
    }
  }

  moveToCompareOpenScList() {
    if (this.validCompareConditions(10)) {
      location.href = Routes.compare_open_sc_list_path() + '?' + this.getEncodedQuery(this.getModelIds());
    }
  }

  getModelIds() {
    return _.map($("input[name='model_id[]']:checked"), element => $(element).val());
  }

  getEncodedQuery(model_ids) {
    const self = this;
    const params = new URLSearchParams();
    model_ids.forEach(function(id) {
      params.append('model_id[]', id);
      params.append('model_type[]', self.model.type);
    });
    return params.toString();
  }

  validCompareConditions(max_length) {
    const targets = $("input[name='model_id[]']:checked");
    if (!targets.is("*")) {
      Notify.danger('比較対象を2件以上選択してください');
      return false;
    }
    if (targets.length > max_length) {
      Notify.danger(`比較対象を${max_length}件以内にしてください`);
      return false;
    }
    if (targets.length < 2) {
      Notify.danger('比較対象を2件以上選択してください');
      return false;
    }
    return true;
  }
}


ko.components.register('navbar-compare-button', {
  viewModel(params) {
    return new NavbarCompareButtonViewModel(params.model_type);
  },
  template: `\
<div id="btn-navbar-compare" class="btn-group">
  <button class="btn btn-default btn-sm dropdown-toggle navbar-btn"
    type="button" data-toggle="dropdown" aria-expanded="false"
  >くらべる&nbsp;<span class="caret"></span></button>
  <ul class="dropdown-menu" role="menu">
    <!-- ko if: model.type == 'sc' -->
      <li class="dropdown-header">2〜5件 選択可能</li>
      <li><a data-bind="click: moveToCompareScGraph">SCをグラフ比較</a></li>
      <li><a data-bind="click: moveToCompareBrands">ショップ比較</a></li>
    <!-- /ko -->
    <!-- ko if: model.type == 'pm' -->
      <li class="dropdown-header">2〜5件 選択可能</li>
      <li><a data-bind="click: moveToCompareBrands">ショップ比較</a></li>
    <!-- /ko -->
    <!-- ko if: model.type == 'shop_brand' -->
      <li class="dropdown-header">2〜10件 選択可能</li>
      <li><a data-bind="click: moveToCompareOpenScList">出店先SC比較</a></li>
    <!-- /ko -->
  </ul>
</div>\
`
});