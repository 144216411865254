$(function() {
  if (isLoggedIn) {
    $(document).tooltip({
      title: "ご利用いただけません",
      selector: '[data-accessible=false]'
    });
  } else {
    $(document).tooltip({
      title: "ご利用登録後にご利用できます",
      selector: '[data-accessible=false]'
    });
  }
});
