import { getModelId } from './utilities'
import { C3ShopBrandKindsChart } from './charts/c3_shop_brand_kinds_chart';
import { C3OccupancyChart } from './charts/c3_occupancy_chart'

$(function() {
  if ($('#occupancy-chart').is('*')) {
    getScOccupancyChart();
    showScOccupancyData();
  }

  if ($('#shop_brand_kinds-chart').is('*')) {
    getScShopBrandKindsChart();
    showScShopBrandKindsData();
  }

  if ($('#year_month_start, #year_month_end').is('*')) {
    $('#year_month_start, #year_month_end').datepicker({
      format: 'yyyy/mm',
      language: 'ja',
      autoclose: true,
      minViewMode: 'months'
    });
  }

  // 出退店詳細ページの検索フォーム
  if ($('form#sc_occupancy_detail').is('*')) {
    $('form#sc_occupancy_detail').submit(function() {
      const form_data = $('form#sc_occupancy_detail').serialize();
      getScOccupancyChart(form_data);
      showScOccupancyData(form_data);
      return false;
    });
  }

  // 業種別ショップ・ブランド数詳細ページの検索フォーム
  if ($('form#sc_shop_brand_kinds_detail').is('*')) {
    $('form#sc_shop_brand_kinds_detail').submit(function() {
      const form_data = $('form#sc_shop_brand_kinds_detail').serialize();
      getScShopBrandKindsChart(form_data);
      showScShopBrandKindsData(form_data);
      return false;
    });
  }


  $(document).on('ajax:error', '#modal_shop_brand_form', function(xhr, status, error) {
    // 何も解決していない…
    alert("エラーが発生しました。\n申し訳ございませんが、後ほどお試しください。");
  });
});

//
// SCの出退店のチャートとデータ
//
function getScOccupancyChart(form_data) {
  $.getJSON(Routes.occupancy_sc_path(getModelId(), {format: 'json'}),
      form_data,
      data => new C3OccupancyChart('#occupancy-chart', data).show());
}

function showScOccupancyData(form_data) {
  $('#occupancy-data').load(Routes.occupancy_sc_path(getModelId(),
      {format: 'html'}),
      form_data
  );
}

//
// SCの業種別ショップ・ブランド数のチャートとデータ
//
function getScShopBrandKindsChart(form_data){
  $.getJSON(Routes.shop_brand_kinds_sc_path(getModelId(), {format: 'json'}), form_data, data => {
    new C3ShopBrandKindsChart('#shop_brand_kinds-chart', data, false).show();
    new C3ShopBrandKindsChart('#shop_brand_kinds_percentage-chart', data, true).show();
  });
}

function showScShopBrandKindsData(form_data) {
  if ($('#shop_brand_kinds-data').is('*')) {
    $('#shop_brand_kinds-data').load(Routes.shop_brand_kinds_sc_path(getModelId(), {format: 'html'}), form_data);
  }
};