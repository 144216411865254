import { KnockoutModalBase } from "../../../shared/knockout/view_models/knockout_modal_base";

export class ModalFormMemoViewModel extends KnockoutModalBase {
  constructor() {
    super();
    this.initializeForm();
    this.checkParams = function() {
      if ((this.title == null) || (this.status == null)) {
        return false;
      } else {
        return !_.isBlank(this.title) && !_.isBlank(this.status);
      }
    };

    this.isLimited = () => this.status === 'range_limited';

    this.modelLabelName = () => `model-label-${_.underscored(this.model.type)}`;

    ko.track(this);
    const self = this;
    ko.postbox.subscribe('showModalFormMemo', function(memo) {
      self.setMemo(memo);
      self.show();
    });
  }

  setMemo(memo) {
    this.id = memo.id;
    this.model = memo.model;
    this.title = memo.title;
    this.status = memo.status;
    this.content = memo.content_raw;
    this.url = memo.url;
    this.members = memo.members;
    return this.selected_member_ids = _.pluck(this.members, 'id').map(it => it.toString());
  }

  submitForm(form_element) {
    const self = this;
    self.sending = true;
    const target_url = (self.id != null) ? Routes.memo_path(self.id, {format: 'json'}) : Routes.memos_path({format: 'json'});
    const method = (self.id != null) ? 'PATCH' : 'POST';
    return $.ajax({url: target_url, method, data: $(form_element).serialize()}).done(function(data, status, xhr) {
      if (self.id == null) {
        // メモの新規作成の結果を踏まえての処理
        ko.postbox.publish('incrementCountMemoButton');
        Notify.success(`メモ <strong>『${_.escapeHTML(data.title)}』</strong> を保存しました`);
      } else {
        // メモの編集の結果を踏まえての処理
        ko.postbox.publish('refreshListMemo');
      }
      self.initializeForm();
      return self.hide();
    }).fail((xhr, status, statusText) => Notify.error()).always( () => self.sending = false);
  }

  initializeForm() {
    this.id = null;
    this.model = { id: null, name: null, type: null };
    this.title = null;
    this.status = null;
    this.content = null;
    this.url = null;
    this.members = [];
    this.selected_member_ids = [];
    this.sending = false;
  }
};

ko.components.register('modal-form-memo', {
  viewModel(params) { return new ModalFormMemoViewModel(); },
  template: { element: 'modal-template' }
});